import { Injectable } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { DelegateService, KioskService } from '@starfish-access/core';
import { Delegation, DelegationMetaData } from '@starfish-access/models';
import { OrgSelectItem } from '@starfish-access/shared';
import { BehaviorSubject, Observable, of, throwError } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { RoleDeletionDialogComponent } from './role-deletion-dialog/role-deletion-dialog.component';

@Injectable()
export class DelegationFacade {
  isUpdating$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  loadingDelegations$: BehaviorSubject<boolean> = new BehaviorSubject(false);

  readonly kioskList$: Observable<OrgSelectItem[]> = this.kioskService.getKiosks().pipe(
    map((res) => {
      const modelList: OrgSelectItem[] = [];
      res.forEach((element) => {
        modelList.push({
          id: element.id,
          displayName: element.name,
          orgName: element.name,
        });
      });
      return modelList;
    })
  );
  constructor(private delegateService: DelegateService, private kioskService: KioskService, public dialog: MatDialog) {}

  getDelegations(): Observable<Delegation[]> {
    this.loadingDelegations$.next(true);

    return this.delegateService.getAllDelegationGroups().pipe(tap(() => this.loadingDelegations$.next(false)));
  }

  getDelegationById(id: string): Observable<Delegation> {
    this.loadingDelegations$.next(true);

    return this.delegateService.getDelegationGroupById(id).pipe(tap(() => this.loadingDelegations$.next(false)));
  }

  getDelegationsMetaData(): Observable<DelegationMetaData[]> {
    this.loadingDelegations$.next(true);

    return this.delegateService.getAllDelegationGroupsMetaData().pipe(tap(() => this.loadingDelegations$.next(false)));
  }

  removeDelegationRole(delegationId: string, delegationRoleId: string | undefined, force: boolean): Observable<number> {
    if (!delegationRoleId) {
      return of(0);
    }
    return this.delegateService.removeDelegationRole(delegationId, delegationRoleId, force);
  }

  updateDelegation(del: Delegation): Observable<Delegation> {
    this.isUpdating$.next(true);
    return this.delegateService.updateExistingDelegationGroup(del).pipe(
      catchError((err) => {
        this.isUpdating$.next(false);
        return throwError(err);
      }),
      tap(() => this.isUpdating$.next(false))
    );
  }

  confirmRoleDeletion(roleName: string, assignmentCount: number): Observable<boolean> {
    return this.dialog
      .open(RoleDeletionDialogComponent, {
        width: '30vw',
        data: {
          numberOfAssignments: assignmentCount,
          roleNameToDelete: roleName,
        },
      })
      .afterClosed();
  }
}
