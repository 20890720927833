<mat-card class="audit-approval-task mat-elevation-z0">

  <h1> Auditing </h1>

  <span *ngIf="(isLoadingTaskDetails | async)">
    <br />
    <mat-progress-bar mode="indeterminate"></mat-progress-bar>
  </span>

  <table mat-table
    [dataSource]="dataSource"
    class="mat-elevation-z0">

    <!--- Note that these columns can be defined in any order.
              The actual rendered columns are set as a property on the row definition" -->

    <ng-container matColumnDef="userDisplayName">
      <th mat-header-cell
        *matHeaderCellDef> User Name </th>
      <td mat-cell
        *matCellDef="let element"> {{element.userDisplayName}} </td>
    </ng-container>

    <ng-container matColumnDef="userId">
      <th mat-header-cell
        *matHeaderCellDef> User ID </th>
      <td mat-cell
        *matCellDef="let element"> {{element.userId}} </td>
    </ng-container>

    <ng-container matColumnDef="roleName">
      <th mat-header-cell
        *matHeaderCellDef> My Starfish Role </th>
      <td mat-cell
        *matCellDef="let element"> {{element.roleName}} </td>
    </ng-container>

    <ng-container matColumnDef="approve">
      <th mat-header-cell
        *matHeaderCellDef> </th>
      <td mat-cell
        *matCellDef="let element">
        <button mat-flat-button
          (click)="approveConfirmation(element.roleName)"
          aria-label="Re-request this role from my user."
          color="primary"
          [disabled]="!(isLoggedInUsersTask | async)"
          data-cy="approve-audit-task">Approve</button>
      </td>
    </ng-container>
    <ng-container matColumnDef="remove">
      <th mat-header-cell
        *matHeaderCellDef> </th>
      <td mat-cell
        *matCellDef="let element">
        <button mat-flat-button
          (click)="removeConfirmation(element.roleName)"
          aria-label="Remove this role from my user."
          color="warn"
          [disabled]="!(isLoggedInUsersTask | async)"
          data-cy="remove-audit-task">Remove</button>
      </td>
    </ng-container>

    <tr mat-header-row
      *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row
      *matRowDef="let row; columns: displayedColumns;"></tr>
  </table>

</mat-card>
