import { animate, state, style, transition, trigger } from '@angular/animations';
import { Component, OnDestroy, OnInit, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { MatLegacyPaginator as MatPaginator, LegacyPageEvent as PageEvent } from '@angular/material/legacy-paginator';
import { MatSort, Sort } from '@angular/material/sort';
import { PermissionCheckService } from '@psu/apis/fortress';
import { ConfirmDialogService, TOOLTIP_DELAY, UserListPermissions } from '@starfish-access/core';
import * as R from 'ramda';
import { Observable, Subject } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';
import { AddRoleComponent } from './add-role/add-role.component';
import { NewUserComponent } from './new-user/new-user.component';
import { UserManagementDataSource } from './usermanagement.datasource';
import { UserManagementFacade } from './usermanagement.facade';
import { UsersRequestConfig } from './usermanagement.model';

@Component({
  templateUrl: './usermanagement.component.html',
  styleUrls: ['./usermanagement.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0' })),
      state('expanded', style({ height: '*' })),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
})
export class UserManagementComponent implements OnInit, OnDestroy {
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  @ViewChildren(AddRoleComponent)
  sfAddRoleChildren: QueryList<AddRoleComponent>;
  expandedElement: any;
  canView: Observable<boolean>;
  canDelete: Observable<boolean>;
  displayedColumns: string[] = ['displayName', 'userId', 'roles', 'remove', 'add'];
  dataSource: UserManagementDataSource;
  tooltipDelay: string = TOOLTIP_DELAY;
  deletedUsers: string[] = [];
  filterInput: UntypedFormControl = new UntypedFormControl();
  private permissions: any = {};
  private destroy$ = new Subject<void>();
  private config: UsersRequestConfig = {
    pageStart: 1,
    pageTotal: 5,
  };

  constructor(
    public dialog: MatDialog,
    private confirmDialogService: ConfirmDialogService,
    public facade: UserManagementFacade,
    private permissionCheckService: PermissionCheckService
  ) {
    this.canView = this.permissionCheckService.hasPermission(UserListPermissions.VIEW);
    this.canDelete = this.permissionCheckService.hasPermission(UserListPermissions.DELETE);
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
  ngOnInit(): void {
    // store all permissions
    this.canDelete.pipe(take(1)).forEach((next) => {
      this.permissions.canDelete = next;
    });
    this.canView.pipe(take(1)).forEach((next) => {
      this.permissions.canView = next;
    });

    this.dataSource = this.facade.getUsersTableDataSource();
    this.dataSource.loadUsers(this.config);
  }

  pageEvent(pgEvent: PageEvent): void {
    this.config.pageStart = pgEvent.pageIndex + 1;
    this.config.pageTotal = pgEvent.pageSize;
    this.dataSource.loadUsers(this.config);
  }

  addRoleToUserAndClose(userId: string) {
    this.sfAddRoleChildren.forEach((sfAddComp) => {
      if (sfAddComp.userToEvaluate === userId && sfAddComp.addRoleFormGroup.valid) {
        const ar = sfAddComp.buildAccessRequest(userId);
        this.dataSource.adminUserAddition(ar, this.config);
      }
    });
  }

  searchByFilter(config: UsersRequestConfig): void {
    // Create a new object with the own properties of the first object merged with the own properties of the second object. If a key exists in both objects, the value from the second object will be used.
    this.config = R.mergeRight(this.config, config);
    this.dataSource.loadUsers(this.config);
    this.paginator.firstPage();
  }

  sortData(sort: Sort) {
    // ASCENDING or DESCENDING
    let sortOrder = 'DESCENDING';
    if (sort.direction.indexOf('asc') > -1) {
      sortOrder = 'ASCENDING';
    }
    this.config.useridSortOrder = sortOrder;
    this.dataSource.loadUsers(this.config);
    this.paginator.firstPage();
  }

  removeUser(userId: string) {
    this.confirmDialogService
      .confirmMessage({
        title: 'Delete User?',
        message: 'Are you sure? Deleting this user cannot be undone.',
      })
      .pipe(takeUntil(this.destroy$))
      .subscribe((res) => this.confirmDelete(userId, res));
  }

  removeUserRole(userId: string, roleId: number) {
    this.facade.removeUserRole(userId, roleId, this.config, this.dataSource);
  }

  isActivelyDeleting(userId: string): boolean {
    for (const id of this.deletedUsers) {
      if (id === userId) {
        return true;
      }
    }
    return false;
  }

  openDialog(): void {
    this.dialog.open(NewUserComponent, {
      width: '60vw',
      data: {
        adminRoleGroups: this.facade.roleGroupsWithAdminGroups$,
        dataSource: this.dataSource,
      },
      disableClose: true,
    });
  }

  private confirmDelete(userId: string, confirm: boolean) {
    if (confirm) {
      this.deletedUsers.push(userId);
      this.dataSource.deleteUser(userId, this.config);
      this.deletedUsers = [];
    }
  }
}
