import { Component, forwardRef, OnInit } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR, UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { SearchService } from '@starfish-access/core';
import { UserInfo } from '@starfish-access/models';
import { SEARCH_IMPL, TypeaheadSelectConfig } from '@starfish-access/shared';

@Component({
  selector: 'sf-find-calendar-mgr',
  templateUrl: './find-calendar-mgr.component.html',
  styleUrls: ['./find-calendar-mgr.component.scss'],
  providers: [
    { provide: SEARCH_IMPL, useExisting: SearchService },
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => FindCalendarMgrComponent),
      multi: true,
    },
  ],
})
export class FindCalendarMgrComponent implements OnInit, ControlValueAccessor {
  calMgrConfig: TypeaheadSelectConfig = {
    inputAppearance: 'outline',
    inputPlaceholder: 'Search by User ID or Name',
    removableItems: true,
  };

  calMgrUsrForm: UntypedFormGroup;

  constructor(private dialogRef: MatDialogRef<FindCalendarMgrComponent>, private fb: UntypedFormBuilder) {
    this.calMgrUsrForm = this.fb.group({
      users: [[]],
    });
  }

  writeValue(obj: UserInfo[]): void {
    if (!obj) {
      return;
    }
    this.calMgrUsrForm.patchValue(obj);
  }
  registerOnChange(fn: any): void {
    this.propagateChange = fn;
  }
  registerOnTouched(fn: any): void {
    this.propagateTouch = fn;
  }

  ngOnInit(): void {
    this.calMgrUsrForm.valueChanges.subscribe((value: UserInfo[]) => {
      this.propagateChange(value);
    });
  }

  cancel(): void {
    this.dialogRef.close(undefined);
  }

  complete(): void {
    const users: UserInfo[] = this.calMgrUsrForm.get('users')?.value;
    this.dialogRef.close(users);
  }

  private propagateChange: (_: any) => any = (_: any) => ({});
  private propagateTouch: (_: any) => any = (_: any) => ({});
}
