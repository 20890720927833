import { DragDropModule } from '@angular/cdk/drag-drop';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatLegacyCardModule as MatCardModule } from '@angular/material/legacy-card';
import { MatLegacyCheckboxModule as MatCheckboxModule } from '@angular/material/legacy-checkbox';
import { MatLegacyChipsModule as MatChipsModule } from '@angular/material/legacy-chips';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatLegacyFormFieldModule as MatFormFieldModule } from '@angular/material/legacy-form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import { MatLegacyProgressBarModule as MatProgressBarModule } from '@angular/material/legacy-progress-bar';
import { MatLegacyRadioModule as MatRadioModule } from '@angular/material/legacy-radio';
import { MatLegacySelectModule as MatSelectModule } from '@angular/material/legacy-select';
import { MatLegacySlideToggleModule as MatSlideToggleModule } from '@angular/material/legacy-slide-toggle';
import { MatLegacyTabsModule as MatTabsModule } from '@angular/material/legacy-tabs';
import { MatLegacyTooltipModule as MatTooltipModule } from '@angular/material/legacy-tooltip';
import { RouterModule } from '@angular/router';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { TypeaheadModule as ComponentTypeaheadModule } from '@psu/components/typeahead';
import { CoreModule, FilterModule } from '@starfish-access/core';
import { StarfishCommonModule, TypeaheadModule } from '@starfish-access/shared';
import { EditRowModule } from '../edit-row/edit-row.module';
import { AuthFlowComponent } from './auth-flow/auth-flow.component';
import { ColorSelectorComponent } from './color-selector/color-selector.component';
import { DescriptionsComponent } from './descriptions/descriptions.component';
import { DynamicRoleComponent } from './dynamic-role.component';
import { DynamicRolesEffects } from './dynamic-role.effects';
import { DynamicRolesFacade } from './dynamic-role.facade.service';
import { dynamicRolesReducer } from './dynamic-role.reducer';
import { routes } from './dynamic-role.routing';
import { EditRoleGroupComponent } from './edit-role-group/edit-role-group.component';
import { EditRoleNameComponent } from './edit-role-name/edit-role-name.component';
import { EditRoleComponent } from './edit-role/edit-role.component';
import { GroupPriorityComponent } from './group-priority/group-priority.component';
import { IconPreviewComponent } from './icon-preview/icon-preview.component';
import { IconSelectorComponent } from './icon-selector/icon-selector.component';
import { NewRoleGroupComponent } from './new-role-group/new-role-group.component';
import { OrgsComponent } from './orgs/orgs.component';
import { OrgsFacade } from './orgs/orgs.facade';
import { RoleDetailsFormComponent } from './role-details-form/role-details-form.component';
import { RoleDetailsFormFacade } from './role-details-form/role-details-form.facade';
import { SelectRoleGroupComponent } from './select-role-group/select-role-group.component';
import { ServiceSelectorComponent } from './service-selector/service-selector.component';
import { ServiceToOrgPipe } from './service-selector/service-to-org.pipe';

@NgModule({
  imports: [
    CommonModule,
    CoreModule,
    FormsModule,
    ReactiveFormsModule,
    MatCardModule,
    FlexLayoutModule,
    TypeaheadModule,
    MatIconModule,
    MatCheckboxModule,
    MatFormFieldModule,
    MatInputModule,
    MatRadioModule,
    MatButtonModule,
    MatProgressBarModule,
    MatSelectModule,
    EditRowModule,
    MatChipsModule,
    DragDropModule,
    MatTabsModule,
    MatTooltipModule,
    FilterModule,
    MatExpansionModule,
    StarfishCommonModule,
    ComponentTypeaheadModule,
    MatSlideToggleModule,
    RouterModule.forChild(routes),
    StoreModule.forFeature('dynamicRoles', dynamicRolesReducer),
    EffectsModule.forFeature([DynamicRolesEffects]),
  ],
  declarations: [
    DynamicRoleComponent,
    OrgsComponent,
    NewRoleGroupComponent,
    ColorSelectorComponent,
    IconSelectorComponent,
    GroupPriorityComponent,
    IconPreviewComponent,
    EditRoleComponent,
    EditRoleNameComponent,
    SelectRoleGroupComponent,
    RoleDetailsFormComponent,
    EditRoleGroupComponent,
    AuthFlowComponent,
    ServiceSelectorComponent,
    ServiceToOrgPipe,
    DescriptionsComponent,
  ],
  exports: [DynamicRoleComponent, ServiceSelectorComponent, ServiceToOrgPipe],
  providers: [DynamicRolesFacade, OrgsFacade, RoleDetailsFormFacade],
})
export class DynamicRoleModule {}
