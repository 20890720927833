import { InjectionToken } from '@angular/core';
import { TypeaheadModel } from '@psu/components/typeahead';
import { UserInfo } from '@starfish-access/models';
import { Observable, pipe, Subject } from 'rxjs';
import { map } from 'rxjs/operators';

const userInfosToTypeaheadModels = (userInfos: UserInfo[]): TypeaheadModel<UserInfo>[] =>
  userInfos.map((userInfo) => ({
    displayValue: userInfo.displayName + ' (' + userInfo.id + ')',
    object: userInfo,
    disabled: false,
  }));

export const toTypeaheadModel = () => pipe(map(userInfosToTypeaheadModels));

export const handleError = (error$: Subject<boolean>, progress$: Subject<boolean>) =>
  pipe(
    map((_a: any) => {
      error$.next(true);
      progress$.next(false);
      return [];
    })
  );

export const SEARCH_IMPL: InjectionToken<Searcher> = new InjectionToken<Searcher>('typeahead-search-impl');

export interface Searcher {
  getResults(searchTerm: string): Observable<UserInfo[]>;
}

export interface TypeaheadSelectConfig {
  removableItems: boolean;
  inputPlaceholder: string;
  showSummary?: boolean;
  inputAppearance: 'legacy' | 'standard' | 'fill' | 'outline';
}

export const markDisabled = (selectedItems: UserInfo[]) =>
  pipe(
    map((models: TypeaheadModel<UserInfo>[]) =>
      models.map((model) => {
        if (selectedItems.includes(model.object)) {
          return {
            ...model,
            disabled: true,
          };
        } else {
          return {
            ...model,
            disabled: false,
          };
        }
      })
    )
  );
