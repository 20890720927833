import { Component, Inject, ViewChild } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { User as TypeAheadUser } from '@starfish-access/shared';
import { Observable } from 'rxjs';
import { StarfishConfig, STARFISH_CONFIG } from '../../../starfish.config';
import { AddRoleComponent } from '../add-role/add-role.component';
import { UserManagementFacade } from '../usermanagement.facade';
import { NewUserDialogData, Users } from '../usermanagement.model';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'sf-new-user',
  templateUrl: './new-user.component.html',
  styleUrls: ['./new-user.component.scss'],
})
export class NewUserComponent {
  @ViewChild('sfAddRoleComp')
  sfAddRoleComp: AddRoleComponent;

  config: any;
  selectedUser: TypeAheadUser | undefined;
  existingRoles: Observable<Users> | undefined;

  constructor(
    @Inject(STARFISH_CONFIG) private starfishConfig: StarfishConfig,
    private facade: UserManagementFacade,
    public dialogRef: MatDialogRef<NewUserComponent>,
    @Inject(MAT_DIALOG_DATA) public data: NewUserDialogData
  ) {
    const searchServiceLink = this.starfishConfig.searchService;
    this.config = {
      url: searchServiceLink,
      ssnSearchUrl: '',
      maxResults: 100,
      resultHeight: '48',
      sendAuth: true,
    };
  }

  addNewUser(user: TypeAheadUser) {
    if (!user || user.userid.length < 1) {
      return;
    }
    this.selectedUser = user;
    this.existingRoles = this.facade.getSpecificUserRoles(user.userid);
  }

  clearUser(): void {
    this.selectedUser = undefined;
    this.existingRoles = undefined;
  }

  saveNewUser(dr: MatDialogRef<any>) {
    if (this.selectedUser && this.sfAddRoleComp.addRoleFormGroup.valid) {
      const ar = this.sfAddRoleComp.buildAccessRequest(this.selectedUser.userid);

      this.data.dataSource.adminUserAddition(ar, {
        pageStart: 1,
        pageTotal: 5,
      });
      dr.close();
    }
  }
}
