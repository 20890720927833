import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { ConfirmDialogService } from '@starfish-access/core';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { User } from '../roles.model';

@Component({
  templateUrl: './roletable.component.html',
  styleUrls: ['./roletable.component.scss'],
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'sf-role-table',
})
export class RoleTableComponent implements OnInit, OnDestroy {
  @Input() users: Observable<User[]>;
  @Input() userRoleColumns: string[];
  @Input() canDelete: Observable<boolean>;
  @Input() tableType: string;

  @Output() deleteUser: EventEmitter<string> = new EventEmitter();
  dataSource: MatTableDataSource<User>;
  private destroy$ = new Subject<void>();

  constructor(private confirmDialogService: ConfirmDialogService) {}

  applyFilter(filterValue: string): void {
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  ngOnInit(): void {
    this.users.pipe(takeUntil(this.destroy$)).subscribe((res) => (this.dataSource = new MatTableDataSource(res)));
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  removeUser(accessId: string): void {
    this.confirmDialogService
      .confirmMessage({
        title: 'Delete User',
        message: 'Are you sure? Deleting this member cannot be undone.',
      })
      .pipe(takeUntil(this.destroy$))
      .subscribe((res) => {
        if (res) {
          this.deleteUser.emit(accessId);
        }
      });
  }
}
