import { ClipboardModule } from '@angular/cdk/clipboard';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatLegacyCardModule as MatCardModule } from '@angular/material/legacy-card';
import { MatLegacyDialogModule as MatDialogModule } from '@angular/material/legacy-dialog';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import { MatLegacyListModule as MatListModule } from '@angular/material/legacy-list';
import { MatLegacySelectModule as MatSelectModule } from '@angular/material/legacy-select';
import { MatSortModule } from '@angular/material/sort';
import { MatLegacyTableModule as MatTableModule } from '@angular/material/legacy-table';
import { MatLegacyTooltipModule as MatTooltipModule } from '@angular/material/legacy-tooltip';
import { RouterModule } from '@angular/router';
import { FilterModule } from '@starfish-access/core';
import { StarfishCommonModule } from '@starfish-access/shared';
import { CourseInfoComponent } from './course-list/course-info.component';
import { CourseListComponent } from './course-list/course-list.component';
import { CourseListFacade } from './course-list/course-list.facade';
import { CourseMappingFacade } from './course-mapping.facade';
import { routes } from './course-mapping.routing';
import { CourseMappingService } from './course-mapping.service';
import { SaveSyncComponent } from './sync-courses-display/save-sync.component';
import { SyncCoursesDisplayComponent } from './sync-courses-display/sync-courses-display.component';
import { TaskApprovalComponent } from './task-approval/task-approval.component';
import { UnmappedCoursesDisplayComponent } from './unmapped-courses-display/unmapped-courses-display.component';

@NgModule({
  imports: [
    CommonModule,
    MatButtonModule,
    MatCardModule,
    MatIconModule,
    FlexLayoutModule,
    MatExpansionModule,
    StarfishCommonModule,
    MatTableModule,
    MatSelectModule,
    MatDialogModule,
    ClipboardModule,
    MatTooltipModule,
    MatListModule,
    FilterModule,
    MatSortModule,
    MatInputModule,
    RouterModule.forChild(routes),
  ],
  declarations: [
    TaskApprovalComponent,
    CourseListComponent,
    SyncCoursesDisplayComponent,
    CourseInfoComponent,
    SaveSyncComponent,
    UnmappedCoursesDisplayComponent,
  ],
  exports: [RouterModule],
  providers: [CourseMappingService, CourseMappingFacade, CourseListFacade],
})
export class CourseMappingModule {}
