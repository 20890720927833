<div mat-dialog-content
  class="assign-users-and-roles">

  <mat-horizontal-stepper [linear]=true
    #stepper>
    <mat-step [stepControl]="usersAndRoleGroup">

      <div [formGroup]="usersAndRoleGroup">
        <ng-template matStepLabel><span>Users and Role</span></ng-template>
        <sf-add-user-and-role [existingAssignments]="data.listOfAssignments"
          [isReadOnly]="data.preloadedAssignment"
          [roleConfigs]="data.delegation.delegation.roleConfigs"
          [formControl]="usersAndRoleGroup?.get('userAndRoleCtrl')">
        </sf-add-user-and-role>

        <div class="end-align-flex"
          fxLayoutGap="24px">
          <button mat-stroked-button
            data-cy='cancel-add-assignment-btn'
            (click)="cancel()"
            color="primary">Cancel</button>
          <button mat-flat-button
            color="primary"
            data-cy='complete-user-and-role-btn'
            (click)="goForward(stepper)">Next</button>
        </div>
      </div>
    </mat-step>

    <mat-step *ngIf="(roleSelection$ | async)?.roleSelectionType === 'COURSES'"
      [stepControl]="coursesGroup">

      <form [formGroup]="coursesGroup">
        <ng-template matStepLabel>Course Settings</ng-template>
        <sf-course-config [courseConfigType]="(roleSelection$ | async)?.courseConfig?.type"
          [courseDefermentOptionsEnabled]=false
          formControlName="courses"></sf-course-config>
        <div class="end-align-flex"
          fxLayoutGap="24px">
          <button mat-button
            color="primary"
            matStepperPrevious>Back</button>
          <button mat-stroked-button
            data-cy='cancel-add-assignment-btn'
            (click)="cancel()"
            color="primary">Cancel</button>
          <button mat-flat-button
            color="primary"
            data-cy='complete-courses-btn'
            matStepperNext>Next</button>
        </div>
      </form>
    </mat-step>

    <mat-step *ngIf="(roleSelection$ | async)?.roleSelectionType === 'ONE_TO_ONE'"
      [stepControl]="connectedStudentsGroup">

      <form [formGroup]="connectedStudentsGroup">
        <ng-template matStepLabel>Add Connected Students</ng-template>
        <sf-connected-student formControlName="connectedUsers"></sf-connected-student>
        <!-- If we have invalid users, they are available in a UserInfo array at: connectedStudentsGroup.getError('invalidAsyncUsers')-->
        <mat-error *ngIf="!connectedStudentsGroup.valid">
          Stop! Please remove <a class="invalid-user-display"
            (click)="openDialog(connectedStudentsGroup.getError('invalidAsyncUsers'))">{{connectedStudentsGroup.getError('invalidAsyncUsers')?.length}} students that could not be added to this assignment.</a>
        </mat-error>

        <div *ngIf="connectedStudentsGroup.valid && !connectedStudentsGroup.pristine && (invalidUsersRemoved$ | async) === true"
          class="missing-user-success">
          Success! The missing student(s) have been removed, you may proceed.
        </div>
        <div class="end-align-flex connected-students-actions"
          fxLayoutGap="24px">
          <button mat-button
            color="primary"
            matStepperPrevious>Back</button>
          <button mat-stroked-button
            data-cy='cancel-add-assignment-btn'
            (click)="cancel()"
            color="primary">Cancel</button>
          <button mat-flat-button
            color="primary"
            data-cy='complete-org-btn'
            matTooltipPosition="above"
            [matTooltip]="(!connectedStudentsGroup.valid && !connectedStudentsGroup.pristine) ? 'You have errors on this step!' : ''"
            [disabled]="(facade.loadingMissingStudents$ | async) || (!connectedStudentsGroup.valid && !connectedStudentsGroup.pristine)"
            matStepperNext>Next</button>
        </div>
      </form>

    </mat-step>
    <mat-step *ngIf="(roleSelection$ | async)?.orgsFromDelegation.selectedItems.length > 0"
      [stepControl]="organizationsGroup">

      <form [formGroup]="organizationsGroup">
        <ng-template matStepLabel>Organizations</ng-template>
        <sf-add-org *ngIf="(roleSelection$ | async)?.orgsFromDelegation.selectedItems.length > 0"
          [selectedRole]="roleSelection$ | async"
          [hasAssignments]="hasAssignments$ | async"
          formControlName="orgsCtrl"></sf-add-org>
        <div class="end-align-flex"
          fxLayoutGap="24px">
          <button mat-button
            color="primary"
            matStepperPrevious>Back</button>
          <button mat-stroked-button
            data-cy='cancel-add-assignment-btn'
            (click)="cancel()"
            color="primary">Cancel</button>
          <button mat-flat-button
            color="primary"
            data-cy='complete-org-btn'
            matStepperNext>Next</button>
        </div>
      </form>

    </mat-step>
    <mat-step *ngIf="(roleSelection$ | async)?.kiosksFromDelegation?.selectedItems?.length > 0 || (roleSelection$ | async)?.servicesFromDelegation?.selectedItems?.length > 0"
      [stepControl]="kioskAndServiceGroup">
      <form [formGroup]="kioskAndServiceGroup">

        <ng-template matStepLabel>Services and Kiosks</ng-template>
        <sf-add-svc-kiosk *ngIf="(roleSelection$ | async)?.kiosksFromDelegation?.selectedItems?.length > 0 || (roleSelection$ | async)?.servicesFromDelegation?.selectedItems?.length > 0"
          [selectedRole]="roleSelection$ | async"
          [hasAssignments]="hasAssignments$ | async"
          formControlName="servicesAndKiosksCtrl">
        </sf-add-svc-kiosk>
        <div class="end-align-flex"
          fxLayoutGap="24px">
          <button mat-button
            color="primary"
            matStepperPrevious>Back</button>
          <button mat-stroked-button
            data-cy='cancel-add-assignment-btn'
            (click)="cancel()"
            color="primary">Cancel</button>
          <button mat-flat-button
            color="primary"
            data-cy='complete-svc-kiosk-btn'
            matStepperNext>Next</button>
        </div>
      </form>
    </mat-step>
    <mat-step>
      <ng-template matStepLabel>Review</ng-template>

      <div>
        <h2 class="mat-h2"
          data-cy="Review-Assignment">Summary of Selections</h2>

        <h3 class="mat-h3">Users</h3>
        <mat-chip-list aria-label="User Selection">
          <mat-chip *ngFor="let user of usersAndRoleGroup?.get('userAndRoleCtrl')?.value?.users"
            [attr.data-cy]="'chip-' + user.displayName + '-option'">{{user.displayName | searchServiceGetNamePipe | async}} [{{user.id}}]</mat-chip>
        </mat-chip-list>

        <h3 class="mat-h3">Roles</h3>
        <mat-chip-list aria-label="Role Selection">
          <mat-chip *ngFor="let role of usersAndRoleGroup?.get('userAndRoleCtrl')?.value?.roles"
            [attr.data-cy]="'chip-' + role.displayName + '-option'">{{role.displayName}} [{{role.roleId}}]</mat-chip>
        </mat-chip-list>

        <div *ngIf="(roleSelection$ | async)?.roleSelectionType === 'COURSES'">
          <h3 class="mat-h3">Course Configuration</h3>

          <span [attr.data-cy]="'campus-chip-' + coursesGroup?.get('courses')?.value?.campus"
            *ngIf="coursesGroup?.get('courses')?.value">Courses at {{coursesGroup?.get('courses')?.value?.campus}}</span>
          <mat-chip-list aria-label="Course Connection Selection">
            <mat-chip *ngFor="let course of coursesGroup?.get('courses')?.value?.classConfigArray"
              [attr.data-cy]="'chip-' + course.subject">
              <span>{{course.subject}}</span>
              <span *ngIf="course.course !== 'ALL' && course.course !== 'NONE'">.{{course.course}}</span>
              <span *ngIf="course.section !== 'ALL' && course.section !== 'NONE'">.{{course.section}}</span>
            </mat-chip>
          </mat-chip-list>
          <span *ngIf="!coursesGroup?.get('courses')?.value">--None Selected--</span>
        </div>

        <div *ngIf="(roleSelection$ | async)?.roleSelectionType === 'ONE_TO_ONE'">
          <h3 class="mat-h3">Connected Students</h3>
          <div class="connected-student-detail">
            <sf-compare-users #compare
              (deleteUserEmitter)="viewConnectedStudents(stepper, 'VIEW_DELETE', $event)"
              (addUserEmitter)="viewConnectedStudents(stepper, 'VIEW_ADD', $event)"
              [originalDataSet]="clonedOriginalDataSet"></sf-compare-users>
            <button mat-flat-button
              *ngIf="connectedStudentsGroup?.get('connectedUsers')?.value?.users?.length !== 0"
              color="primary"
              data-cy="view-edit-all-connections"
              (click)="viewConnectedStudents(stepper, 'EDIT')">View All / Edit </button>
            <span *ngIf="connectedStudentsGroup?.get('connectedUsers')?.value?.users?.length === 0">--None Selected--</span>
          </div>
        </div>

        <div *ngIf="(roleSelection$ | async)?.roleSelectionType === 'ORG' || !(roleSelection$ | async)?.roleSelectionType">
          <h3 class="mat-h3">Organizations</h3>
          <mat-chip-list aria-label="Organizations Selection">
            <mat-chip *ngFor="let org of organizationsGroup?.get('orgsCtrl')?.value?.orgs"
              [attr.data-cy]="'chip-' + org.displayName + '-option'">{{org.displayName}}</mat-chip>
          </mat-chip-list>
          <span *ngIf="organizationsGroup?.get('orgsCtrl')?.value?.orgs.length === 0">--None Selected--</span>
        </div>

        <h3 class="mat-h3">Services</h3>
        <mat-chip-list aria-label="Services Selection">
          <mat-chip *ngFor="let org of kioskAndServiceGroup?.get('servicesAndKiosksCtrl')?.value?.services">{{org.displayName}}</mat-chip>
        </mat-chip-list>
        <span data-cy="no-services-selected"
          *ngIf="kioskAndServiceGroup?.get('servicesAndKiosksCtrl')?.value?.services.length === 0">--None Selected--</span>

        <h3 class="mat-h3">Kiosks</h3>
        <mat-chip-list aria-label="Kiosks Selection">
          <mat-chip *ngFor="let org of kioskAndServiceGroup?.get('servicesAndKiosksCtrl')?.value?.kiosks">{{org.displayName}}</mat-chip>
        </mat-chip-list>
        <span data-cy="no-kiosks-selected"
          *ngIf="kioskAndServiceGroup?.get('servicesAndKiosksCtrl')?.value?.kiosks.length === 0">--None Selected--</span>

      </div>

      <div class="end-align-flex"
        fxLayoutGap="24px">

        <button mat-button
          color="primary"
          data-cy="back-from-complete"
          matStepperPrevious>Back</button>
        <button mat-stroked-button
          data-cy='cancel-add-assignment-btn'
          (click)="cancel()"
          color="primary">Cancel</button>
        <button mat-flat-button
          data-cy='complete-add-assignment-btn'
          (click)="complete()"
          [disabled]="!usersAndRoleGroup.valid"
          color="primary">Complete</button>
      </div>
    </mat-step>
    <mat-step *ngIf="(viewConnectedStudents$ | async) !== 'HIDE'">
      <ng-template matStepLabel>Edit Connected Students</ng-template>
      <form [formGroup]="connectedStudentsGroup">

        <sf-connected-student-summary *ngIf="(viewConnectedStudents$ | async) === 'EDIT'"
          formControlName="connectedUsers"></sf-connected-student-summary>

        <sf-connected-student-delete-readonly *ngIf="(viewConnectedStudents$ | async) === 'VIEW_DELETE'"
          [userList]="(studentsToView$ | async)">
        </sf-connected-student-delete-readonly>

        <sf-connected-student-add-readonly *ngIf="(viewConnectedStudents$ | async) === 'VIEW_ADD'"
          [userList]="(studentsToView$ | async)">
        </sf-connected-student-add-readonly>

        <button mat-button
          color="primary"
          data-cy="back-to-complete"
          matStepperPrevious>Back</button>
      </form>
    </mat-step>
  </mat-horizontal-stepper>
</div>
