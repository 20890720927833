import { Component, forwardRef, Input, OnChanges, OnDestroy, SimpleChanges } from '@angular/core';
import {
  ControlValueAccessor,
  UntypedFormArray,
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  NG_VALUE_ACCESSOR,
} from '@angular/forms';
import { MatLegacyCheckboxChange as MatCheckboxChange } from '@angular/material/legacy-checkbox';
import { MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { MatLegacySlideToggleChange as MatSlideToggleChange } from '@angular/material/legacy-slide-toggle';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { ConfirmDialogService, OkayDialogService } from '@starfish-access/core';
import { saveAs } from 'file-saver';
import { clone } from 'ramda';
import { BehaviorSubject, EMPTY, of, Subject } from 'rxjs';
import { catchError, mergeMap, takeUntil } from 'rxjs/operators';
import { ConfiguredAssignments, ConfiguredDelegation } from '../../../core/model/delegation.model';
import { AssignUserComponent } from '../assignment-form/assign-user/assign-user.component';
import { AssignmentReportComponent, AssignmentReportData } from '../assignment-report/assignment-report.component';
import { DeleteDialogComponent } from '../delete-dialog.component';
import { DirectorsFacade } from '../directors.facade';
import { AssignUserComponentData } from '../directors.model';

export interface DelegatedUsers {
  name: string;
  userid: string;
  rolename: string;
  target: string;
}

@Component({
  selector: 'sf-directors-user-table',
  templateUrl: './user-table.component.html',
  styleUrls: ['./user-table.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => UserTableComponent),
      multi: true,
    },
  ],
})
export class UserTableComponent implements OnDestroy, ControlValueAccessor, OnChanges {
  @Input() configuredDelegation: ConfiguredDelegation;

  checkboxDetails =
    '1. Enabled & Unchecked: Assignment is available to transition\r\n' +
    '2. Grayed out & Checked: Assignment has already been transitioned\r\n' +
    '3. Grayed out & Unchecked: Assignment is not available to transition\r\n' +
    '4. No Checkbox: Not a course based assignment / Nothing to transition';
  displayedColumns: string[] = ['name', 'userid', 'rolename', 'transition', 'calendar', 'roleconfig', 'delete'];
  // we need to keep a record of when the checkbox in an associated row is clicked.
  // we cannot use the loaded state of the checkbox.
  // For example, if it is loaded as checked and disabled, that means something different then enabled then checked by the user.
  transitionSelectedMap = new Map<number, BehaviorSubject<boolean>>();

  formGroup: UntypedFormGroup;
  dataSource: MatTableDataSource<ConfiguredAssignments> = new MatTableDataSource([]);
  private destroy$ = new Subject<void>();
  private cdClone: ConfiguredDelegation;

  constructor(
    private fb: UntypedFormBuilder,
    public facade: DirectorsFacade,
    private dialog: MatDialog,
    private confirmDialogService: ConfirmDialogService,
    public okayDialogService: OkayDialogService
  ) {
    this.formGroup = fb.group({
      assignments: this.fb.array([]),
    });

    this.formGroup.valueChanges.subscribe((value) => {
      this.propagateChange(value);
    });
  }

  get assignmentsFormArray() {
    return this.formGroup.get('assignments') as UntypedFormArray;
  }

  writeValue(anyObj: any): void {
    let obj: ConfiguredAssignments[];
    if (anyObj?.assignments) {
      obj = anyObj.assignments;
    } else {
      obj = anyObj;
    }

    this.assignmentsFormArray.clear();
    if (obj) {
      obj.forEach((element) => {
        this.assignmentsFormArray.push(new UntypedFormControl(element));
      });
      this.dataSource = new MatTableDataSource(clone(this.assignmentsFormArray.value));
    }
  }
  registerOnChange(fn: any): void {
    this.propagateChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.propagateTouch = fn;
  }

  transitionClicked(index: number, event: MatCheckboxChange) {
    const bs = this.transitionSelectedMap.get(index)
      ? this.transitionSelectedMap.get(index)
      : new BehaviorSubject<boolean>(false);
    bs?.next(event.checked);
    if (bs) {
      this.transitionSelectedMap.set(index, bs);
      const ca: ConfiguredAssignments = this.assignmentsFormArray.controls[index].value;
      ca.selectedToTransition = event.checked;
    }
    this.assignmentsFormArray.controls[index].markAsDirty();
    this.assignmentsFormArray.controls[index].updateValueAndValidity();
  }

  calMgrExclusionClicked(index: number, event: MatSlideToggleChange) {
    const ca: ConfiguredAssignments = this.assignmentsFormArray.controls[index].value;
    ca.calendarManagementEnabled = event.checked;

    this.assignmentsFormArray.controls[index].markAsDirty();
    this.assignmentsFormArray.controls[index].updateValueAndValidity();
  }

  resetTransitionSelectedMap() {
    this.transitionSelectedMap.forEach((element) => {
      element.next(false);
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.configuredDelegation) {
      if (this.configuredDelegation) {
        this.cdClone = clone(this.configuredDelegation);
      }
      this.configuredDelegation?.assignedRoles.forEach((element) => {
        this.assignmentsFormArray.push(new UntypedFormControl(element));
      });

      this.dataSource = new MatTableDataSource(this.assignmentsFormArray.value);
    }
  }

  deleteAssignment(index: number, ca: ConfiguredAssignments): void {
    let loadingDialogRef: MatDialogRef<DeleteDialogComponent>;

    const delegationId: number = this.configuredDelegation.delegation.id ? this.configuredDelegation.delegation.id : -1;
    const assignmentId: number = ca.assignmentId ? ca.assignmentId : -1;
    let error = false;
    this.confirmDialogService
      .confirmDelete('This will delete the assignment right now and cannot be undone. Are you sure?')
      .pipe(
        mergeMap((res) => {
          if (res) {
            // user chose to delete the assignment
            loadingDialogRef = this.dialog.open(DeleteDialogComponent, {
              disableClose: true,
            });
            // if this is a new assignment (not persisted yet), we can just delete it.
            if (assignmentId === -1) {
              return of(true);
            }
            return this.facade.deleteConfiguredAssignment(delegationId, assignmentId).pipe(
              catchError((err) => {
                if (loadingDialogRef) {
                  try {
                    loadingDialogRef.close();
                  } catch {}
                }
                error = true;
                return this.okayDialogService.okay(
                  'Assignment Not Updated',
                  'We could not delete your assignment at this time'
                );
              })
            );
          }
          return EMPTY;
        }),
        takeUntil(this.destroy$)
      )
      .subscribe(() => {
        if (loadingDialogRef) {
          try {
            loadingDialogRef.close();
          } catch {}
        }

        if (!error) {
          this.assignmentsFormArray.removeAt(index);
          this.dataSource = new MatTableDataSource(clone(this.assignmentsFormArray.value));
        }
      });
  }

  editAssignment(index: number): void {
    const data: AssignUserComponentData = {
      delegation: this.configuredDelegation,
      preloadedAssignment: this.assignmentsFormArray.controls[index].value,
    };
    const dialogRef = this.dialog.open(AssignUserComponent, {
      width: '850px',
      disableClose: true,
      data,
    });
    dialogRef.afterClosed().subscribe((result: ConfiguredAssignments[]) => {
      if (result) {
        this.assignmentsFormArray.controls[index].patchValue(result[0]); // when we edit, only 1 user at a time should be allowed
      }
    });
  }

  addAssignment(): void {
    const data: AssignUserComponentData = {
      delegation: this.cdClone ? this.cdClone : this.configuredDelegation,
      preloadedAssignment: undefined,
      listOfAssignments: this.dataSource.data,
    };
    const dialogRef = this.dialog.open(AssignUserComponent, {
      width: '850px',
      disableClose: true,
      data,
    });

    dialogRef
      .afterClosed()
      .pipe(takeUntil(this.destroy$))

      .subscribe((result: ConfiguredAssignments[]) => {
        if (result) {
          result.forEach((element) => {
            this.assignmentsFormArray.push(new UntypedFormControl(element));
          });
          this.dataSource = new MatTableDataSource(clone(this.assignmentsFormArray.value));
        }
      });
  }

  viewAssignmentReport(): void {
    const data: AssignmentReportData = {
      assignmentData: this.assignmentsFormArray.value,
    };
    this.dialog
      .open(AssignmentReportComponent, {
        width: '850px',
        data,
      })
      .afterClosed()
      .pipe(
        mergeMap((res) => {
          if (res && this.configuredDelegation.delegation.id) {
            return this.facade.downloadAssignmentReport(this.configuredDelegation.delegation.id.toString());
          }
          return of(null);
        }),
        takeUntil(this.destroy$),
        catchError(() => of(undefined))
      )
      .subscribe((csvFileResult) => {
        if (csvFileResult === undefined) {
          this.okayDialogService.okay('Report Not Downloaded', 'The service is not available at this time.');
        } else if (csvFileResult) {
          const filename = this.configuredDelegation.delegation.name + '_report.csv';
          saveAs(csvFileResult, filename);
        }
      });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  private propagateChange: (_: any) => any = (_: any) => ({});
  private propagateTouch: (_: any) => any = (_: any) => ({});
}
