<div fxLayout="column"
  class="new-org-div"
  [formGroup]="newRoleGroupForm">
  <h3>Role Group Name</h3>
  <span class="md2-emphasis-md">Create a new role group name</span>
  <mat-form-field appearance="outline">
    <mat-label>New Role Group</mat-label>
    <input matInput
      formControlName="name"
      placeholder="New Role Group Name"
      data-cy='input-role-group-name'
      [errorStateMatcher]="matcher">
    <mat-hint>This name must be unique</mat-hint>
    <mat-error *ngIf="newRoleGroupForm?.get('name')?.errors && newRoleGroupForm?.get('name')?.errors?.exists">This group name already exists. Please enter a different name.</mat-error>
  </mat-form-field>

  <div fxLayout="row"
    class="icon-selection">
    <div fxLayout="column">
      <sf-color-selector [loadedRoleGroup]="loadedRoleGroup"
        [colorSelection]='colorSelectionSubj'
        [roleGroups]='facade.roleGroups$ | async'></sf-color-selector>
      <sf-icon-selector [loadedRoleGroup]="loadedRoleGroup"
        [iconSelection]='iconSelectionSubj'
        [roleGroups]='facade.roleGroups$ | async'>
      </sf-icon-selector>
    </div>

    <sf-icon-preview [colorSelection]='colorSelectionSubj'
      [iconSelection]='iconSelectionSubj'>
    </sf-icon-preview>

  </div>

  <div fxLayout="column">
    <sf-group-priority #priorityComponent
      [isEditing]="(loadedRoleGroup !== undefined)"
      [newGroupName]="newGroupNameSubj"
      [newGroupColor]="colorSelectionSubj | async"
      [priorityObs]="sortedPriorityListObs"
      [roleGroupPriorities]="facade.roleGroupPriorities$">
    </sf-group-priority>
  </div>
</div>
