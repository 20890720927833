import { AfterViewInit, Component, Inject, ViewChild } from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { MatLegacyPaginator as MatPaginator } from '@angular/material/legacy-paginator';
import { MatSort } from '@angular/material/sort';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { ConfiguredAssignments, OrgModel } from '@starfish-access/models';
import { clone } from 'ramda';

export interface AssignmentReportData {
  assignmentData: ConfiguredAssignments[];
}

interface AssignmentReportDisplay {
  userName: string;
  userId: string;
  roleName: string;
  target: string;
  service: string;
  kiosk: string;
}

@Component({
  selector: 'sf-assignment-report',
  templateUrl: './assignment-report.component.html',
  styleUrls: ['./assignment-report.component.scss'],
})
export class AssignmentReportComponent implements AfterViewInit {
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  displayedColumns: string[] = ['userName', 'userId', 'roleName', 'target', 'service', 'kiosk'];
  dataSource = new MatTableDataSource<AssignmentReportDisplay>();

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: AssignmentReportData
  ) {
    const dataToDisplay: AssignmentReportDisplay[] = [];
    this.data?.assignmentData?.forEach((oneAssignment) => {
      const dataBlock: AssignmentReportDisplay[] = this.toResportDisplay(oneAssignment);
      dataBlock?.forEach((res) => {
        dataToDisplay.push(res);
      });
    });

    this.dataSource.data = dataToDisplay;
  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  private toResportDisplay(assignment: ConfiguredAssignments): AssignmentReportDisplay[] {
    const baseDisplayItem: AssignmentReportDisplay = {
      userName: assignment.user.displayName,
      userId: assignment.user.id,
      roleName: assignment.role.displayName,
      target: assignment.role.roleSelectionType,
      kiosk: '--',
      service: '--',
    };

    const kiosks: OrgModel[] = assignment.options.kiosksConfig.selectedItems;
    const services: OrgModel[] = assignment.options.servicesConfig.selectedItems;

    const displayArray: AssignmentReportDisplay[] = [];

    if (!services || services?.length === 0) {
      const displayItemToBuild = clone(baseDisplayItem);
      displayArray.push(displayItemToBuild);
    }
    services?.forEach((svc) => {
      if (!kiosks || kiosks?.length === 0) {
        const displayItemToBuild = clone(baseDisplayItem);
        displayItemToBuild.service = svc.name;
        displayArray.push(displayItemToBuild);
      }
      kiosks?.forEach((kiosk) => {
        const displayItemToBuild = clone(baseDisplayItem);
        displayItemToBuild.service = svc.name;
        displayItemToBuild.kiosk = kiosk.name;
        displayArray.push(displayItemToBuild);
      });
    });

    return displayArray;
  }
}
