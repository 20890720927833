import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatLegacyListModule as MatListModule } from '@angular/material/legacy-list';
import { ResponsiveModule } from '@psu/utils/responsive';
import { FooterComponent } from './footer.component';

@NgModule({
  imports: [CommonModule, FlexLayoutModule, MatListModule, ResponsiveModule],
  declarations: [FooterComponent],
  exports: [FooterComponent],
})
export class FooterModule {}
