import { Component, Inject } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { AccessRequest, RequestItem } from '@starfish-access/models';
import { User as TypeAheadUser } from '@starfish-access/shared';
import { Observable } from 'rxjs';
import { StarfishConfig, STARFISH_CONFIG } from 'src/app/starfish.config';
import { UserDetailsFacade } from '../user-details.facade';
import { NewUserDialogData, SearchServiceConfig, Users } from '../user-details.model';

@Component({
  selector: 'sf-new-user',
  templateUrl: './new-user.component.html',
  styleUrls: ['./new-user.component.scss'],
})
export class NewUserComponent {
  config: SearchServiceConfig;
  selectedUser: TypeAheadUser | undefined;
  existingRoles: Observable<Users> | undefined;
  disableSaveAction: boolean;

  constructor(
    @Inject(STARFISH_CONFIG) private starfishConfig: StarfishConfig,
    private facade: UserDetailsFacade,
    public dialogRef: MatDialogRef<NewUserComponent>,
    @Inject(MAT_DIALOG_DATA) public data: NewUserDialogData
  ) {
    const searchServiceLink = this.starfishConfig.searchService;
    this.config = {
      url: searchServiceLink,
      ssnSearchUrl: '',
      maxResults: 100,
      resultHeight: '48',
      sendAuth: true,
    };
  }

  addNewUser(user: TypeAheadUser) {
    if (!user || user.userid.length < 1) {
      return;
    }
    this.selectedUser = user;
    this.existingRoles = this.facade.getSpecificUserRoles(user.userid);
  }

  clearUser(): void {
    this.selectedUser = undefined;
    this.existingRoles = undefined;
  }

  saveNewUser() {
    if (this.selectedUser) {
      const ar = this.buildAccessRequest(this.selectedUser.userid);

      this.data.dataSource.adminUserAddition(ar, {
        pageStart: 1,
        pageTotal: 5,
      });
      this.dialogRef.close();
    }
  }

  buildAccessRequest(userId: string): AccessRequest {
    // No Role Selected
    const roleRequestItem: RequestItem = {
      type: 'role-request',
      roles: [],
      calendarRoleUsers: [],
      calendarRoleSemesters: [],
    };
    const emptyKioskRequestItem: RequestItem = {
      type: 'kiosk-request',
      kiosks: [],
    };
    const emptySvcRequestItem: RequestItem = {
      type: 'service-request',
      services: [],
    };

    const ar: AccessRequest = {
      accessUser: {
        userId,
      },
      college: '',
      department: '',
      jobTitle: '',
      starfishTrainingFlag: true,
      requestItems: [emptyKioskRequestItem, emptySvcRequestItem, roleRequestItem],
      'request-date': '',
      'last-status-date': '',
    };

    return ar;
  }
}
