<section *ngIf="!(errorMessage | async) || errorMessageDisplayed; else error_message">
  <mat-card class="tasklist-result card-table">
    <sf-togglerequests #sfToggleRequests
      primaryTitle="My Tasking"
      secondaryTitle="All Audit Tasks"
      primaryButton="My Tasks"
      secondaryButton="All Audit Tasks"
      [isViewingAllSubmissions]='isViewingAllAudits'
      [canViewAll]='isUserDataSteward'
      [isUserSteward]='isUserDataSteward'
      (viewEmitter)='viewAuditTasks()'></sf-togglerequests>

    <mat-card-content>
      <div>
        <span *ngIf="(isFetching | async) && !(hasFailed | async)">
          <br />
          <mat-progress-bar mode="indeterminate"></mat-progress-bar>
        </span>

        <span *ngIf="(isFetching | async) && (hasFailed | async)">
          We couldn't find your tasks. Please try again later, or contact an
          admin for help.
        </span>

        <div *ngIf="!(isFetching | async) && dataSource?.data?.length > 0">

          <div fxLayout="row">
            <mat-form-field class="starfish-filter"
              fxFlex>
              <input matInput
                (keyup)="applyFilter($event.target.value)"
                placeholder="Filter">
            </mat-form-field>
          </div>

          <table mat-table
            [dataSource]="dataSource"
            matSort
            class="mat-elevation-z0 starfish-data-table">
            <ng-container matColumnDef="taskId">
              <th mat-header-cell
                *matHeaderCellDef
                mat-sort-header
                class="standard-col-width">Task ID</th>
              <td mat-cell
                *matCellDef="let element"
                class="hand-pointer"
                matTooltip="View Task Details"
                [matTooltipShowDelay]="tooltipDelay"
                (click)="getApproved(element)">
                {{ element.taskId }}
              </td>
            </ng-container>

            <ng-container matColumnDef="requester">
              <th mat-header-cell
                *matHeaderCellDef
                mat-sort-header
                class="standard-col-width">
                <span *ngIf="!isViewingAllAudits">Requester</span>
                <span *ngIf="isViewingAllAudits">Audited User</span>
              </th>
              <td mat-cell
                *matCellDef="let element"
                class="hand-pointer"
                matTooltip="View Task Details"
                [matTooltipShowDelay]="tooltipDelay"
                (click)="getApproved(element)">
                {{ element.requester }}
              </td>
            </ng-container>

            <ng-container matColumnDef="taskName">
              <th mat-header-cell
                mat-sort-header
                *matHeaderCellDef
                class="standard-col-width">Tasking</th>
              <td [attr.data-cy]="element.taskId+'-taskname-item'"
                mat-cell
                *matCellDef="let element"
                class="hand-pointer"
                matTooltip="View Task Details"
                [matTooltipShowDelay]="tooltipDelay"
                (click)="getApproved(element)">
                {{ element.taskName }}
              </td>
            </ng-container>

            <ng-container matColumnDef="taskStatus">
              <th mat-header-cell
                mat-sort-header
                *matHeaderCellDef
                class="status-col-width">Status</th>
              <td [attr.data-cy]="element.taskId+'-task-item'"
                mat-cell
                *matCellDef="let element"
                class="hand-pointer"
                matTooltip="View Task Details"
                [matTooltipShowDelay]="tooltipDelay"
                (click)="getApproved(element)">
                {{ element.taskStatus | initialCaps }}
              </td>
            </ng-container>

            <ng-container matColumnDef="taskCreatedTime">
              <th mat-header-cell
                mat-sort-header
                *matHeaderCellDef
                class="standard-col-width">Created Time</th>
              <td mat-cell
                *matCellDef="let element"
                class="hand-pointer"
                matTooltip="View Task Details"
                [matTooltipShowDelay]="tooltipDelay"
                (click)="getApproved(element)">
                <!-- Unable to convert "06-01-2018 12:51:44" into a date' date:'HH:mm' -->
                {{ element.taskCreatedTime | starfishDatePipe }}
              </td>
            </ng-container>

            <ng-container matColumnDef="processInstanceId">
              <th mat-header-cell
                mat-sort-header
                *matHeaderCellDef
                class="standard-col-width">
                <span *ngIf="!isViewingAllAudits">Process ID</span>
                <span *ngIf="isViewingAllAudits">Audited Role</span>
              </th>
              <td mat-cell
                *matCellDef="let element"
                class="hand-pointer"
                matTooltip="View Task Details"
                [matTooltipShowDelay]="tooltipDelay"
                (click)="getApproved(element)">
                {{ element.processInstanceId }}
              </td>
            </ng-container>

            <tr mat-header-row
              *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row
              *matRowDef="let row; columns: displayedColumns"></tr>
          </table>

          <mat-paginator class="starfish-paginator"
            [pageSizeOptions]="[10, 20]"
            showFirstLastButtons></mat-paginator>
        </div>

        <span *ngIf="!(isFetching | async) && dataSource?.data?.length < 1">
          <br />
          <p fxLayoutAlign="center center">
            <strong data-cy="no-tasks-to-complete">You have no tasks to complete.</strong>
          </p>
          <br />
        </span>
      </div>
    </mat-card-content>
  </mat-card>
</section>
<ng-template #error_message>
  <div class="error_message"><strong>ERROR:</strong> {{ errorMessage }}</div>
  {{ clearErrors() }}
</ng-template>
