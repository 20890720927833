<div fxLayout="row"
  class="workflow-header-component"
  fxLayoutGap="24px">
  <sf-workflow-icon [taskStatus]="processDetailsStatus ? processDetailsStatus : taskDetails?.taskStatus"> </sf-workflow-icon>
  <div fxLayout="row"
    fxLayoutGap="24px"
    *ngIf="taskDetails?.taskStatus !== 'loading...'">
    <div fxLayout="column">
      <span class="md2-emphasis-md mat-body-2">Status</span>
      <span class="mat-body-2"
        data-cy="process-details-status">{{(processDetailsStatus ? processDetailsStatus : taskDetails?.taskStatus) | initialCaps}}</span>
    </div>
    <div fxLayout="column">
      <span class="md2-emphasis-md mat-body-2">Task ID</span>
      <span class="mat-body-2">#{{taskDetails?.taskId}}</span>
    </div>
    <div fxLayout="column">
      <span class="md2-emphasis-md mat-body-2">Requested by</span>
      <span class="mat-body-2"><a href="{{psuDirectoryLink}}{{taskDetails?.requester}}"
          target="_blank">{{taskDetails?.requester}}</a></span>
    </div>
  </div>
  <div fxFlex></div>
  <div *ngIf="(taskDetails?.taskStatus !== 'CANCELLED')">
    <span *ngIf='!(submitInProgress | async) && !isRequestView'
      fxLayout="row"
      class="request-details-buttons"
      fxLayoutGap="8px">
      <button mat-stroked-button
        data-cy='complete-submit'
        *ngIf="isReturned()"
        class="approve-buttons"
        (click)="completeRequest('APPROVE')">SUBMIT</button>
      <button mat-stroked-button
        data-cy='approve-submit'
        *ngIf="!isReturned()"
        [attr.data-cy]="'btn-approve'"
        class="approve-buttons"
        (click)="completeRequest('APPROVE')">APPROVE</button>
      <div class="split-buttons-div"
        *ngIf="!isReturned()">
        <uic-split-button (action)="completeRequest($event)"
          [actions]="splitButtonCancel"
          color="warn">
        </uic-split-button>
      </div>
    </span>
  </div>
  <span *ngIf='(submitInProgress | async)'>
    <mat-spinner diameter=50
      color=accent></mat-spinner>
  </span>
</div>
