<!-- <pre>{{newRoleForm?.value | json}}</pre> -->

<form class="dynamic-role-form"
  fxLayout="column"
  [formGroup]="newRoleForm">
  <mat-form-field appearance="outline">
    <mat-label>New Role Name</mat-label>
    <input matInput
      [formControl]="newRoleForm.get('roleName')"
      placeholder="New Role Name"
      [attr.data-cy]="roleToEdit?.roleName +'-input-role-name'">
    <mat-error [attr.data-cy]="roleToEdit?.roleName+'-group-name-error-required'"
      *ngIf="newRoleForm?.get('roleName')?.errors && newRoleForm?.get('roleName')?.errors?.required">You must supply a name</mat-error>
    <mat-error [attr.data-cy]="roleToEdit?.roleName+'-group-name-error'"
      *ngIf="newRoleForm?.get('roleName')?.errors && newRoleForm?.get('roleName')?.errors?.exists">This group name already exists for the selected Group Type. Please enter a different name</mat-error>
  </mat-form-field>

  <ng-template #clearButtonIconTemplate>
    <mat-icon>clear</mat-icon>
  </ng-template>

  <h2>Role Group</h2>
  <mat-radio-group (change)="groupChanged($event)"
    color="primary"
    aria-label="Which Group should this role be a part of?"
    fxLayout="column">
    <mat-radio-button checked='true'
      [ngClass]="{'hidden': !includeNewGroup}"
      #existingGroupRadioButton
      value="existinggroup"
      data-cy="existing-role-group-radio"
      class="mat-body-1 md2-emphasis-md">Is this new role part of an existing role group?</mat-radio-button>
    <mat-form-field appearance="outline"
      *ngIf="groupSelectionSubj | async">
      <mat-label>Select a Group</mat-label>
      <mat-select [compareWith]="compareGroups"
        formControlName="existingGroup"
        data-cy='select-existing-rolegroup'>
        <mat-option *ngFor="let group of roleGroups$"
          [value]="group"
          [attr.data-cy]="group.name+'-group-item'">
          {{group.name}}
        </mat-option>
      </mat-select>
      <mat-error>You must select a group</mat-error>
    </mat-form-field>
    <mat-radio-button *ngIf="includeNewGroup"
      value="newgroup"
      class="mat-body-1 md2-emphasis-md"
      data-cy="new-role-group-radio">Is this new role part of a new role group?</mat-radio-button>
    <sf-new-role-group *ngIf="!(groupSelectionSubj | async)"
      [requireNewGroup]="!(groupSelectionSubj | async)"
      formControlName="newGroup"></sf-new-role-group>
  </mat-radio-group>

  <!-- <pre> {{ newRoleForm.get('existingGroup')?.value.id }}</pre> -->
  <!-- <pre>{{descriptions?.value | json }}</pre> -->

  <sf-descriptions formControlName="descriptions">

  </sf-descriptions>

  <h2 class="admin-only-title">Role Control Selection</h2>
  <mat-radio-group aria-label="Select an option"
    id="main-radio-group"
    color="primary"
    class="role-control-selection-radio"
    fxLayout="row"
    [formControl]="adminOnlyCtrl">
    <mat-radio-button data-cy="role-control-user-requested"
      checked
      value="USER_REQUESTABLE">User Requested</mat-radio-button>
    <mat-radio-button data-cy="role-control-Administration"
      value="ADMIN_ONLY">Administration</mat-radio-button>
    <mat-radio-button data-cy="role-control-Delegation"
      value="DELEGATION_ONLY">Delegation</mat-radio-button>
    <mat-radio-button data-cy="role-control-Delegation"
      value="READ_ONLY">Info Only</mat-radio-button>
  </mat-radio-group>

  <sf-dynamic-role-orgs #orgSelectionComponent
    [ngClass]="{'hidden': adminOnlyCtrl?.value==='READ_ONLY'}"
    [editMode]="roleToEdit !== undefined"
    [userRequestedSelected]="adminOnlyCtrl?.value==='USER_REQUESTABLE'"
    [formControl]="relationships">
  </sf-dynamic-role-orgs>

  <sf-service-selector #servicesList
    [ngClass]="{'hidden': adminOnlyCtrl?.value==='READ_ONLY'}"
    [possibleKiosks]="facade.kiosks$ | async"
    [possibleServices]="facade.services$ | async"
    [formControl]="servicesAndKiosksControl">
  </sf-service-selector>

  <sf-auth-flow *ngIf="adminOnlyCtrl?.value === 'USER_REQUESTABLE'"
    #authFlowComponent
    [formControl]="authCtrl"
    [roleName]="roleToEdit?.roleName"></sf-auth-flow>

</form>
