<div fxLayout="row">
  <div>
    <span
      class="icon-dot"
      [ngClass]="(myRoles | hasRolePipe: roleName) ? 'gray' : iconColor"
      fxLayoutAlign="center center">
      <mat-icon
        class="role-icon"
        [svgIcon]="iconName"></mat-icon>
    </span>
  </div>
  <div [ngClass]="isOpen ? 'fade-icon-in' : 'fade-icon-out'">
    <span class="opened-icon">
      <mat-icon
        class="opened-icon-check"
        [ngClass]="(myRoles | hasRolePipe: roleName) ? 'gray-check' : ''">
        check_circle_outline
      </mat-icon>
    </span>
  </div>
</div>
