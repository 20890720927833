import { Component, Inject } from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { FilterSyncedCoursesPipe } from '../../../core/pipe/is-unsynced-courses.pipe';
import { toCourseString } from '../course-mapping.facade';

@Component({
  templateUrl: './save-sync.component.html',
  styleUrls: ['./save-sync.component.scss'],
})
export class SaveSyncComponent {
  private pipe: FilterSyncedCoursesPipe = new FilterSyncedCoursesPipe();

  constructor(@Inject(MAT_DIALOG_DATA) public data: any) {}

  courseCopyString(): string {
    if (this.data.channel?.courses) {
      return toCourseString(this.pipe.transform(this.data.channel.courses));
    }
    return '';
  }
}
