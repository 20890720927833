<h1>Edit Existing Role Group</h1>
<div *ngIf="(facade.isFetching$ | async)">
  <mat-progress-bar mode="indeterminate"></mat-progress-bar>
</div>
<div *ngIf="!(facade.isFetching$ | async)"
  class="edit-role-group-container-class">
  <mat-accordion>
    <mat-expansion-panel *ngFor="let roleGroup of (facade.roleGroups$ | async); let componentIndex = index;"
      class="mat-elevation-z0 exp-panel-class">
      <mat-expansion-panel-header class="exp-panel-header"
        [attr.data-cy]="roleGroup.name+'-editable-item'">
        <mat-panel-title fxLayoutAlign="start center"
          fxLayoutGap="8px">
          <sf-role-icon [roleName]="roleGroup.name"
            [iconName]="roleGroup.iconImage"
            [iconColor]="roleGroup.iconColor"></sf-role-icon>

          <div fxLayout="column"
            class="role-name-div">
            <span class="mat-subtitle-1">{{roleGroup.name}}</span>
          </div>

        </mat-panel-title>
      </mat-expansion-panel-header>

      <form [formGroup]="createFormGroup(roleGroup)">
        <sf-new-role-group #roleGroupComp
          [requireNewGroup]="false"
          [loadedRoleGroup]="roleGroup"
          formControlName="existingGroup"></sf-new-role-group>
      </form>

      <div class="role-buttons"
        fxLayoutAlign="end center"
        fxLayoutGap="16px">
        <button *ngIf="!(facade.isUpdating$ | async)"
          mat-stroked-button
          color="primary"
          (click)="clearEntries(componentIndex, roleGroup)">Reset</button>
        <button *ngIf="!(facade.isUpdating$ | async)"
          mat-flat-button
          color="primary"
          (click)="updateRoleGroup(groupMap.get(roleGroup.name), roleGroup, componentIndex)"
          [attr.data-cy]="roleGroup.name+'-update-this-item'">Update</button>
        <mat-progress-bar *ngIf="(facade.isUpdating$ | async)"
          data-cy="save-role-progress-bar"
          mode="indeterminate"></mat-progress-bar>
      </div>
    </mat-expansion-panel>
  </mat-accordion>
</div>
