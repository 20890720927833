<div
  *ngIf="canAdd | async"
  class="add-new-users"
  utResponsive>
  <ta-typeahead
    [attr.data-cy]="userType + '-autocomplete'"
    [config]="config"
    [floatLabel]="'never'"
    [placeholder]="'Add a user (User ID, Name)'"
    [resultTemplate]="resultTemplate"
    (selected)="addNewUser($event)"
    [noResultsTemplate]="noResultsTemplate"
    [autoActiveFirstOption]="false"
    [clearButtonIconTemplate]="clearButtonIconTemplate"
    [showSearchButton]="true"
    [showMatPrefix]="false"
    #ta1>
  </ta-typeahead>

  <mat-card
    class="search-result"
    *ngFor="let result of searchResults"
    fxLayout="row"
    fxLayoutAlign="start center">
    <span
      fxLayout="column"
      class="result-content">
      <span>{{ result.displayName }} ({{ result.userid }})</span>
    </span>
  </mat-card>
  <mat-card
    class="search-result"
    *ngIf="searchResults ? searchResults.length === 0 : false"
    fxLayout="row"
    fxLayoutAlign="start center">
    <span
      fxLayout="column"
      class="result-content">
      No Results Found
    </span>
  </mat-card>
  <!-- This template is for the display of results in autocomplete overlay -->
  <ng-template
    #resultTemplate
    let-result>
    <span
      fxLayout="row"
      fxLayoutAlign="start center">
      <span
        fxLayout="row"
        class="result-content"
        fxFlex>
        <span>{{ result.displayName }} ({{ result.userid }})</span>
        <span fxFlex></span>
        <span fxLayoutAlign="center center">
          <button
            mat-raised-button
            color="accent"
            class="add-user-button mat-elevation-z0">
            <i class="material-icons">person_add</i>ADD
          </button>
        </span>
      </span>
    </span>
  </ng-template>
  <ng-template #noResultsTemplate> No Results Found </ng-template>
  <ng-template #clearButtonIconTemplate>
    <mat-icon>clear</mat-icon>
  </ng-template>
</div>
