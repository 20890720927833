import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatLegacyDialogModule as MatDialogModule } from '@angular/material/legacy-dialog';
import { ResponsiveModule } from '@psu/utils/responsive';
import { OkayDialogComponent } from './okay-dialog.component';
import { OkayDialogService } from './okay-dialog.service';

@NgModule({
  imports: [CommonModule, MatDialogModule, MatButtonModule, FlexLayoutModule, ResponsiveModule],
  declarations: [OkayDialogComponent],
  exports: [OkayDialogComponent],
  providers: [OkayDialogService],
})
export class OkayDialogModule {}
