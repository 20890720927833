import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatLegacyCardModule as MatCardModule } from '@angular/material/legacy-card';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import { MatLegacyListModule as MatListModule } from '@angular/material/legacy-list';
import { MatLegacyProgressBarModule as MatProgressBarModule } from '@angular/material/legacy-progress-bar';
import { MatLegacyProgressSpinnerModule as MatProgressSpinnerModule } from '@angular/material/legacy-progress-spinner';
import { MatLegacySelectModule as MatSelectModule } from '@angular/material/legacy-select';
import { MatLegacyTableModule as MatTableModule } from '@angular/material/legacy-table';
import { MatLegacyTooltipModule as MatTooltipModule } from '@angular/material/legacy-tooltip';
import { RouterModule } from '@angular/router';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { FilterModule } from '@starfish-access/core';
import {
  WorkflowDetailsModule,
  WorkflowHeaderModule,
  WorkflowHistoryModule,
  WorkflowIconModule,
} from '@starfish-access/shared';
import { ApproveComponent } from './approve.component';
import { ApproveEffects } from './approve.effects';
import { taskDetailsReducer } from './approve.reducer';
import { routes } from './approve.routing';
import { ApproveService } from './approve.service';
import { TextAreaDialogComponent } from './enter-comment/textarea-dialog.component';
import { UserSelectDialogComponent } from './user-select/user-select-dialog.component';

@NgModule({
  imports: [
    CommonModule,
    MatButtonModule,
    MatCardModule,
    MatIconModule,
    MatInputModule,
    MatListModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    MatTooltipModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule.forChild(routes),
    FlexLayoutModule,
    FilterModule,
    MatTableModule,
    MatSelectModule,
    WorkflowDetailsModule,
    WorkflowHeaderModule,
    WorkflowIconModule,
    WorkflowHistoryModule,
    StoreModule.forFeature('taskDetails', taskDetailsReducer),
    EffectsModule.forFeature([ApproveEffects]),
  ],
  declarations: [ApproveComponent, UserSelectDialogComponent, TextAreaDialogComponent],
  providers: [ApproveService],
})
export class ApproveModule {}
