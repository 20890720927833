import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanDeactivate, RouterStateSnapshot } from '@angular/router';
import { ConfirmDialogService } from '@starfish-access/core';
import { Observable } from 'rxjs';
import { DelegationCreateComponent } from './delegation-create.component';

@Injectable({ providedIn: 'root' })
export class DelegationCreateGuard implements CanDeactivate<DelegationCreateComponent> {
  constructor(private dialogService: ConfirmDialogService) {}

  canDeactivate(
    component: DelegationCreateComponent,
    currentRoute: ActivatedRouteSnapshot,
    currentState: RouterStateSnapshot,
    nextState: RouterStateSnapshot
  ): boolean | Observable<boolean> {
    return !!component.delegationFormGroup.pristine
      ? true
      : this.dialogService.confirmDiscard('You have unsaved changes that will be lost if you leave.');
  }
}
