import { Component, OnInit } from '@angular/core';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { MatLegacyTabChangeEvent as MatTabChangeEvent } from '@angular/material/legacy-tabs';
import { Store } from '@ngrx/store';
import { PermissionCheckService } from '@psu/apis/fortress';
import { DataStewardRolePermissions, DusRolePermissions } from '@starfish-access/core';
import { Observable } from 'rxjs';
import { take } from 'rxjs/operators';
import { AddDUS, AddSteward, DeleteDus, DeleteSteward, RequestDUS, RequestStewards } from './roles.actions';
import { User } from './roles.model';
import {
  selectRolesDUSList,
  selectRolesHasFailed,
  selectRolesIsFetchingDUS,
  selectRolesIsFetchingStewards,
  selectRolesStewardList,
} from './roles.reducer';

@Component({
  templateUrl: './roles.component.html',
  styleUrls: ['./roles.component.scss'],
})
export class RolesComponent implements OnInit {
  dusUsers: Observable<User[]>;
  stewardsUsers: Observable<User[]>;
  userRoleColumns: string[] = ['displayName', 'userId', 'delete'];
  hasFailed: Observable<boolean>;
  isFetchingStewards: Observable<boolean>;
  isFetchingDus: Observable<boolean>;
  currentlySelectedTab = 0;

  canViewDS: Observable<boolean>;
  canAddDS: Observable<boolean>;
  canEditDS: Observable<boolean>;
  canDeleteDS: Observable<boolean>;
  canViewDUS: Observable<boolean>;
  canAddDUS: Observable<boolean>;
  canEditDUS: Observable<boolean>;
  canDeleteDUS: Observable<boolean>;

  private permissions: any = {};

  constructor(
    public snackBar: MatSnackBar,
    private store: Store<any>,
    private permissionCheckService: PermissionCheckService
  ) {
    this.canViewDS = this.permissionCheckService.hasPermission(DataStewardRolePermissions.VIEW);
    this.canAddDS = this.permissionCheckService.hasPermission(DataStewardRolePermissions.ADD);
    this.canDeleteDS = this.permissionCheckService.hasPermission(DataStewardRolePermissions.DELETE);

    this.canViewDUS = this.permissionCheckService.hasPermission(DusRolePermissions.VIEW);
    this.canAddDUS = this.permissionCheckService.hasPermission(DusRolePermissions.ADD);
    this.canDeleteDUS = this.permissionCheckService.hasPermission(DusRolePermissions.DELETE);

    this.dusUsers = this.store.select<User[]>(selectRolesDUSList);
    this.stewardsUsers = this.store.select<User[]>(selectRolesStewardList);
    this.hasFailed = this.store.select<boolean>(selectRolesHasFailed);
    this.isFetchingStewards = this.store.select<boolean>(selectRolesIsFetchingStewards);
    this.isFetchingDus = this.store.select<boolean>(selectRolesIsFetchingDUS);
  }

  addNewDataSteward(newUser: User): void {
    this.store.dispatch(new AddSteward(newUser));
  }

  addNewDus(newUser: User): void {
    this.store.dispatch(new AddDUS(newUser));
  }

  ngOnInit(): void {
    this.store.dispatch(new RequestStewards());
    this.store.dispatch(new RequestDUS());

    this.canDeleteDS.pipe(take(1)).forEach((next) => {
      this.permissions.canDeleteDS = next;
    });
    this.canAddDS.pipe(take(1)).forEach((next) => {
      this.permissions.canAddDS = next;
    });
    this.canViewDS.pipe(take(1)).forEach((next) => {
      this.permissions.canViewDS = next;
    });

    this.canDeleteDUS.pipe(take(1)).forEach((next) => {
      this.permissions.canDeleteDUS = next;
    });
    this.canAddDUS.pipe(take(1)).forEach((next) => {
      this.permissions.canAddDUS = next;
    });
    this.canViewDUS.pipe(take(1)).forEach((next) => {
      this.permissions.canViewDUS = next;
    });
  }

  confirmDusDelete(accessId: string) {
    this.store.dispatch(new DeleteDus(accessId));
  }

  confirmStewardDelete(accessId: string) {
    this.store.dispatch(new DeleteSteward(accessId));
  }

  tabChanged(tabChangeEvent: MatTabChangeEvent): void {
    this.currentlySelectedTab = tabChangeEvent.index;
  }
}
