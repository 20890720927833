<div class="course-mapping-list-main">

  <h2 class="groups-h2 md2-emphasis-md">Course Service Mapping</h2>

  <mat-accordion>

    <!-- Mapped Courses that may or may not need synced-->
    <mat-expansion-panel *ngFor="let course of (courseMappingList$ | async)"
      #matRolePanel
      [attr.data-cy]="course?.serviceDto?.name + 'expansion panel'"
      class="mat-elevation-z0 exp-panel-class">
      <mat-expansion-panel-header class="exp-panel-header">
        <mat-panel-title fxLayoutAlign="start center"
          fxLayoutGap="8px">
          <sf-role-icon iconName="golf"
            [isOpen]="matRolePanel.expanded"
            iconColor="green3"
            [roleName]="course.serviceDto?.name"></sf-role-icon>

          <div fxLayout="column"
            class="role-name-div header-with-icon ">
            <span class="mat-subtitle-1"
              [ngClass]="{'accordian-selected': matRolePanel.expanded}">{{course.serviceDto?.name}} </span>
            <mat-icon *ngIf="(course | hasUnsyncedCourses)"
              svgIcon="alert-outline"
              color="warn"
              matTooltip="This service may have unsynchronized courses"
              aria-label="This service may have unsynchronized courses"
              class="course-sync-alert-icon"></mat-icon>
          </div>

        </mat-panel-title>
      </mat-expansion-panel-header>

      <ng-template matExpansionPanelContent>
        <div fxLayout="column">
          <sf-sync-courses-display [courseChannel]="course"></sf-sync-courses-display>

        </div>
      </ng-template>
    </mat-expansion-panel>

    <!-- Unmapped Courses. Section only appears if there are any tasks here-->
    <mat-expansion-panel *ngIf="(unMappedCourseList$| async)?.length > 0"
      #matRolePanel
      [attr.data-cy]="'unmapped-course-expansion panel'"
      class="mat-elevation-z0 exp-panel-class">
      <mat-expansion-panel-header class="exp-panel-header">
        <mat-panel-title fxLayoutAlign="start center"
          fxLayoutGap="8px">
          <sf-role-icon iconName="fire"
            [isOpen]="matRolePanel.expanded"
            iconColor="orange"
            roleName="Unassigned Subjects"></sf-role-icon>

          <div fxLayout="column"
            class="role-name-div">
            <span class="mat-subtitle-1"
              [ngClass]="{'accordian-selected': matRolePanel.expanded}">Unassigned Subjects</span>
          </div>

        </mat-panel-title>

      </mat-expansion-panel-header>

      <ng-template matExpansionPanelContent>
        <div fxLayout="column">
          <sf-unmapped-courses-display>
          </sf-unmapped-courses-display>
        </div>
      </ng-template>
    </mat-expansion-panel>

  </mat-accordion>

</div>
