import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { Selections } from '../../features/course-mapping/unmapped-courses-display/unmapped-courses-display.component';

@Component({
  templateUrl: './confirm-dialog.component.html',
  styleUrls: ['./confirm-dialog.component.scss'],
})
export class ConfirmDialogComponent {
  @Input()
  title: string;
  @Input()
  message: string;
  @Input()
  okLabel = 'Yes';
  @Input()
  cancelLabel = 'Cancel';
  @Input()
  okColor: string;
  @Input()
  cancelColor: string;
  @Input()
  scrollbarFix: boolean;
  @Input()
  includeProgressbar: boolean;
  @Input()
  selections?: Selections[];

  @Output()
  serverAction: EventEmitter<any> = new EventEmitter();

  constructor(public dialogRef: MatDialogRef<ConfirmDialogComponent>) {}
}
