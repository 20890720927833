<span class="sf-service-selector">

  <h2>Services</h2>
  <mat-slide-toggle #servicesCheckbox
    [formControl]="servicesCheckboxControl"
    color="primary"
    class="basic-checkbox mat-body-1 md2-emphasis-md">Are existing services needed for this new role?</mat-slide-toggle>

  <mat-radio-group fxLayout="row"
    color="primary"
    class="services-selection-group md2-emphasis-md"
    [formControl]="serviceDelegationControl"
    aria-label="Are you assigning services now, or letting delegates decide?"
    [ngClass]="{'selection-area-hidden': !(servicesCheckboxControl?.value)}">
    <mat-radio-button checked
      [value]=false><span class="services-section-title">Choose specific service(s) now</span></mat-radio-button>
    <mat-radio-button [value]=true><span class="services-section-title">Let the user choose from this list</span>
    </mat-radio-button>
  </mat-radio-group>

  <sf-org-select-input #serviceSelector
    class="services-selector"
    [ngClass]="{'selector-hidden': !(servicesCheckboxControl?.value)}"
    [possibleItems]="possibleServices | serviceToOrg"
    [config]="{placeholder: 'Search for services', showSearchIcon: true, removable: true, keepPanelOpen: true}"
    [typeaheadErrorMatcher]="servicesMatcher"
    (blur)="blurHandler($event)"
    [formControl]="serviceListControl">
    <!-- this.validate()'s return value becomes formControl.errors -->
    <mat-error *ngIf="formControl?.errors?.servicesRequired">You must select at least one service</mat-error>
    <mat-error *ngIf="formControl?.errors?.servicesRequiredWithKiosks">You must select at least one service when selecting kiosks</mat-error>
  </sf-org-select-input>

  <h2 id="kiosk-title">Kiosks</h2>
  <mat-slide-toggle #kiosksCheckbox
    [formControl]="kiosksCheckboxControl"
    color="primary"
    class="basic-checkbox mat-body-1 md2-emphasis-md">Are existing kiosks needed for this new role?</mat-slide-toggle>
  <span class="mat-body-1 md2-emphasis-md details-section">
    If you click on Kiosks, Services will automatically be checked.
  </span>

  <mat-radio-group fxLayout="row"
    color="primary"
    [formControl]="kioskDelegationControl"
    class="services-selection-group md2-emphasis-md"
    aria-label="Are you assigning kiosks now, or letting delegates decide?"
    [ngClass]="{'selection-area-hidden': !(kiosksCheckboxControl?.value)}">
    <mat-radio-button checked
      [value]=false><span class="services-section-title">Choose specific kiosks(s) now</span></mat-radio-button>
    <mat-radio-button [value]=true><span class="services-section-title">Let the user choose from this list</span>
    </mat-radio-button>
  </mat-radio-group>

  <sf-org-select-input #kioskSelector
    class="services-selector"
    [ngClass]="{'selector-hidden': !(kiosksCheckboxControl?.value)}"
    [possibleItems]="possibleKiosks | serviceToOrg"
    [config]="{placeholder: 'Search for kiosks', showSearchIcon: true, removable: true, keepPanelOpen: true}"
    [typeaheadErrorMatcher]="kiosksMatcher"
    (blur)="blurHandler($event)"
    [formControl]="kioskListControl">
    <mat-error *ngIf="formControl?.errors?.kiosksRequired">You must select at least one kiosk</mat-error>
  </sf-org-select-input>

  <!--
    helpful for debugging validation
    <pre>{{formControl?.errors | json}}</pre>
  -->

</span>
