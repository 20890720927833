import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { CoreModule, FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatLegacyAutocompleteModule as MatAutocompleteModule } from '@angular/material/legacy-autocomplete';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatLegacyCardModule as MatCardModule } from '@angular/material/legacy-card';
import { MatLegacyCheckboxModule as MatCheckboxModule } from '@angular/material/legacy-checkbox';
import { MatLegacyChipsModule as MatChipsModule } from '@angular/material/legacy-chips';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatLegacyDialogModule as MatDialogModule } from '@angular/material/legacy-dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import { MatLegacyListModule as MatListModule } from '@angular/material/legacy-list';
import { MatLegacyProgressBarModule as MatProgressBarModule } from '@angular/material/legacy-progress-bar';
import { MatLegacyProgressSpinnerModule as MatProgressSpinnerModule } from '@angular/material/legacy-progress-spinner';
import { MatLegacyRadioModule as MatRadioModule } from '@angular/material/legacy-radio';
import { MatLegacySelectModule as MatSelectModule } from '@angular/material/legacy-select';
import { MatLegacySlideToggleModule as MatSlideToggleModule } from '@angular/material/legacy-slide-toggle';
import { MatLegacyTableModule as MatTableModule } from '@angular/material/legacy-table';
import { MatLegacyTabsModule as MatTabsModule } from '@angular/material/legacy-tabs';
import { RouterModule } from '@angular/router';
import { TypeaheadModule as ComponentTypeaheadModule, TypeaheadSearchModule } from '@psu/components/typeahead';
import { ResponsiveModule } from '@psu/utils/responsive';
import { FilterModule } from '@starfish-access/core';
import { AccountAutoCompleteComponent } from './autocomplete/autocomplete.component';
import { DatePickerComponent } from './date-picker/date-picker.component';
import { DelegationFormComponent } from './delegation/delegation-form/delegation-form.component';
import { ClassConfigComponent } from './delegation/role-config-form/class-config/class-config.component';
import { CourseConfigComponent } from './delegation/role-config-form/course-config/course-config.component';
import { RoleConfigFormComponent } from './delegation/role-config-form/role-config-form.component';
import { WarningDialogComponent } from './delegation/role-config-form/warning-dialog/warning-dialog.component';
import { RoleConfigListComponent } from './delegation/role-config-list/role-config-list.component';
import { NavListComponent } from './nav/nav-list.component';
import { NavListService } from './nav/nav-list.service';
import { OrgSelectComponent } from './org-select/org-select.component';
import { RoleIconComponent } from './roleicon/role-icon.component';
import { SimpleServiceSelectorComponent } from './simple-service-selector/simple-service-selector.component';
import { TogglerequestsComponent } from './togglerequests/togglerequests.component';
import { TypeaheadSelectComponent } from './typeahead-select/typeahead-select.component';

@NgModule({
  imports: [
    CommonModule,
    MatAutocompleteModule,
    MatButtonModule,
    MatCardModule,
    MatCheckboxModule,
    MatDialogModule,
    MatIconModule,
    MatInputModule,
    MatListModule,
    MatProgressSpinnerModule,
    MatProgressBarModule,
    MatRadioModule,
    MatButtonToggleModule,
    MatSelectModule,
    MatSlideToggleModule,
    MatTableModule,
    FormsModule,
    ReactiveFormsModule,
    CoreModule,
    FlexLayoutModule,
    FilterModule,
    MatExpansionModule,
    MatDividerModule,
    MatTabsModule,
    MatChipsModule,
    ComponentTypeaheadModule,
    TypeaheadSearchModule,
    MatDatepickerModule,
    ResponsiveModule,
    RouterModule,
  ],
  declarations: [
    RoleIconComponent,
    AccountAutoCompleteComponent,
    OrgSelectComponent,
    TogglerequestsComponent,
    DelegationFormComponent,
    RoleConfigListComponent,
    RoleConfigFormComponent,
    SimpleServiceSelectorComponent,
    TypeaheadSelectComponent,
    CourseConfigComponent,
    ClassConfigComponent,
    DatePickerComponent,
    WarningDialogComponent,
    NavListComponent,
  ],
  exports: [
    RoleIconComponent,
    AccountAutoCompleteComponent,
    OrgSelectComponent,
    TogglerequestsComponent,
    DelegationFormComponent,
    TypeaheadSelectComponent,
    CourseConfigComponent,
    DatePickerComponent,
    NavListComponent,
  ],
  providers: [NavListService],
})
export class StarfishCommonModule {}
