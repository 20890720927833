import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { Component, forwardRef, Input, OnDestroy, OnInit } from '@angular/core';
import {
  AbstractControl,
  ControlValueAccessor,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  UntypedFormBuilder,
  UntypedFormGroup,
  ValidationErrors,
  Validator,
} from '@angular/forms';
import { MatLegacyCheckboxChange as MatCheckboxChange } from '@angular/material/legacy-checkbox';
import { Role, RoleAuthorization, RoleAuthorizationFlow } from '@starfish-access/models';
import { BehaviorSubject, Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'sf-auth-flow',
  templateUrl: './auth-flow.component.html',
  styleUrls: ['./auth-flow.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => AuthFlowComponent),
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => AuthFlowComponent),
      multi: true,
    },
  ],
})
export class AuthFlowComponent implements OnInit, OnDestroy, ControlValueAccessor, Validator {
  @Input() roleName: string;
  umgFormGroup: UntypedFormGroup;
  authSelectionSubj: Subject<boolean> = new BehaviorSubject<boolean>(false);

  approvalOptions: string[] = ['Manager', 'Add Group Name', 'ASR', 'Data Steward', 'DUS'];

  canDelete: string[] = ['Manager', 'Add Group Name', 'ASR', 'Data Steward'];

  canMove: string[] = ['Manager', 'Add Group Name'];

  wasDeleted: string[] = [];

  private destroy$ = new Subject<void>();

  constructor(private fb: UntypedFormBuilder) {}

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  ngOnInit(): void {
    this.umgFormGroup = this.fb.group({
      name: [''],
    });

    const nameCtrl = this.umgFormGroup.get('name');
    if (nameCtrl) {
      nameCtrl.valueChanges
        .pipe(debounceTime(300), distinctUntilChanged(), takeUntil(this.destroy$))
        .subscribe((res) => {
          // console.log('listening to UMG input change: ', res);
          if (this.onChange) {
            this.onChange(this.generateRoleFlow());
          }
        });
    }
  }

  authChecked(event: MatCheckboxChange): void {
    this.authSelectionSubj.next(event.checked);
  }

  drop(dropEvent: CdkDragDrop<string[]>) {
    if (dropEvent.currentIndex < 2) {
      moveItemInArray(this.approvalOptions, dropEvent.previousIndex, dropEvent.currentIndex);
    } else {
      // do nothing
    }
    if (this.onChange) {
      this.onChange(this.generateRoleFlow());
    }
  }

  deleteAuthRole(authStep: string) {
    this.wasDeleted.push(authStep);
    if (this.onChange) {
      this.onChange(this.generateRoleFlow());
    }
  }

  markAsDeleted(authStep: string, enabled: boolean) {
    if (!enabled) {
      this.wasDeleted.push(authStep);
    }
  }

  resetAuthList(resetCheckbox: boolean): void {
    this.authSelectionSubj.next(resetCheckbox);

    const len = this.wasDeleted.length;
    for (let i = 0; i < len; i++) {
      this.wasDeleted.pop();
    }

    this.approvalOptions = ['Manager', 'Add Group Name', 'ASR', 'Data Steward', 'DUS'];

    if (this.onChange) {
      this.onChange(this.generateRoleFlow());
    }
  }

  generateRoleFlow(): RoleAuthorizationFlow {
    const nameCtrl = this.umgFormGroup.get('name');
    const flow: RoleAuthorizationFlow = {
      asrEnabled: this.wasDeleted.indexOf('ASR') === -1,
      dataStewardEnabled: this.wasDeleted.indexOf('Data Steward') === -1,
      managerEnabled: this.wasDeleted.indexOf('Manager') === -1,
      managerFirst: this.approvalOptions.indexOf('Manager') === 0,
      specialGroupEnabled: this.wasDeleted.indexOf('Add Group Name') === -1,
      specialGroupName: nameCtrl ? nameCtrl.value.trim() : '',
    };
    return flow;
  }

  // custom form control functions
  validate(control: AbstractControl): ValidationErrors | null {
    return null;
  }
  registerOnValidatorChange(fn: () => void): void {
    this.onValidatorChange = fn;
  }
  writeValue(role: Role): void {
    // called when loading existing data from ctrl.patchValue
    if (!role) {
      return;
    }
    const obj: RoleAuthorization = role.authFlow;
    const defaultAuthPath: boolean = this.isDefault(role);
    this.authSelectionSubj.next(!defaultAuthPath);

    if (obj) {
      this.markAsDeleted('ASR', obj.asrEnabled || false);
      this.markAsDeleted('Data Steward', obj.dataStewardEnabled || false);
      this.markAsDeleted('Manager', obj.managerEnabled || false);
      if (!obj?.managerFirst && obj.specialGroupEnabled) {
        moveItemInArray(this.approvalOptions, 0, 1);
      }
      this.markAsDeleted('Add Group Name', obj.specialGroupEnabled || false);
    }
    const nameCtrlGroup: any = this.umgFormGroup.get('name');
    if (obj?.specialGroupEnabled && nameCtrlGroup) {
      nameCtrlGroup.patchValue(obj?.specialGroupName);
    }
    if (this.onChange) {
      this.onChange(this.generateRoleFlow());
    }
  }
  registerOnChange(fn: any): void {
    this.onChange = fn;
  }
  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  isDefault(clientRole: Role): any {
    const roleAuthFLow: RoleAuthorization = clientRole.authFlow;

    // we need to specifically check for undefined, this is a 3 state boolean
    if (!roleAuthFLow || roleAuthFLow.managerEnabled === undefined || roleAuthFLow.managerEnabled === null) {
      // managerEnabled null value indicates that this was never customized for any field.
      return true;
    }

    const isDefault =
      roleAuthFLow.managerEnabled &&
      roleAuthFLow.managerFirst &&
      !roleAuthFLow.specialGroupEnabled &&
      roleAuthFLow.asrEnabled &&
      roleAuthFLow.dataStewardEnabled;

    return isDefault;
  }

  private onChange: (_: any) => any = (_: any) => ({});
  private onTouched: (_: any) => any = (_: any) => ({});
  private onValidatorChange: (_: any) => any = (_: any) => ({});
}
