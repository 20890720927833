<form class="view-delete-summary"
  fxLayout="column">
  <h2 class="mat-h2">{{userList.length}} Students to be Deleted</h2> (View only screen)

  <sf-account-search #searchInput
    placeHolderText="Filter Access IDs"
    (onSearch)='filterSubject$.next($event)'></sf-account-search>

  <p class="warning-text"
    data-cy="no-search-results"
    *ngIf="(filteredChips$ | async)?.length === 0">There are no records that match that access id. Please clear the search bar to see all student IDs or search again.</p>

  <mat-chip-list>
    <mat-chip *ngFor="let thing of (filteredChips$ | async | sortDisplayName)"
      [removable]="'false'"
      class="chip-padding"
      [attr.data-cy]="'chip-' + thing.displayName">
      {{thing.id}}
    </mat-chip>
  </mat-chip-list>

</form>
