import { Component, OnDestroy, ViewChild } from '@angular/core';
import { MatLegacyPaginator as MatPaginator } from '@angular/material/legacy-paginator';
import { MatSort } from '@angular/material/sort';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { ConfirmDialogService, DialogService, TOOLTIP_DELAY } from '@starfish-access/core';
import { KioskSvc } from '@starfish-access/models';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { KioskSvcFacade } from './kiosk-services.facade';

@Component({
  templateUrl: './kiosk-services.component.html',
  styleUrls: ['./kiosk-services.component.scss'],
})
export class KioskSvcComponent implements OnDestroy {
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  displayedColumns: string[] = ['name', 'id', 'delete'];
  dataSource: MatTableDataSource<KioskSvc> = new MatTableDataSource();

  tooltipDelay: string = TOOLTIP_DELAY;
  canView: Observable<boolean>;
  canAdd: Observable<boolean>;
  canEdit: Observable<boolean>;
  canDelete: Observable<boolean>;
  isLoadingData: Observable<boolean>;
  hasFailed: Observable<boolean>;
  private deletedService: string[] = [];
  private destroy$ = new Subject<void>();

  constructor(
    public facade: KioskSvcFacade,
    public confirmDialogService: ConfirmDialogService,
    public dialogService: DialogService
  ) {
    this.facade
      .serviceList()
      .pipe(takeUntil(this.destroy$))
      .subscribe((res) => {
        this.dataSource.data = res;
        // set timeout because we are in an ngIf. If the ngIf goes away this can move to ngafterviewinit
        setTimeout(() => {
          this.dataSource.paginator = this.paginator;
          this.dataSource.paginator.pageSizeOptions = [10, 25, 50, res.length];
          this.dataSource.paginator.pageSize = 10;
          this.dataSource.sort = this.sort;
        });
      });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  applyFilter(filterValue: string): void {
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  isActivelyDeleting(serviceId: string): boolean {
    for (const id of this.deletedService) {
      if (id === serviceId) {
        return true;
      }
    }
    return false;
  }
}
