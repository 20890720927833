<div fxLayout="column">
  <h3 class="body-bold">Add New User</h3>

  <ta-typeahead
    data-cy="add-new-user-to-sf-users-ta"
    [config]="config"
    [floatLabel]="'never'"
    [placeholder]="'Start typing to search. Results will appear after three characters.'"
    [resultTemplate]="resultTemplate"
    (selected)="addNewUser($event)"
    [noResultsTemplate]="noResultsTemplate"
    [autoActiveFirstOption]="false"
    [clearButtonIconTemplate]="clearButtonIconTemplate"
    [showSearchButton]="true"
    [showMatPrefix]="false"
    #ta1>
  </ta-typeahead>

  <sf-add-role
    #sfAddRoleComp
    data-cy="new-user-role"
    [existingUserRoles]="existingRoles | async"
    [disableSaveAction]="selectedUser === undefined"
    (closeAction)="dialogRef.close()"
    (saveAction)="saveNewUser(dialogRef)"
    [allRoles]="data?.adminRoleGroups | async"
    fxFlex></sf-add-role>

  <div
    *ngIf="existingRoles && (existingRoles | async)?.roles?.length > 0"
    class="assigned-roles-list">
    <p class="md2-emphasis-md body-bold">Assigned Roles:</p>
    <mat-chip-list>
      <mat-chip *ngFor="let roleDetails of (existingRoles | async)?.roles">{{ roleDetails.roleName }}</mat-chip>
    </mat-chip-list>
  </div>
  <!-- This template is for the display of results in autocomplete overlay -->
  <ng-template
    #resultTemplate
    let-result>
    <span
      fxLayout="row"
      fxLayoutAlign="start center">
      <span
        fxLayout="row"
        class="result-content"
        fxFlex>
        <span>{{ result.displayName }} ({{ result.userid }})</span>
      </span>
    </span>
  </ng-template>
  <ng-template #noResultsTemplate> No Results Found </ng-template>
  <ng-template #clearButtonIconTemplate>
    <mat-icon (click)="clearUser()">clear</mat-icon>
  </ng-template>
</div>
