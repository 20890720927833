<footer utResponsive
  class="psu-mat-footer mat-typography">
  <!-- lt- media queries don't currently work. https://github.com/angular/flex-layout/issues/249 -->
  <div fxHide
    fxShow.gt-sm="true"
    fxLayout="row"
    fxLayoutAlign="start center"
    class="mat-caption">
    <div fxFlex
      *ngIf="logoLarge">
      <a [attr.href]="footerNavigation.psuLink"
        title="Penn State University home page"
        target="_BLANK"
        rel="noopener">
        <img class="footer-logo"
          [src]="logoLarge"
          alt="Penn State University home page">
      </a>
    </div>

    <div class="list"
      fxLayout="row"
      fxLayoutAlign="center center">
      <a [attr.href]="footerNavigation.serviceDeskLink"
        target="_BLANK"
        rel="noopener"
        aria-label="Contact the IT service desk">Contact</a>
      <a [attr.href]="footerNavigation.legalLink"
        target="_BLANK"
        rel="noopener"
        aria-label="Privacy information">Privacy</a>
      <a [attr.href]="footerNavigation.accessibilityLink"
        target="_BLANK"
        rel="noopener"
        aria-label="Accessibility information">Accessibility</a>
    </div>

    <div fxFlex
      fxLayout="row"
      fxLayoutAlign="end center">
      <a [attr.href]="footerNavigation.copyrightLink"
        target="_BLANK"
        rel="noopener"
        aria-label="Copyright The Pennsylvania State University">
        The Pennsylvania State University © {{year}}
      </a>
    </div>
  </div>

  <div fxShow
    fxHide.gt-sm="true"
    fxLayout="column"
    fxLayoutAlign="space-between center"
    class="mat-caption">
    <div fxLayout="row wrap"
      fxLayoutAlign="center center"
      class="list mobile">
      <a [attr.href]="footerNavigation.serviceDeskLink"
        target="_BLANK"
        rel="noopener"
        aria-label="Contact the IT service desk">Contact</a>
      <a [attr.href]="footerNavigation.legalLink"
        target="_BLANK"
        rel="noopener"
        aria-label="Privacy information">Privacy</a>
      <a [attr.href]="footerNavigation.accessibilityLink"
        target="_BLANK"
        rel="noopener"
        aria-label="Accessibility information">Accessibility</a>
      <a [attr.href]="footerNavigation.copyrightLink"
        target="_BLANK"
        rel="noopener"
        aria-label="Copyright The Pennsylvania State University">
        The Pennsylvania State University © {{year}}
      </a>
    </div>

    <div fxFlex="auto">&nbsp;</div>

    <a *ngIf="logoMobile"
      [attr.href]="footerNavigation.psuLink"
      title="Penn State University home page"
      target="_BLANK"
      rel="noopener">
      <img class="footer-logo"
        mat-list-avatar
        [src]="logoMobile"
        alt="Penn State University home page">
    </a>
  </div>
</footer>
