<div fxLayout="row">
  <h1 *ngIf="!isViewingAllSubmissions">{{primaryTitle}}</h1>
  <h1 *ngIf="isViewingAllSubmissions">{{secondaryTitle}}</h1>
  <div fxFlex></div>
  <mat-button-toggle-group *ngIf="canViewAll || isUserSteward"
    #toggleGroup
    data-cy='toggle-requests'
    [value]='selectedValue'
    (change)="viewEmitter.emit()">
    <mat-button-toggle [value]='VIEW_MY'>
      <mat-icon class="toggle-text"
        data-cy='my-requests-icon'
        *ngIf="!isViewingAllSubmissions">check_circle_outline</mat-icon>
      <span class=" md2-subtitle-2 title-bold all-caps"
        [ngClass]="(!isViewingAllSubmissions) ? 'toggle-text' : 'md2-emphasis-md'">{{primaryButton}}</span>
    </mat-button-toggle>
    <mat-button-toggle data-cy='all-button-toggle'
      [value]='VIEW_ALL'>
      <mat-icon class="toggle-text"
        data-cy='all-requests-icon'
        *ngIf="isViewingAllSubmissions">check_circle_outline</mat-icon>
      <span class="md2-subtitle-2 title-bold all-caps"
        [ngClass]="(isViewingAllSubmissions) ? 'toggle-text' : 'md2-emphasis-md'">{{secondaryButton}}</span>
    </mat-button-toggle>
  </mat-button-toggle-group>
</div>
