import { Component, EventEmitter, Inject, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { User as TypeAheadUser } from '@starfish-access/shared';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { StarfishConfig, STARFISH_CONFIG } from '../../../starfish.config';
import { User } from '../roles.model';

@Component({
  templateUrl: './user-search.component.html',
  styleUrls: ['./user-search.component.scss'],
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'sf-user-search',
})
export class UserSearchComponent implements OnInit, OnDestroy {
  @Input() canAdd: Observable<boolean>;
  @Input() users: Observable<User[]>;
  @Input() userType: string;
  @Output() addNewUserToSource: EventEmitter<User> = new EventEmitter();
  title = 'Typeahead Component Demo';
  searchResults: TypeAheadUser[];
  config: any;
  private userIsDeleting: string[] = [];
  private destroy$ = new Subject<void>();
  private usersSubscribed: User[];

  constructor(@Inject(STARFISH_CONFIG) private starfishConfig: StarfishConfig, public snackBar: MatSnackBar) {
    const searchServiceLink = this.starfishConfig.searchService;
    this.config = {
      url: searchServiceLink,
      ssnSearchUrl: '',
      maxResults: 100,
      resultHeight: '48',
    };
  }

  addNewUser(user: TypeAheadUser) {
    if (!user || user.userid.length < 1) {
      return;
    }
    const accessId = user.userid;

    const newUser: User = {
      userId: accessId,
    };

    if (this.checkAddedUsersToDisplay(accessId)) {
      this.snackBar.open('Access ID ' + accessId + ' has already been added!', 'OK', { duration: 3000 });
    } else {
      this.addNewUserToSource.emit(newUser);
    }

    let index = -1;
    for (const id of this.userIsDeleting) {
      if (id === newUser.userId) {
        index = this.userIsDeleting.indexOf(id);
      }
    }

    if (index > -1) {
      this.userIsDeleting.splice(index, 1);
    }
  }

  checkAddedUsersToDisplay(accessId: string): boolean {
    if (!this.usersSubscribed) {
      return false;
    }

    let existing = false;
    Array.from(this.usersSubscribed).forEach((user) => {
      if (user.userId === accessId) {
        existing = true;
      }
    });
    return existing;
  }

  isUserDeleting(accessId: string): boolean {
    const index = this.userIsDeleting.indexOf(accessId);
    return index > -1;
  }

  ngOnInit(): void {
    this.users.pipe(takeUntil(this.destroy$)).subscribe((res) => (this.usersSubscribed = res));
  }
  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
