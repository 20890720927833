<div class="mat-typography">
  <h3>{{statusMessageMapping?.get(action)?.dialogTitle}}</h3>
  <div fxLayout="column">
    <p>{{statusMessageMapping?.get(action)?.dialogMsg}}</p>
    <mat-form-field fxFlex>
      <mat-label>Comment (optional)</mat-label>
      <textarea matInput
        data-cy="text-area-input"
        cdkTextareaAutosize
        [(ngModel)]="comment"
        #autosize="cdkTextareaAutosize"
        cdkAutosizeMinRows="1"
        cdkAutosizeMaxRows="5"></textarea>
    </mat-form-field>
  </div>
  <div fxLayoutAlign="end center"
    mat-dialog-actions>
    <button type="button"
      data-cy='text-area-cancel'
      mat-button
      color=warn
      (click)="dialogRef.close()">CANCEL</button>
    <button type="button"
      data-cy='text-area-confirm'
      mat-button
      color=primary
      (click)="dialogRef.close({comment: comment})">CONFIRM</button>
  </div>
</div>
