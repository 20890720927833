<form [formGroup]="addRoleFormGroup"
  fxLayout="column"
  class="add-role-component">
  <span class="body-bold">Add a role for this user</span>
  <mat-form-field fxFlex
    class="role-comp-form-field"
    appearance="outline">
    <mat-label>Add a new role</mat-label>
    <mat-select formControlName="selectedRole"
      [attr.data-cy]="userToEvaluate + '-role-dropdown-select'"
      class="role-select">
      <mat-option>-- None --</mat-option>
      <mat-optgroup *ngFor="let group of allRoles"
        [label]="group.name">
        <mat-option *ngFor="let role of (group.roles | pruneRolesList: existingUserRoles)"
          [value]="role"
          fxLayout="row">
          <mat-icon [ngClass]="group.iconColor+'-foreground'"
            [svgIcon]="group.iconImage"></mat-icon>
          <span class="role-name"
            [attr.data-cy]="role.roleName + '-dropdown'"
            fxFlex>{{role.roleName}}</span>
          <mat-icon *ngIf='role.relationships.organizations?.length > 0'
            alt="you must select an org for this role"
            matTooltip="You must select an org for this role."
            svgIcon="alert-outline"
            color="primary"></mat-icon>
        </mat-option>
      </mat-optgroup>

    </mat-select>
  </mat-form-field>

  <div fxLayout="column"
    *ngIf="addRoleFormGroup.get('selectedRole')?.value?.relationships?.organizations?.length > 0 && addRoleFormGroup.get('selectedRole')?.value?.relationships.orgSelectionType !== 'MULTIPLE'">
    <div fxLayout="row"
      data-cy="organization-selection-display-single">
      <span class="body-bold">You must choose an organization</span>
      <mat-icon class="org-alert-icon"
        svgIcon="alert-outline"
        color="primary"></mat-icon>
    </div>
    <mat-form-field appearance="outline">
      <mat-label>Organizations</mat-label>
      <mat-select formControlName="singleOrg"
        [attr.data-cy]="userToEvaluate + '-org-dropdown-select'">
        <mat-option *ngFor="let org of addRoleFormGroup?.get('selectedRole')?.value?.relationships?.organizations"
          [value]='org'>{{org.name}}</mat-option>
      </mat-select>
      <mat-error *ngIf="!addRoleFormGroup?.get('singleOrg')?.valid"
        class="checkbox-error"
        data-cy="single-org-select-error">You must select an organization</mat-error>
    </mat-form-field>
  </div>

  <div fxLayout="column"
    *ngIf="addRoleFormGroup.get('selectedRole')?.value?.relationships?.organizations?.length > 0 && addRoleFormGroup.get('selectedRole')?.value?.relationships.orgSelectionType === 'MULTIPLE'">
    <div fxLayout="row"
      data-cy="organization-selection-display-multiple">
      <span class="body-bold">You must choose an organization</span>
      <mat-icon class="org-alert-icon"
        svgIcon="alert-outline"
        color="primary"></mat-icon>
    </div>
    <mat-form-field appearance="outline">
      <mat-label>Organizations</mat-label>
      <mat-select formControlName="multiOrg"
        [attr.data-cy]="userToEvaluate + '-multi-org-dropdown-select'"
        multiple>
        <mat-option *ngFor="let org of addRoleFormGroup?.get('selectedRole')?.value?.relationships.organizations"
          [value]='org'>{{org.name}}</mat-option>
      </mat-select>
      <mat-error *ngIf="!addRoleFormGroup?.get('multiOrg')?.valid"
        class="checkbox-error"
        data-cy="multi-org-select-error">You must select an organization</mat-error>
    </mat-form-field>
  </div>
  <div fxLayoutAlign="end center"
    fxLayoutGap="24px">
    <button mat-button
      [attr.data-cy]="userToEvaluate + 'cancel-user-role-btn'"
      color="primary"
      (click)="closeAction.emit()">Cancel</button>
    <button mat-flat-button
      [attr.data-cy]="userToEvaluate + 'save-user-role-btn'"
      color="primary"
      (click)="saveAction.emit($event)"
      [disabled]="disableSaveAction">Save</button>
  </div>
</form>
