import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatLegacyAutocompleteModule as MatAutocompleteModule } from '@angular/material/legacy-autocomplete';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatLegacyFormFieldModule as MatFormFieldModule } from '@angular/material/legacy-form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import { MatLegacyProgressSpinnerModule as MatProgressSpinnerModule } from '@angular/material/legacy-progress-spinner';
import { MatLegacyTooltipModule as MatTooltipModule } from '@angular/material/legacy-tooltip';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { NgLetModule } from '@psu/utils/angular';
import { TypeaheadComponent } from './typeahead.component';
import { TypeaheadEffects } from './typeahead.effects';
import { TypeaheadFacade } from './typeahead.facade';
import { reducer, TYPEAHEAD_FEATURE_KEY } from './typeahead.reducer';
import { TypeaheadService } from './typeahead.service';

@NgModule({
  imports: [
    CommonModule,
    FlexLayoutModule,
    MatIconModule,
    MatFormFieldModule,
    MatTooltipModule,
    MatAutocompleteModule,
    MatInputModule,
    MatButtonModule,
    MatProgressSpinnerModule,
    FormsModule,
    ReactiveFormsModule,
    NgLetModule,
    StoreModule.forFeature(TYPEAHEAD_FEATURE_KEY, reducer),
    EffectsModule.forFeature([TypeaheadEffects]),
  ],
  declarations: [TypeaheadComponent],
  exports: [TypeaheadComponent],
  providers: [TypeaheadEffects, TypeaheadFacade, TypeaheadService],
})
export class TypeaheadModule {}
