import {
  AbstractControl,
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  ValidationErrors,
  Validators,
} from '@angular/forms';
import { update } from 'ramda';
import { Observable, of } from 'rxjs';
import { catchError, debounceTime, distinctUntilChanged, map, startWith, take, tap } from 'rxjs/operators';
import { RelationshipType } from '../model/relationships.model';
import { ClientRoleGroup, ClientRoleGroupAndPriority, Role, RoleGroupPriority } from '../model/roles.model';
import {
  RoleGroupUpdateInfo,
  ServerRelationshipType,
  ServerRole,
  ServerRoleGroup,
  ServerRoleGroupAndPriority,
} from '../model/server/server-roles.model';

export type GroupType = 'NEW' | 'EXISTING';

export const generateRoleForm = (
  fb: UntypedFormBuilder,
  roleGroups: ClientRoleGroup[],
  roleName?: string
): UntypedFormGroup => {
  const formGroup: UntypedFormGroup = fb.group({
    id: [],
    roleName: ['', [Validators.required], validatorWrapper(roleGroups, roleName)],
    existingGroup: ['', [Validators.required]],
    newGroup: [''],
    descriptions: new UntypedFormControl([]),
    orgList: [],
    multiOrg: false,
    services: false,
    kiosks: false,
    servicesAndKiosks: new UntypedFormControl({
      serviceList: [],
      serviceDelegation: false,
      kioskList: [],
      kioskDelegation: false,
    }),
    relationships: [],
    roleControl: ['USER_REQUESTABLE'],
    authFlow: {
      managerEnabled: true,
      managerFirst: true,
      specialGroupEnabled: false,
      specialGroupName: '',
      asrEnabled: true,
      dataStewardEnabled: true,
    },
  });

  return formGroup;
};

const validatorWrapper = (
  roleGroups: ClientRoleGroup[],
  roleName?: string
): ((abstractControl: AbstractControl) => Observable<ValidationErrors | null>) => {
  let f = true;
  return (control: AbstractControl) => {
    if (f) {
      f = false;
      control.setErrors(null);
      control.updateValueAndValidity();
      return of(null);
    }
    return control.valueChanges.pipe(
      debounceTime(100),
      distinctUntilChanged(),
      startWith(control.value),
      take(1),
      // withLatestFrom(this.roleGroups),
      map((editedRoleName) => {
        for (const group of roleGroups) {
          for (const role of group.roles) {
            if (editedRoleName.length < 1) {
              return { required: true } as ValidationErrors;
            }
            if (role.roleName === editedRoleName && role.roleName !== roleName) {
              return { exists: true } as ValidationErrors;
            }
          }
        }
        return null;
      }),
      tap((vr: ValidationErrors) => {
        control.setErrors(vr);
      }),
      catchError((e) => of(null))
    );
  };
};

export const addToRoleGroup = (
  clientSideRoleGroupAndPriority: ClientRoleGroupAndPriority
): ServerRoleGroupAndPriority => {
  const clientSideRole: Role = clientSideRoleGroupAndPriority.clientSideRole;
  const groupType: GroupType = clientSideRoleGroupAndPriority.roleGroupType;
  const originalRoleGroup: ClientRoleGroup | undefined = clientSideRoleGroupAndPriority.originalRoleGroup;

  const roleToUpdate: ServerRole = toServerRole(clientSideRole);

  let rg: ServerRoleGroup | undefined;
  let priorityList: RoleGroupPriority[] = [];

  if (clientSideRole.existingGroup) {
    rg = toServerRoleGroup(clientSideRole.existingGroup);
  }
  if (groupType === 'NEW' && clientSideRole.newGroup) {
    rg = {
      description: clientSideRole.newGroup.description ? clientSideRole.newGroup.description : '',
      iconColor: clientSideRole.newGroup.color,
      name: clientSideRole.newGroup.name,
      iconImage: clientSideRole.newGroup.icon,
      roles: [],
    };
    priorityList = clientSideRole.newGroup.priorityList;
  }

  if (!rg) {
    const empty: ServerRoleGroup = {
      name: '',
      description: '',
      iconColor: '',
      iconImage: '',
      roles: [],
    };
    return {
      roleGroup: empty,
      roleGroupInfo: {
        destinationRoleGroup: empty,
        originalRoleGroup: empty,
        updatedRole: roleToUpdate,
      },
      roleGroupPriority: [],
    };
  }

  let updatedRoleList: ServerRole[] = rg.roles ? rg.roles : [];

  let indexToUpdate = -1;
  let counter = 0;
  for (const single of updatedRoleList) {
    if (single.id === roleToUpdate.id) {
      indexToUpdate = counter;
      break;
    }
    counter++;
  }

  if (indexToUpdate === -1) {
    // brand new role
    updatedRoleList.push(roleToUpdate);
  } else {
    // updating existing role
    updatedRoleList = update(indexToUpdate, roleToUpdate, rg.roles);
  }

  const roleGroupToUpdate: ServerRoleGroup = {
    id: rg.id,
    name: rg.name,
    description: rg.description,
    roles: updatedRoleList,
    iconColor: rg.iconColor,
    iconImage: rg.iconImage,
    customUmgGroupName: clientSideRole.authFlow.specialGroupName,
    customUmgGroupEnabled: clientSideRole.authFlow.specialGroupEnabled,
  };

  const rgInfo: RoleGroupUpdateInfo = {
    updatedRole: roleToUpdate,
    destinationRoleGroup: roleGroupToUpdate,
    originalRoleGroup: originalRoleGroup ? originalRoleGroup : roleGroupToUpdate,
    customUmgGroupName: clientSideRole.authFlow.specialGroupName,
    customUmgGroupEnabled: clientSideRole.authFlow.specialGroupEnabled,
  };

  const groupAndPriority: ServerRoleGroupAndPriority = {
    roleGroup: rg,
    roleGroupInfo: rgInfo,
    roleGroupPriority: clientSideRole.newGroup ? clientSideRole.newGroup.priorityList : [],
  };

  return groupAndPriority;
};

const toServerRoleGroup = (crg: ClientRoleGroup): ServerRoleGroup => {
  const singleRoleList: ServerRole[] = crg.roles.map(toServerRole);
  return {
    id: crg.id,
    description: crg.description,
    iconColor: crg.iconColor,
    iconImage: crg.iconImage,
    name: crg.name,
    roles: singleRoleList,
    customUmgGroupEnabled: crg.customUmgGroupEnabled,
    customUmgGroupName: crg.customUmgGroupName,
  };
};

export const toServerRole = (role: Role): ServerRole => ({
  id: role.id,
  name: role.roleName,
  descriptions: role.descriptions,
  courseConfiguration: {
    courseType: 'DEFER_COURSES',
    deferOptions: {
      deferCampusSelection: true,
      deferTrackSelection: true,
      deferSemesterSelection: true,
      deferCourseSelection: true,
    },
    courses: [
      {
        campus: 'ALL',
        termName: 'ALL',
        track: 'ALL',
        department: 'ALL',
        courseNumber: 'ALL',
      },
    ],
  },
  organizationConfiguration: {
    allowMultipleOrganizations: role.relationships ? role.relationships.orgSelectionType === 'MULTIPLE' : false,
    deferOrganization: role.relationships?.deferOrganization ? role.relationships.deferOrganization : false,
    organizations: role.relationships ? (role.relationships.organizations as any[]) : [],
  },
  kioskConfiguration: {
    displayKiosk:
      role.servicesAndKiosks && role.servicesAndKiosks.kioskList ? role.servicesAndKiosks.kioskList.length > 0 : false,
    deferKioskSelection: role.servicesAndKiosks.kioskDelegation,
    kiosks: role.servicesAndKiosks.kioskList,
  },
  serviceConfiguration: {
    displayService:
      role.servicesAndKiosks && role.servicesAndKiosks.serviceList
        ? role.servicesAndKiosks.serviceList.length > 0
        : false,
    deferServiceSelection: role.servicesAndKiosks.serviceDelegation,
    services: role.servicesAndKiosks.serviceList,
  },
  approvalPathConfiguration: {
    managerEnabled: role.authFlow ? role.authFlow.managerEnabled : undefined,
    managerFirst: role.authFlow ? role.authFlow.managerFirst : undefined,
    // The specialGroupEnabled and specialGroupName validate together on the endpoint.
    // specialGroupEnabled flag can only be set to true if the specialGroupName has been set (so length > 0)
    // otherwise, it should remain undefined.
    specialGroupEnabled:
      role.authFlow && role.authFlow?.specialGroupName?.length > 0 ? role.authFlow.specialGroupEnabled : undefined,
    specialGroupName: role.authFlow ? role.authFlow.specialGroupName : undefined,
    asrEnabled: role.authFlow ? role.authFlow.asrEnabled : undefined,
    dataStewardEnabled: role.authFlow ? role.authFlow.dataStewardEnabled : undefined,
  },
  assignmentType: role.roleControl ? role.roleControl : 'USER_REQUESTABLE',
  relationshipType: toServerRelationshipType(role.relationships?.relationshipType),
});

export const toServerRelationshipType = (rt: RelationshipType | undefined | null): ServerRelationshipType => {
  if (!rt) {
    return 'ALL_STUDENTS';
  } else if (rt === 'ORGANIZATION') {
    return 'ORGANIZATION';
  }
  return rt;
};
