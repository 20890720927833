<div id="add-user-role-comp"
  [formGroup]="calMgrUsrForm"
  fxLayout="column">

  <h2 class="mat-h2">Add Calendar Manager(s)</h2>
  <sf-typeahead-select [config]="calMgrConfig"
    formControlName="users">
  </sf-typeahead-select>

  <div class="end-align-flex"
    fxLayoutGap="24px">
    <button mat-stroked-button
      data-cy='cancel-add-calendar-mgr-btn'
      (click)="cancel()"
      color="primary">Cancel</button>
    <button mat-flat-button
      (click)="complete()"
      data-cy='complete-add-calendar-mgr-btn'
      color="primary">Add Users</button>
  </div>
</div>
