import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatLegacyCardModule as MatCardModule } from '@angular/material/legacy-card';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyListModule as MatListModule } from '@angular/material/legacy-list';
import { MatLegacyProgressBarModule as MatProgressBarModule } from '@angular/material/legacy-progress-bar';
import { MatLegacyProgressSpinnerModule as MatProgressSpinnerModule } from '@angular/material/legacy-progress-spinner';
import { MatLegacyTableModule as MatTableModule } from '@angular/material/legacy-table';
import { MatLegacyTooltipModule as MatTooltipModule } from '@angular/material/legacy-tooltip';
import { NotificationModule } from '@psu/components/notification-ngrx';
import { FilterModule } from '@starfish-access/core';
import { WorkflowIconModule } from '../workflowicon/workflow-icon.module';
import { HeaderDisplayComponent } from './header-display.component';

@NgModule({
  imports: [
    CommonModule,
    MatButtonModule,
    MatCardModule,
    MatIconModule,
    MatListModule,
    MatProgressBarModule,
    MatTooltipModule,
    FormsModule,
    ReactiveFormsModule,
    NotificationModule,
    MatTableModule,
    FlexLayoutModule,
    FilterModule,
    MatProgressSpinnerModule,
    WorkflowIconModule,
  ],
  declarations: [HeaderDisplayComponent],
  exports: [HeaderDisplayComponent],
  providers: [],
})
export class WorkflowHeaderDisplayModule {}
