<div class="edit-role-container-class">
  <div fxLayout="row">
    <h1 fxFlex>Edit Roles</h1>
    <button class="create-role-btn"
      data-cy='create-new-role-btn'
      mat-flat-button
      color="primary"
      routerLink="../createrole">Create New Role</button>
  </div>
  <div *ngIf="(roleGroupsIsFetching | async)">
    <mat-progress-bar mode="indeterminate"></mat-progress-bar>
  </div>
  <div *ngIf="!(roleGroupsIsFetching | async)">

    <sf-account-search (onSearch)='filterSubject.next($event)'></sf-account-search>

    <div *ngFor="let roleGroup of (facade.roleGroupsWithAdminGroups$ | async)">
      <h2 class="groups-h2 md2-emphasis-md">{{roleGroup.name}}</h2>
      <mat-accordion>
        <mat-expansion-panel hideToggle
          *ngFor="let role of (roleGroup.roles | filterRole: (filterSubject | async))"
          class="mat-elevation-z0 exp-panel-class">
          <mat-expansion-panel-header class="exp-panel-header"
            [attr.data-cy]="role.roleName+'-editable-item'">
            <mat-panel-title fxLayoutAlign="start center"
              fxLayoutGap="8px">
              <sf-role-icon [roleName]="role.roleName"
                [iconName]="roleGroup.iconImage"
                [iconColor]="roleGroup.iconColor"></sf-role-icon>

              <div fxLayout="column"
                class="role-name-div">
                <div fxLayout="row"
                  fxLayoutGap="12px">
                  <span class="mat-subtitle-1">{{role.roleName}} </span>
                  <mat-icon *ngIf="role.roleControl === 'DELEGATION_ONLY'"
                    #templateVariable="matTooltip"
                    (mouseenter)="setToolTipMsg(templateVariable, role.id)"
                    matTooltip="Loading..."
                    aria-label="info about delegations that this role is a part of"
                    matTooltipClass="target-tooltip"
                    svgIcon="information-outline"></mat-icon>
                </div>
                <span class="md2-emphasis-md mat-body-2">{{(role.roleControl | initialCaps).replace('Only', '')}}</span>
              </div>

            </mat-panel-title>
            <mat-panel-description>
              <span>
                <!--for flex spacing-->
              </span>
              <mat-icon svgIcon="square-edit-outline"></mat-icon>
            </mat-panel-description>
          </mat-expansion-panel-header>

          <ng-template matExpansionPanelContent>
            <div fxLayout="column">
              <form [formGroup]="createFormGroup(role.roleName)">
                <sf-role-details-form #roleDetailsFormComponent
                  [roleToEdit]="role"
                  [containingRoleGroup]="roleGroup"
                  [newRoleForm]="groupMap.get(role.roleName)"
                  [roleGroups$]="facade.roleGroupsWithAdminGroups$ | async"
                  [includeNewGroup]="false">
                </sf-role-details-form>
              </form>
              <div class="role-buttons"
                fxLayoutAlign="end center"
                fxLayoutGap="16px">
                <button *ngIf="!(facade.isUpdating$ | async)"
                  mat-stroked-button
                  color="primary"
                  (click)="clearEntries(role.roleName)">Reset</button>
                <button *ngIf="!(facade.isUpdating$ | async)"
                  mat-flat-button
                  color="primary"
                  (click)="editExistingRole(groupMap?.get(role.roleName), roleGroup, role.id)"
                  [attr.data-cy]="role.roleName+'-update-item'">Update</button>
                <mat-progress-bar *ngIf="(facade.isUpdating$ | async)"
                  data-cy="save-role-progress-bar"
                  mode="indeterminate"></mat-progress-bar>
              </div>
            </div>
          </ng-template>
        </mat-expansion-panel>
      </mat-accordion>
    </div>

  </div>
</div>
