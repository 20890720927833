import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatLegacyCardModule as MatCardModule } from '@angular/material/legacy-card';
import { MatLegacyFormFieldModule as MatFormFieldModule } from '@angular/material/legacy-form-field';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import { MatLegacyPaginatorModule as MatPaginatorModule } from '@angular/material/legacy-paginator';
import { MatLegacyProgressBarModule as MatProgressBarModule } from '@angular/material/legacy-progress-bar';
import { MatSortModule } from '@angular/material/sort';
import { MatLegacyTableModule as MatTableModule } from '@angular/material/legacy-table';
import { MatLegacyTooltipModule as MatTooltipModule } from '@angular/material/legacy-tooltip';
import { RouterModule } from '@angular/router';
import { FilterModule, TaskService } from '@starfish-access/core';
import { StarfishCommonModule } from '../../shared/common.module';
import { TaskListComponent } from './tasklist.component';
import { TaskListFacade } from './tasklist.facade.service';
import { routes } from './tasklist.routing';

@NgModule({
  imports: [
    CommonModule,
    StarfishCommonModule,
    MatProgressBarModule,
    MatTooltipModule,
    MatCardModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule.forChild(routes),
    MatTableModule,
    MatPaginatorModule,
    MatSortModule,
    FlexLayoutModule,
    FilterModule,
    MatFormFieldModule,
    MatInputModule,
  ],
  declarations: [TaskListComponent],
  exports: [RouterModule],
  providers: [TaskService, TaskListFacade],
})
export class TaskListModule {}
