<mat-card class="task-approval-component">

  <div fxLayout="row">
    <button mat-fab
      color="primary"
      aria-label="Example icon button with a delete icon">
      <mat-icon>golf_course</mat-icon>
    </button>

    <div fxLayout="column"
      fxFlex
      class="details-block">
      <span class="body-bold"
        data-cy='course-mapping-task-id'>Task ID: {{ (courseMappingTaskDetails$ | async)?.taskId }}</span>
      <span class="md2-emphasis-md">{{ (courseMappingTaskDetails$ | async)?.taskName }}</span>
    </div>
    <div fxLayoutAlign="center center">
      <button mat-flat-button
        *ngIf="!(facade.hasApproved$ | async)"
        (click)="facade.approveTask(taskId)"
        [disabled]="facade.isFetching$ | async"
        data-cy="approve-course-task"
        color="primary">
        <mat-icon>check</mat-icon><span>Confirm</span>
      </button>
      <button mat-flat-button
        *ngIf="facade.hasApproved$ | async"
        data-cy="course-task-approved"
        class="confirmed-button">
        <mat-icon>check</mat-icon><span>Confirmed</span>
      </button>
    </div>
  </div>

</mat-card>
