<form id="add-user-role-comp-summary"
  [formGroup]="connectedUserFormGroup"
  fxLayout="column">
  <h2 class="mat-h2">View All / Delete Connected Students</h2>

  <sf-account-search #searchInput
    placeHolderText="Search by User ID"
    (onSearch)='filterSubject$.next($event)'></sf-account-search>
  <p class="warning-text"
    data-cy="deletion-notification"
    *ngIf="deletionCount > 0">You have deleted {{this.deletionCount}} student ID(s). </p>

  <p class="warning-text"
    data-cy="no-search-results"
    *ngIf="(filteredChips$ | async)?.length === 0">There are no records that match that access id. Please clear the search bar to see all student IDs or search again.</p>

  <mat-chip-list class="chip-list-group">
    <mat-chip *ngFor="let thing of (filteredChips$ | async | sortDisplayName)"
      class="chip-padding"
      [removable]="'true'"
      [attr.data-cy]="'chip-' + thing.displayName"
      (removed)="removeSelectedItem(thing)">{{thing.id}}
      <mat-icon matChipRemove
        [attr.data-cy]="'chip-' + thing.displayName + '-remove'">cancel</mat-icon>
    </mat-chip>
  </mat-chip-list>

</form>
