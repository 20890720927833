<mat-form-field class="autocomplete-form-field"
  appearance="outline"
  fxFlex
  floatLabel="never">
  <mat-icon class="search-icon"
    matPrefix>search</mat-icon>
  <input #searchByRole
    data-cy="search-by-role-name"
    type="text"
    [placeholder]="placeHolderText"
    aria-label="Number"
    matInput>
</mat-form-field>
