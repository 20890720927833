<div class="area-directors-main"
  *ngIf="{result: (filteredDelegations$ | async)} as filteredDelSubs">

  <h1 class="mat-h1">Role Management</h1>
  <sf-account-search (onSearch)='filterSubject.next($event)'
    placeHolderText="Search by delegation name"></sf-account-search>
  <div>
    <h2 class="groups-h2 md2-emphasis-md">Assigned Delegations</h2>
    <mat-progress-bar *ngIf="facade.loadingDelegations$ | async"
      data-cy="save-role-progress-bar"
      mode="indeterminate"></mat-progress-bar>
    <mat-accordion>
      <mat-expansion-panel hideToggle
        #matRolePanel
        *ngFor="let delegation of filteredDelSubs.result; index as i"
        [attr.data-cy]="delegation?.name + 'expansion panel'"
        class="mat-elevation-z0 exp-panel-class">
        <mat-expansion-panel-header class="exp-panel-header">
          <mat-panel-title fxLayoutAlign="start center"
            fxLayoutGap="8px">
            <sf-role-icon iconName="waves"
              [isOpen]="matRolePanel.expanded"
              [roleName]="delegation.name"></sf-role-icon>

            <div fxLayout="column"
              class="role-name-div">
              <span class="mat-subtitle-1"
                [ngClass]="{'accordian-selected': matRolePanel.expanded}">{{delegation.name}}</span>
            </div>

          </mat-panel-title>
          <mat-panel-description>
            <mat-icon svgIcon="alert-outline"
              data-cy='you-have-unsaved-work-icon'
              class="unsaved-warning"
              *ngIf="hasUnsavedWork(delegation.id)"></mat-icon>
            <span data-cy='you-have-unsaved-work-text'
              class="unsaved-warning"
              *ngIf="hasUnsavedWork(delegation.id)">
              You have unsaved work!
            </span>
            <span fxFlex></span>
            <mat-icon svgIcon="square-edit-outline"></mat-icon>

          </mat-panel-description>
        </mat-expansion-panel-header>

        <ng-template matExpansionPanelContent>

          <sf-assignment-form #assignmentFormComp
            [configuredDelegation]="delegation.id | configuredDelegationById | async"></sf-assignment-form>

        </ng-template>

      </mat-expansion-panel>
    </mat-accordion>
  </div>

  <div *ngIf="filteredDelSubs.result?.length === 0"
    data-cy='no-delegations-found'
    fxLayout="column"
    class='md2-emphasis-md'
    fxLayoutAlign="center center">
    <h3 class="mat-h3">No Delegations Found</h3>
    <p>Contact the DUS to create a delegation</p>
  </div>
</div>
