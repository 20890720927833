import { AfterContentChecked, Component, Inject } from '@angular/core';
import { NG_VALUE_ACCESSOR, UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { MatLegacyDialog as MatDialog, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { OrgModel, RoleConfig, SelectableRole } from '@starfish-access/models';
import { WarningDialogComponent } from './warning-dialog/warning-dialog.component';

/**
 * This is a presentation component, it takes in a RoleConfigDialogData via the data injection token
 * It's 'output' is that it returns the RoleConfig object from it's internal formGroup.value
 * RoleConfigDialogData contains a RoleConfig, which matches the internal formGroup
 * RoleCOnfigDialogData also contains a SelectableRole, which contains role info as well as
 * possible lists and selectionEnabled flags for orgs, services, and kiosks
 *
 * Summing up the logic, if selectionEnabled is true, then use possible list items for the
 * dropdown contents.
 * If selectionEnabled is false, then put the possible list items into the roleConfig, patch it
 * into the formGroup, and disable that input.
 */

export interface RoleConfigDialogData {
  config: RoleConfig;
  roleInfo: SelectableRole;
}

@Component({
  templateUrl: './role-config-form.component.html',
  styleUrls: ['./role-config-form.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: RoleConfigFormComponent,
      multi: true,
    },
  ],
})
export class RoleConfigFormComponent implements AfterContentChecked {
  roleConfig: UntypedFormGroup;
  initialRoleConfig: RoleConfig;
  roleInfo: SelectableRole;
  possibleKiosks: OrgModel[] = [];
  possibleServices: OrgModel[] = [];
  possibleOrgs: OrgModel[] = [];
  servicesVisible = false;
  kiosksVisible = false;
  coursesVisible = false;
  orgsVisible = false;

  // display a warning to the user, the first
  // time they interact with a simple-service-selector
  private firstChangeWarningDisplayed = false;

  constructor(
    private fb: UntypedFormBuilder,
    @Inject(MAT_DIALOG_DATA)
    public data: RoleConfigDialogData,
    private dialog: MatDialog
  ) {
    this.roleInfo = data.roleInfo;
    this.possibleOrgs = this.roleInfo?.possibleOrgs ? this.roleInfo.possibleOrgs : [];
    this.possibleKiosks = data.config?.kiosksFromRole?.selectedItems ? data.config.kiosksFromRole?.selectedItems : [];
    this.possibleServices = data.config?.servicesFromRole?.selectedItems
      ? data.config.servicesFromRole?.selectedItems
      : [];

    this.initialRoleConfig = data.config;

    this.roleConfig = this.fb.group({
      roleId: [''],
      displayName: [''],
      roleSelectionType: [''],
      roleDelegationId: [],
      servicesFromRole: [this.possibleServices],
      servicesFromDelegation: [this.possibleServices],
      kiosksFromRole: [this.possibleKiosks],
      kiosksFromDelegation: [this.possibleKiosks],
      orgsFromRole: [this.possibleOrgs],
      orgsFromDelegation: [this.possibleOrgs],
      courseConfig: [],
    });

    if (this.initialRoleConfig.kiosksFromDelegation.selectedItems === null) {
      this.initialRoleConfig.kiosksFromDelegation.selectedItems = this.initialRoleConfig.kiosksFromRole.selectedItems;
      this.initialRoleConfig.kiosksFromDelegation.selectionType = this.initialRoleConfig.kiosksFromRole.selectionType;
    } else if (this.initialRoleConfig.kiosksFromDelegation.selectedItems.length === 0) {
      // this.to.shall.pass
    }
    if (this.initialRoleConfig.servicesFromDelegation.selectedItems === null) {
      this.initialRoleConfig.servicesFromDelegation.selectedItems =
        this.initialRoleConfig.servicesFromRole.selectedItems;
      this.initialRoleConfig.servicesFromDelegation.selectionType =
        this.initialRoleConfig.servicesFromRole.selectionType;
    } else if (this.initialRoleConfig.servicesFromDelegation.selectedItems.length === 0) {
      // this.to.shall.pass
    }
    if (this.initialRoleConfig.orgsFromDelegation.selectedItems === null) {
      this.initialRoleConfig.orgsFromDelegation.selectedItems = this.initialRoleConfig.orgsFromRole.selectedItems;
      this.initialRoleConfig.orgsFromDelegation.selectionType = this.initialRoleConfig.orgsFromRole.selectionType;
    } else if (this.initialRoleConfig.orgsFromDelegation.selectedItems.length === 0) {
      // this.to.shall.pass
    }

    this.roleConfig.patchValue(this.initialRoleConfig);

    if (!this.roleInfo?.serviceSelectionEnabled) {
      this.roleConfig.get('servicesFromDelegation')?.disable();
    }

    if (!this.roleInfo?.kioskSelectionEnabled) {
      this.roleConfig.get('kiosksFromDelegation')?.disable();
    }

    if (!this.roleInfo?.orgSelectionEnabled) {
      this.roleConfig.get('orgsFromDelegation')?.disable();
    }
  }

  ngAfterContentChecked(): void {
    this.servicesVisible =
      this.data.config?.servicesFromDelegation?.selectedItems?.length > 0 ||
      this.data.roleInfo?.serviceSelectionEnabled;

    this.kiosksVisible =
      this.data.config?.kiosksFromDelegation?.selectedItems?.length > 0 || this.data.roleInfo?.kioskSelectionEnabled;

    this.coursesVisible = this.data.roleInfo?.roleType === 'COURSE';

    this.orgsVisible =
      this.data.config?.orgsFromDelegation?.selectedItems?.length > 0 || this.data.roleInfo?.orgSelectionEnabled;
  }

  submit(rc: RoleConfig): RoleConfig {
    // the endpoint does not want an empty array in the servicesFromDeledation Section. This means if the user has chosen
    // to defer the selection of services (ADMIN_AREA_CHOOSE), we should pass them the list of all services from the initial role config
    if (
      rc.servicesFromDelegation.selectedItems.length === 0 &&
      rc.servicesFromDelegation.selectionType === 'ADMIN_AREA_CHOOSE'
    ) {
      rc.servicesFromDelegation.selectedItems = rc.servicesFromRole.selectedItems;
    }

    if (
      rc.kiosksFromDelegation.selectedItems.length === 0 &&
      rc.kiosksFromDelegation.selectionType === 'ADMIN_AREA_CHOOSE'
    ) {
      rc.kiosksFromDelegation.selectedItems = rc.kiosksFromRole.selectedItems;
    }

    if (
      rc.orgsFromDelegation?.selectedItems?.length === 0 &&
      rc.orgsFromDelegation?.selectionType === 'ADMIN_AREA_CHOOSE'
    ) {
      rc.orgsFromDelegation.selectedItems = rc.orgsFromRole.selectedItems;
    }
    return rc;
  }

  registerFirstChange() {
    if (!this.firstChangeWarningDisplayed) {
      this.dialog.open(WarningDialogComponent);
    }
    this.firstChangeWarningDisplayed = true;
  }
}
