import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatLegacyCardModule as MatCardModule } from '@angular/material/legacy-card';
import { MatLegacyDialogModule as MatDialogModule } from '@angular/material/legacy-dialog';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyProgressBarModule as MatProgressBarModule } from '@angular/material/legacy-progress-bar';
import { RouterModule } from '@angular/router';
import { FilterModule } from '@starfish-access/core';
import { StarfishCommonModule } from '@starfish-access/shared';
import { DelegationCreateComponent } from './delegation-create/delegation-create.component';
import { DelegationCreateFacade } from './delegation-create/delegation-create.facade';
import { DelegationEditComponent } from './delegation-edit/delegation-edit.component';
import { DelegationListComponent } from './delegation-list/delegation-list.component';
import { DelegationRoutingModule } from './delegation-routing.module';
import { DelegationComponent } from './delegation.component';
import { DelegationFacade } from './delegation.facade';
import { RoleDeletionDialogComponent } from './role-deletion-dialog/role-deletion-dialog.component';
@NgModule({
  declarations: [
    DelegationComponent,
    DelegationListComponent,
    DelegationCreateComponent,
    DelegationEditComponent,
    RoleDeletionDialogComponent,
  ],
  imports: [
    CommonModule,
    StarfishCommonModule,
    DelegationRoutingModule,
    MatButtonModule,
    MatCardModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    MatExpansionModule,
    MatIconModule,
    FilterModule,
    MatProgressBarModule,
    MatDialogModule,
    FlexLayoutModule, // sorry matt
  ],
  providers: [DelegationFacade, DelegationCreateFacade],
})
export class DelegationModule {}
