<div class="mat-typography">
  <h3 *ngIf="title">{{ title }}</h3>
  <div mat-dialog-content
    class="mat-typography">{{ message }}</div>
  <div fxLayoutAlign="end center"
    mat-dialog-actions>
    <button type="button"
      mat-button
      color="primary"
      (click)="dialogRef.close(true)">{{okLabel}}</button>
  </div>
</div>
