import { Component, EventEmitter, OnDestroy, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { MatLegacySlideToggleChange as MatSlideToggleChange } from '@angular/material/legacy-slide-toggle';
import { Store } from '@ngrx/store';
import { NotificationService } from '@psu/components/notification-ngrx';
import { AuthService } from '@psu/utils/security';
import { UserPreferencesService } from '@starfish-access/core';
import { BehaviorSubject, Observable, of, Subject } from 'rxjs';
import { catchError, map, mapTo, take, takeUntil } from 'rxjs/operators';
import { CourseChannelService } from '../../core/services/course-channel.service';
import { selectProcessRequestsSubmitted } from '../../features/details/details.reducer';
import { NavListService } from './nav-list.service';
import { NavSection } from './nav-section.model';
import { navViews } from './nav-views';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'psu-nav-list',
  templateUrl: './nav-list.component.html',
  styleUrls: ['./nav-list.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class NavListComponent implements OnInit, OnDestroy {
  @Output() sfSelect: EventEmitter<any> = new EventEmitter<any>();
  cartNumber: Observable<number[]>;
  navSections: Observable<NavSection[]>;
  menuSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  private destroy$ = new Subject<void>();
  constructor(
    private store: Store<any>,
    private service: NavListService,
    public courseService: CourseChannelService,
    public authService: AuthService,
    public prefs: UserPreferencesService,
    private notifications: NotificationService
  ) {
    this.navSections = this.service.filter(navViews);
    this.cartNumber = this.store.select(selectProcessRequestsSubmitted);
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
  ngOnInit(): void {
    this.prefs.getPreferences$
      .pipe(
        takeUntil(this.destroy$),
        map((res) => res.keepMenuOpen),
        catchError(() => of(false))
      )
      .subscribe((res) => this.menuSubject.next(res));
  }

  saveUserPrefs(change: MatSlideToggleChange): void {
    this.prefs
      .updatePreferences({ keepMenuOpen: change.checked })
      .pipe(
        take(1),
        mapTo(true),
        catchError(() => of(false))
      )
      .subscribe((res) => {
        if (res) {
          this.notifications.success({
            message: 'Your preferences have been updated.',
            closeText: 'OK',
          });
        } else {
          this.notifications.error({
            message: 'We could not update your preferences at this time.',
            closeText: 'OK',
          });
        }
      });
  }

  onSelect(): void {
    this.sfSelect.emit();
  }
}
