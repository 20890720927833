import { Injectable, QueryList } from '@angular/core';
import { ActivatedRouteSnapshot, CanDeactivate, RouterStateSnapshot } from '@angular/router';
import { ConfirmDialogService } from '@starfish-access/core';
import { Observable } from 'rxjs';
import { DelegationComponent } from '../delegation.component';
import { DelegationEditComponent } from './delegation-edit.component';

@Injectable({ providedIn: 'root' })
export class DelegationEditGuard implements CanDeactivate<DelegationComponent> {
  constructor(private dialogService: ConfirmDialogService) {}

  canDeactivate(
    component: DelegationComponent,
    currentRoute: ActivatedRouteSnapshot,
    currentState: RouterStateSnapshot,
    nextState: RouterStateSnapshot
  ): boolean | Observable<boolean> {
    const list: QueryList<DelegationEditComponent> = component?.delList?.formGroupComponents;
    let allPassing = true;
    list.forEach((element) => {
      if (!element.delegationFormGroup.pristine) {
        allPassing = false;
      }
    });

    return allPassing
      ? true
      : this.dialogService.confirmDiscard('If you have unsaved changes, they will be lost if you leave.');
  }
}
