import { AfterViewInit, Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import {
  AbstractControl,
  ControlValueAccessor,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  UntypedFormBuilder,
  UntypedFormGroup,
  ValidationErrors,
  Validators,
} from '@angular/forms';
import { MatLegacyOptionSelectionChange as MatOptionSelectionChange } from '@angular/material/legacy-core';
import { MatLegacySelect as MatSelect } from '@angular/material/legacy-select';
import { OrgModel, SelectionConfig } from '@starfish-access/models';
import { equals, isEmpty } from 'ramda';

@Component({
  selector: 'sf-simple-service-selector',
  templateUrl: './simple-service-selector.component.html',
  styleUrls: ['./simple-service-selector.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: SimpleServiceSelectorComponent,
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      useExisting: SimpleServiceSelectorComponent,
      multi: true,
    },
  ],
})
export class SimpleServiceSelectorComponent implements ControlValueAccessor, AfterViewInit {
  @Input() serviceType: 'Service' | 'Kiosk';
  @Input() possibleItems: OrgModel[];
  @Input() disabled = false;
  @Input() isOrg = false;
  @Output() notificationEmitter: EventEmitter<void> = new EventEmitter();
  @ViewChild('selectElement') private select: MatSelect;
  selectDisabled = false;
  menuFormGroup: UntypedFormGroup;

  constructor(fb: UntypedFormBuilder) {
    this.menuFormGroup = fb.group({
      serviceSelector: [[], Validators.required],
    });
  }

  get serviceSelector(): AbstractControl | null {
    return this.menuFormGroup.get('serviceSelector');
  }

  ngAfterViewInit(): void {
    this.menuFormGroup.valueChanges.subscribe((newValue: { serviceSelector: string[] }) => {
      if (!newValue.serviceSelector || newValue.serviceSelector.includes('NONE')) {
        this.propagateChange({
          selectedItems: [],
          selectionType: 'NONE',
        });
      } else if (newValue.serviceSelector.includes('ADMIN_AREA_CHOOSE')) {
        // remove 'ADMIN_AREA_CHOOSE' from selection and populate selected items
        const deferIndex = newValue.serviceSelector.indexOf('ADMIN_AREA_CHOOSE');
        newValue.serviceSelector.splice(deferIndex, 1);
        this.propagateChange({
          selectedItems: newValue.serviceSelector,
          selectionType: 'ADMIN_AREA_CHOOSE',
        });
      } else {
        this.propagateChange({
          selectedItems: newValue.serviceSelector,
          selectionType: 'SPECIFIC_SERVICES',
        });
      }
    });
    setTimeout(() => {
      this.setDisabledState(this.disabled);
    });
  }

  setDisabledState(isDisabled: boolean): void {
    if (isDisabled) {
      this.menuFormGroup.get('serviceSelector')?.disable();
    } else {
      this.menuFormGroup.get('serviceSelector')?.enable();
    }
  }

  writeValue(obj: SelectionConfig<any>): void {
    // I dunno man, but if this isn't here pre-loaded roles in delegations won't load orgs when editing
    if (this.isOrg) {
      if (!obj) {
        return;
      }
      if (obj?.selectedItems === null || obj?.selectedItems === undefined) {
        obj.selectedItems = [];
      }
      obj?.selectedItems.forEach((element) => {
        if (!element.id) {
          element.id = undefined;
        }
      });
    }
    // end

    if (obj?.selectionType === 'NONE') {
      // services, kiosks, orgs where that specific option is not available
      this.serviceSelector?.setValue(['NONE']);
    } else if (obj?.selectedItems?.length === 0) {
      // services, kiosks, orgs with an empty array stored ([] No <thing> Selected checkbox selected)
      this.serviceSelector?.setValue(['ALL']);
    } else if (obj?.selectionType === 'ADMIN_AREA_CHOOSE') {
      // services, kiosks, orgs  with a subset of items selected and deferred.
      const values: any[] = [];
      values.push('ADMIN_AREA_CHOOSE');
      values.push(...obj?.selectedItems);
      this.serviceSelector?.setValue(values);
    } else if (obj?.selectionType === 'SPECIFIC_SERVICES') {
      // services, kiosks, orgs  with a subset of items selected from a previous step and not allowed to be edited.
      this.serviceSelector?.setValue(obj?.selectedItems);
    }
  }

  registerOnChange(fn: any): void {
    this.propagateChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.propagateTouch = fn;
  }

  // Used force mat-select to compare by value instead of by reference
  compareOrgModels(object1: OrgModel | string, object2: OrgModel | string): boolean {
    return equals(object1, object2);
  }

  /**
   * Handles the exclusivity behavior of the dropdown
   */
  selectionChange($event: MatOptionSelectionChange): void {
    const sourceValue = $event.source.value;
    const formGroupValue: string[] = this.serviceSelector?.value;
    if (sourceValue === 'ALL' && !formGroupValue.includes('ALL') && $event.isUserInput) {
      this.serviceSelector?.setValue(['ALL']);
      this.select.close();
    } else if (
      sourceValue === 'ADMIN_AREA_CHOOSE' &&
      !formGroupValue.includes('ADMIN_AREA_CHOOSE') &&
      $event.isUserInput
    ) {
      const currentValue: string[] = this.serviceSelector?.value;
      const newValue = currentValue.filter((v) => !['ALL'].includes(v));
      this.serviceSelector?.setValue(newValue);
    } else if (sourceValue !== 'ALL' && sourceValue !== 'ADMIN_AREA_CHOOSE' && $event.isUserInput) {
      const currentValue: string[] = this.serviceSelector?.value;
      if (currentValue.includes('ALL') || currentValue.includes('ADMIN_AREA_CHOOSE')) {
        const newValue = currentValue.filter((v) => !['ALL'].includes(v));
        this.serviceSelector?.setValue(newValue);
      }
    }
  }

  validate(_control: AbstractControl): ValidationErrors | null {
    const validationErrors: ValidationErrors = {};
    if (!this.menuFormGroup.get('serviceSelector')?.valid) {
      validationErrors.requiredEntry = 'You must make a selection';
    }
    return !isEmpty(validationErrors) ? validationErrors : null;
  }

  private propagateChange: (_: any) => any = (_: any) => ({});
  private propagateTouch: (_: any) => any = (_: any) => ({});
}
