<div class="sync-courses-display"
  fxLayout="column">

  <div fxFlex
    fxLayoutGap="18px">
    <span fxFlex></span>
    <button mat-stroked-button
      [attr.data-cy]="courseChannel.channelServiceId + '-info-button'"
      (click)="facade.subjectInfoDialog(courseChannel)"
      color="primary">
      <mat-icon svgIcon="lightbulb-outline"></mat-icon><span class="info-btn-text">Assigned Subjects</span>
    </button>
    <button mat-flat-button
      [disabled]='!(courseChannel | hasUnsyncedCourses)'
      [attr.data-cy]="courseChannel.channelServiceId + '-sync-button'"
      (click)="syncCourse()"
      color="primary">
      <mat-icon>refresh</mat-icon><span class="bulk-btn-text">Bulk Sync</span>
    </button>
    <button mat-flat-button
      [cdkCopyToClipboard]="courseCopyString()"
      color="primary">
      <mat-icon>content_copy</mat-icon><span class="bulk-btn-text">Copy All</span>
    </button>
  </div>

  <mat-form-field>
    <mat-label>Filter</mat-label>
    <input matInput
      data-cy="course-filter"
      (keyup)="applyFilter($event)"
      placeholder="Ex. UP, Fall 2020, MATH, etc."
      #input>
  </mat-form-field>
  <table mat-table
    matSort
    (matSortChange)="sortData($event)"
    matSortActive="synchronized"
    class="mat-elevation-z0 starfish-data-table"
    [dataSource]="dataSource">
    <!--- Note that these columns can be defined in any order.
          The actual rendered columns are set as a property on the row definition" -->

    <ng-container matColumnDef="synchronized">
      <th mat-header-cell
        *matHeaderCellDef
        mat-sort-header> Synced </th>
      <td mat-cell
        *matCellDef="let element">
        <span *ngIf="element.synchronized">
          <mat-icon svgIcon="check"
            [attr.data-cy]="element.courseDto.sectionId + 'synced'"
            class="green4 icon icon-sync"></mat-icon>
        </span>
        <span *ngIf="!element.synchronized">
          <mat-icon svgIcon="block-helper"
            [attr.data-cy]="element.courseDto.sectionId + 'unsynced'"
            class="icon icon-unsync"></mat-icon>
        </span>
      </td>
    </ng-container>

    <ng-container matColumnDef="department">
      <th mat-header-cell
        *matHeaderCellDef
        mat-sort-header> Subject </th>
      <td mat-cell
        *matCellDef="let element"> {{element.courseDto.department}} </td>
    </ng-container>

    <ng-container matColumnDef="courseNumber">
      <th mat-header-cell
        *matHeaderCellDef
        mat-sort-header> Course </th>
      <td mat-cell
        *matCellDef="let element"> {{element.courseDto.courseNumber}} </td>
    </ng-container>

    <ng-container matColumnDef="sectionNumber">
      <th mat-header-cell
        *matHeaderCellDef
        mat-sort-header> Section </th>
      <td mat-cell
        *matCellDef="let element"> {{element.courseDto.sectionNumber}} </td>
    </ng-container>

    <ng-container matColumnDef="termName">
      <th mat-header-cell
        *matHeaderCellDef
        mat-sort-header> Term </th>
      <td mat-cell
        *matCellDef="let element"> {{element.courseDto.termName}} </td>
    </ng-container>

    <ng-container matColumnDef="campus">
      <th mat-header-cell
        *matHeaderCellDef
        mat-sort-header> Campus </th>
      <td mat-cell
        *matCellDef="let element"> {{element.courseDto.campus}} </td>
    </ng-container>

    <tr mat-header-row
      *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row
      *matRowDef="let row; columns: displayedColumns;"></tr>

    <tr class="mat-row"
      *matNoDataRow>
      <td class="mat-cell"
        colspan="6">
        <div data-cy='classes-empty'
          class="no-classes-div"
          fxLayoutAlign="center center">
          <p class="md2-emphasis-md">There were no classes found for this service</p>
        </div>
      </td>
    </tr>
  </table>

</div>
