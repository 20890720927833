import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  TemplateRef,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { FormGroupDirective, NgForm, UntypedFormControl } from '@angular/forms';
import { MatLegacyAutocompleteSelectedEvent as MatAutocompleteSelectedEvent, MatLegacyAutocompleteTrigger as MatAutocompleteTrigger } from '@angular/material/legacy-autocomplete';
import { ErrorStateMatcher } from '@angular/material/core';
import { Observable, Subject } from 'rxjs';
import { takeUntil, takeWhile, tap } from 'rxjs/operators';
import { v4 as uuid } from 'uuid';
import { TypeaheadConfig } from './typeahead.config';
import { TypeaheadFacade } from './typeahead.facade';
import { LOAD_ALL_OPTION } from './typeahead.model';
import { TypeaheadComponentState } from './typeahead.reducer';
import { User } from './user.model';

export class TypeaheadErrorStateMatcher implements ErrorStateMatcher {
  private hasError: boolean;
  private alive = true;
  constructor(private errorState: Observable<string>) {
    this.errorState.pipe(takeWhile(() => this.alive)).subscribe((error) => (this.hasError = !!error));
  }

  isErrorState(control: UntypedFormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    return !!this.hasError;
  }

  destroy(): void {
    this.alive = true;
  }
}

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'ta-typeahead',
  templateUrl: './typeahead.component.html',
  styleUrls: ['./typeahead.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class TypeaheadComponent implements OnInit, OnDestroy {
  @Input()
  floatLabel: boolean;

  @Input()
  placeholder: string;

  @Input()
  config: TypeaheadConfig;

  @Input()
  resultTemplate: TemplateRef<any>;

  @Input()
  noResultsTemplate: TemplateRef<any>;

  @Input()
  fullSearchTemplate: TemplateRef<any>;

  @Input()
  showSearchButton: boolean;

  @Input()
  showMatPrefix = true;

  @Input()
  overlay = true;

  @Input()
  autoActiveFirstOption = true;

  @Input()
  clearButtonIconTemplate: TemplateRef<any>;

  @Output()
  selected: EventEmitter<User> = new EventEmitter();

  @Output()
  viewAll: EventEmitter<string> = new EventEmitter();

  @Output()
  noResults = new EventEmitter<void>();

  @Output()
  clearResults = new EventEmitter<void>();

  @Output()
  results: EventEmitter<User[]> = new EventEmitter();
  @ViewChild(MatAutocompleteTrigger, { static: false })
  private autocompleteTrigger: MatAutocompleteTrigger;
  currentTerm$ = new Subject<string | undefined>();

  searchState$: Observable<TypeaheadComponentState>;
  userSearchCtrl: UntypedFormControl = new UntypedFormControl();

  visible = true;
  selectable = true;

  currentTerm: string | undefined;

  errorStateMatcher: TypeaheadErrorStateMatcher;

  readonly loadAllOption = LOAD_ALL_OPTION;

  private componentId: string;
  private searchError$ = new Subject<string>();
  private destroyed$: Subject<void>;

  constructor(private facade: TypeaheadFacade) {
    this.errorStateMatcher = new TypeaheadErrorStateMatcher(this.searchError$);

    this.componentId = uuid();
    this.destroyed$ = new Subject();
  }

  ngOnInit(): void {
    this.searchState$ = this.facade
      .observeComponent(
        this.componentId,
        this.userSearchCtrl.valueChanges.pipe(takeUntil(this.destroyed$)),
        this.config
      )
      .pipe(
        tap((state) => {
          this.currentTerm = state.currentTerm;
          if (state.results) {
            this.results.emit(state.results);
            if (state.results.length === 0) {
              this.noResults.emit();
              this.currentTerm$.next(state.currentTerm);
            }
          }
          if (!!state.error) {
            this.searchError$.next(state.error);
          }
        }),
        takeUntil(this.destroyed$)
      );
  }

  ngOnDestroy(): void {
    this.errorStateMatcher.destroy();
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  onSelectionChanged(event: MatAutocompleteSelectedEvent): void {
    const selectedUser: User = event.option.value;
    this.selectItem(selectedUser);
  }

  loadAll(results: User[]): void {
    if (this.currentTerm) {
      this.selectItem(this.loadAllOption);
      if (
        this.autocompleteTrigger &&
        this.autocompleteTrigger.autocomplete &&
        this.autocompleteTrigger.autocomplete.isOpen
      ) {
        this.autocompleteTrigger.closePanel();
      }
    }
  }

  clear(): void {
    this.clearInput(true);
    this.currentTerm = undefined;
    this.clearResults.emit();
    this.currentTerm$.next(this.currentTerm);
  }

  userDisplay(value: User | string): string | undefined {
    if (value) {
      if (typeof value === 'string') {
        return this.currentTerm;
      } else {
        return value.userid || value.psuid;
      }
    } else {
      return undefined;
    }
  }

  private selectItem(selectedUser: User | string): void {
    if (typeof selectedUser === 'string') {
      this.viewAll.emit(this.currentTerm);
      this.currentTerm$.next(this.currentTerm);
    } else {
      this.selected.emit(selectedUser);
      this.currentTerm$.next(selectedUser.userid || selectedUser.psuid);
    }
    this.clearInput(this.config.clearInputOnSelection);
  }

  private clearInput(resetControl: boolean | undefined): void {
    if (resetControl) {
      this.userSearchCtrl.reset();
      this.facade.clearSearchInput(this.componentId);
    }
  }
}
