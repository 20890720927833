<div class="mat-typography">
  <h3>Return Request to a User</h3>
  <div mat-dialog-content
    fxLayout="column">
    <p>Select the user below to return this request to. This action cannot be undone.</p>
    <mat-select class='mat-autocomplete-width'
      [(ngModel)]="selectedValue"
      placeholder="Select User">
      <mat-option *ngFor="let user of users"
        [value]="user">{{ user }}</mat-option>
    </mat-select>
    <mat-form-field fxFlex>
      <mat-label>Add in any relevant justifications for the requested roles here</mat-label>
      <textarea data-cy="return-to-user-dialog-input"
        matInput
        cdkTextareaAutosize
        [(ngModel)]="comment"
        #autosize="cdkTextareaAutosize"
        cdkAutosizeMinRows="1"
        cdkAutosizeMaxRows="5"></textarea>
    </mat-form-field>
  </div>
  <div fxLayoutAlign="end center"
    mat-dialog-actions>
    <button type="button"
      mat-button
      color=warn
      (click)="dialogRef.close()">CANCEL</button>
    <button type="button"
      data-cy="confirm-user-select"
      disabled
      [attr.disabled]="selectedValue ? null : undefined "
      mat-button
      color=primary
      (click)="dialogRef.close({user: selectedValue, comment: comment})">CONFIRM</button>

  </div>
</div>
