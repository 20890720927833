import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatLegacyCardModule as MatCardModule } from '@angular/material/legacy-card';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyListModule as MatListModule } from '@angular/material/legacy-list';
import { MatLegacyProgressBarModule as MatProgressBarModule } from '@angular/material/legacy-progress-bar';
import { MatLegacyTableModule as MatTableModule } from '@angular/material/legacy-table';
import { MatLegacyTooltipModule as MatTooltipModule } from '@angular/material/legacy-tooltip';
import { RouterModule } from '@angular/router';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { NotificationModule } from '@psu/components/notification-ngrx';
import { FilterModule, OkayDialogModule } from '@starfish-access/core';
import { WorkflowDetailsModule, WorkflowHistoryModule } from '@starfish-access/shared';
import { DetailsComponent } from './details.component';
import { DetailsEffects } from './details.effects';
import { detailsListReducer } from './details.reducer';
import { routes } from './details.routing';
import { DetailsService } from './details.service';

@NgModule({
  imports: [
    CommonModule,
    MatButtonModule,
    MatCardModule,
    MatIconModule,
    MatListModule,
    MatProgressBarModule,
    MatTooltipModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule.forChild(routes),
    NotificationModule,
    MatTableModule,
    FlexLayoutModule,
    FilterModule,
    WorkflowDetailsModule,
    WorkflowHistoryModule,
    OkayDialogModule,
    StoreModule.forFeature('detailsList', detailsListReducer),
    EffectsModule.forFeature([DetailsEffects]),
  ],
  declarations: [DetailsComponent],
  exports: [RouterModule],
  providers: [DetailsService],
})
export class DetailsModule {}
