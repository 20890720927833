<ng-container *ngLet="searchState$ | async as searchState">
  <form
    (submit)="loadAll(results)"
    *ngLet="searchState?.results as results">
    <mat-form-field
      appearance="outline"
      class="autocomplete-form-field"
      [floatLabel]="floatLabel"
      [class.with-search-button]="showSearchButton">
      <mat-icon
        matPrefix
        matTooltip="Search"
        matTooltipPosition="below"
        aria-disabled="true"
        *ngIf="showMatPrefix"
        class="search-icon">
        search
      </mat-icon>
      <input
        *ngIf="overlay"
        matInput
        autofocus
        [placeholder]="placeholder"
        [formControl]="userSearchCtrl"
        [matAutocomplete]="auto"
        [errorStateMatcher]="errorStateMatcher"
        data-cy="typeahead-input" />
      <input
        *ngIf="!overlay"
        matInput
        autofocus
        [placeholder]="placeholder"
        [formControl]="userSearchCtrl"
        [errorStateMatcher]="errorStateMatcher"
        data-cy="typeahead-input" />
      <mat-spinner
        *ngIf="searchState?.searchInProgress"
        class="search-spinner"
        color="primary"
        [diameter]="config.spinnerDiameter ? config.spinnerDiameter : 35">
      </mat-spinner>
      <button
        *ngIf="clearButtonIconTemplate &amp;&amp; currentTerm"
        type="button"
        mat-icon-button
        (click)="clear()"
        class="clear-button"
        matTooltip="Clear Search"
        aria-label="Clear Search">
        <ng-container *ngTemplateOutlet="clearButtonIconTemplate"></ng-container>
      </button>
      <button
        *ngIf="showSearchButton"
        mat-button
        type="submit"
        matSuffix
        class="search-button"
        fxLayout="row"
        fxLayoutAlign="center center"
        aria-label="Search">
        <mat-icon>search</mat-icon>
      </button>
      <mat-autocomplete
        #auto="matAutocomplete"
        (optionSelected)="onSelectionChanged($event)"
        [autoActiveFirstOption]="autoActiveFirstOption"
        [displayWith]="userDisplay.bind(this)"
        class="ta-auto">
        <ng-container *ngLet="searchState?.hasSearched as hasSearched">
          <mat-option
            *ngFor="let result of results"
            [ngStyle]="{ 'height.px': config.resultHeight }"
            [value]="result">
            <ng-container *ngTemplateOutlet="resultTemplate; context: { $implicit: result }"></ng-container>
          </mat-option>
          <mat-option
            [disabled]="true"
            *ngIf="results?.length === 0 &amp;&amp; hasSearched &amp;&amp; noResultsTemplate">
            <ng-container *ngTemplateOutlet="noResultsTemplate"></ng-container>
          </mat-option>
          <mat-option
            *ngIf="results?.length &gt; 0 &amp;&amp; fullSearchTemplate &amp;&amp; !searchState.isSpecialSearch"
            [value]="loadAllOption"
            class="full-search">
            <ng-container *ngTemplateOutlet="fullSearchTemplate"></ng-container>
          </mat-option>
        </ng-container>
      </mat-autocomplete>
      <mat-error *ngIf="searchState?.error">{{ searchState?.error }}</mat-error>
    </mat-form-field>
  </form>
</ng-container>
