import { Component } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'textarea-dialog',
  templateUrl: './textarea-dialog.html',
})
// eslint-disable-next-line @angular-eslint/component-class-suffix
export class TextAreaDialog {
  title: string;
  commentsBody = '';

  constructor(public dialogRef: MatDialogRef<TextAreaDialog>) {}
}
