import { Component, Input } from '@angular/core';
import { FooterNavigation } from './footer-navigation.model';

@Component({
  selector: 'sf-mat-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class FooterComponent {
  /** Relative path to the logo image for large devices */
  @Input()
  logoLarge: string;

  /** Relative path to the logo image for mobile devices */
  @Input()
  logoMobile: string;

  /**
   * Copyright year.  Use the year of first publishing;
   * this should not automatically increment each year.
   */
  @Input()
  year: string;

  @Input()
  footerNavigation: FooterNavigation = {
    psuLink: 'http://www.psu.edu',
    serviceDeskLink: 'http://www.itservicedesk.psu.edu',
    legalLink: 'http://www.psu.edu/ur/legal.html',
    accessibilityLink: 'http://www.psu.edu/accessibilitystatement',
    copyrightLink: 'http://www.psu.edu/ur/copyright.html',
  };
}
