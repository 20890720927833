<section *ngIf="canView | async; else perms_error">
  <mat-card
    *ngLet="{
      allRoleGroups: facade.roleGroupsWithAdminGroups$ | async
    } as asyncValues"
    class="result card-table">
    <div fxLayout="row">
      <h1 fxFlex>Starfish Users</h1>
      <button
        mat-flat-button
        class="new-user-btn"
        color="primary"
        (click)="openDialog()">
        Add New User
      </button>
    </div>

    <mat-card-content>
      <span *ngIf="dataSource?.loadingSubject() | async">
        <mat-progress-bar mode="indeterminate"></mat-progress-bar>
      </span>
      <div fxLayout="column">
        <sf-usermgt-filter (setFilter)="searchByFilter($event)"> </sf-usermgt-filter>

        <table
          mat-table
          multiTemplateDataRows
          matSort
          (matSortChange)="sortData($event)"
          [dataSource]="dataSource"
          class="mat-elevation-z0 starfish-data-table">
          <!--- Note that these columns can be defined in any order.
                            The actual rendered columns are set as a property on the row definition" -->

          <!-- Name Column -->
          <ng-container matColumnDef="displayName">
            <th
              mat-header-cell
              *matHeaderCellDef
              [attr.data-cy]="'-user-name-heading'">
              User Name
            </th>
            <td
              mat-cell
              *matCellDef="let element"
              [attr.data-cy]="element.displayName + '-user-with-role'">
              {{ element.displayName }}
            </td>
          </ng-container>

          <!-- ID Column -->
          <ng-container matColumnDef="userId">
            <th
              mat-header-cell
              *matHeaderCellDef
              [attr.data-cy]="'-user-id-heading'"
              mat-sort-header>
              User ID
            </th>
            <td
              mat-cell
              *matCellDef="let element"
              [attr.data-cy]="element.userId + '-user-role-item'">
              {{ element.userId }}
            </td>
          </ng-container>

          <!-- Roles Column -->
          <ng-container matColumnDef="roles">
            <th
              mat-header-cell
              *matHeaderCellDef>
              Roles
            </th>
            <td
              mat-cell
              *matCellDef="let element">
              <mat-chip-list>
                <mat-chip
                  *ngFor="let x of element.roles"
                  [attr.data-cy]="x.roleName + '-single-role-item'"
                  [removable]="true"
                  (removed)="removeUserRole(element.userId, x.roleId)">
                  {{ x.roleName }}
                  <mat-icon
                    [attr.data-cy]="x.roleName + '-single-role-delete'"
                    matChipRemove
                    >cancel</mat-icon
                  >
                </mat-chip>
              </mat-chip-list>
            </td>
          </ng-container>

          <!-- removeUser Column -->
          <ng-container matColumnDef="remove">
            <th
              mat-header-cell
              *matHeaderCellDef>
              <span fxLayoutAlign="center center">Remove User</span>
            </th>
            <td
              mat-cell
              *matCellDef="let element"
              [attr.data-cy]="element.userId + '-remove-user'">
              <a
                fxLayoutAlign="center center"
                *ngIf="canDelete | async"
                (click)="removeUser(element.userId)"
                ><button
                  matTooltip="Remove User"
                  [matTooltipShowDelay]="tooltipDelay"
                  mat-button
                  color="warn">
                  <mat-icon svgIcon="delete-forever"></mat-icon></button
              ></a>
              <span *ngIf="!(canDelete | async)">&nbsp;</span>
            </td>
          </ng-container>

          <!-- Add Role to a User Column -->
          <ng-container matColumnDef="add">
            <th
              mat-header-cell
              *matHeaderCellDef>
              <span fxLayoutAlign="center center">Add Role</span>
            </th>
            <td
              mat-cell
              *matCellDef="let element"
              [attr.data-cy]="element.userId + '-add-to-user'">
              <a
                fxLayoutAlign="center center"
                (click)="expandedElement = expandedElement === element ? null : element">
                <button
                  matTooltip="Add a role to this user"
                  [matTooltipShowDelay]="tooltipDelay"
                  mat-button
                  color="primary">
                  <mat-icon [svgIcon]="element !== expandedElement ? 'plus' : 'minus'"></mat-icon></button
              ></a>
            </td>
          </ng-container>

          <!-- Expanded Content Column - The detail row is made up of this one column that spans across all columns -->
          <ng-container matColumnDef="expandedDetail">
            <td
              mat-cell
              *matCellDef="let element"
              class="usrmgr-td-detail"
              [attr.colspan]="displayedColumns.length">
              <div
                class="usermgr-element-detail"
                [@detailExpand]="element === expandedElement ? 'expanded' : 'collapsed'">
                <sf-add-role
                  #sfAddRoleComp
                  [existingUserRoles]="element"
                  class="add-role-to-user"
                  [userToEvaluate]="element.userId"
                  (closeAction)="expandedElement = expandedElement === element ? null : element"
                  (saveAction)="addRoleToUserAndClose(element.userId)"
                  [disableSaveAction]="false"
                  [allRoles]="asyncValues.allRoleGroups"
                  fxFlex></sf-add-role>
              </div>
            </td>
          </ng-container>

          <tr
            mat-header-row
            *matHeaderRowDef="displayedColumns"></tr>
          <tr
            mat-row
            *matRowDef="let element; columns: displayedColumns"
            class="usermgr-element-row"
            [class.usermgr-expanded-row]="expandedElement === element"></tr>
          <tr
            mat-row
            *matRowDef="let row; columns: ['expandedDetail']"
            class="usermgr-detail-row"></tr>
        </table>
        <mat-paginator
          class="starfish-paginator"
          [length]="dataSource?.totalUsersCount() | async"
          [pageSize]="5"
          [pageSizeOptions]="[5, 10, 20]"
          (page)="pageEvent($event)"
          #paginator></mat-paginator>
      </div>
    </mat-card-content>
  </mat-card>
</section>
<ng-template #perms_error>
  <div class="failed-styling">You do not have access to this content.</div>
</ng-template>
