import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatLegacyPaginator as MatPaginator } from '@angular/material/legacy-paginator';
import { MatSort } from '@angular/material/sort';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { ActivatedRoute, Router } from '@angular/router';
import { PermissionCheckService } from '@psu/apis/fortress';
import { AuthService } from '@psu/utils/security';
import { TOOLTIP_DELAY } from '@starfish-access/core';
import { Tasks } from '@starfish-access/models';
import { TogglerequestsComponent } from '@starfish-access/shared';
import { combineLatest, Observable, Subject } from 'rxjs';
import { filter, mergeMap, takeUntil } from 'rxjs/operators';
import { DisplayUser } from '../roles/roles.model';
import { TaskListFacade } from './tasklist.facade.service';

@Component({
  templateUrl: './tasklist.component.html',
  styleUrls: ['./tasklist.component.scss'],
})
export class TaskListComponent implements OnDestroy, OnInit {
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  isUserSteward: Observable<boolean>;
  isCancelling = false;
  isSelectedToCancel: string[] = [];
  isViewingAllAudits = false;
  isUserDataSteward = false;
  toggleRequestComp: TogglerequestsComponent;

  errorMessage: Observable<string>;
  errorMessageDisplayed = false;
  data: Tasks[] = [];
  hasFailed: Observable<boolean>;
  isFetching: Observable<boolean>;
  tooltipDelay: string = TOOLTIP_DELAY;
  userToDisplay: DisplayUser[] = [];

  displayedColumns: string[] = [
    'taskId',
    'requester',
    'taskName',
    'taskStatus',
    'taskCreatedTime',
    'processInstanceId',
  ];

  dataSource = new MatTableDataSource<Tasks>();
  private destroy$ = new Subject<void>();

  constructor(
    private taskListFacade: TaskListFacade,
    private authService: AuthService,
    private permissionsService: PermissionCheckService,
    private route: ActivatedRoute,
    public router: Router
  ) {
    this.isFetching = this.taskListFacade.isFetching$;
    this.taskListFacade.getTasks().pipe(takeUntil(this.destroy$)).subscribe(this.updateDataSource.bind(this));
    this.hasFailed = this.taskListFacade.hasFailed$;
  }

  ngOnInit() {
    this.errorMessage = this.taskListFacade.errorMsg$;

    // we need the userid and the if the user is an admin
    // once we have those, we need to know if the user is a datasteward, using their userId.
    // when we have all that, we can check the route information to see which set of processes
    // to load.
    const userFromToken = this.authService.getCurrentUser();

    combineLatest([this.permissionsService.hasPermission('starfish-workflow.process.view')])
      .pipe(
        filter(([hasPerms]) => hasPerms !== undefined),
        takeUntil(this.destroy$),
        mergeMap(([hasPerms]) => {
          let userId = '';
          if (userFromToken !== null) {
            userId = userFromToken.userName;
          }

          return this.taskListFacade.requestIfUserIsDataStewardFromService(userId, hasPerms);
        })
      )
      .subscribe((isDataSteward) => {
        this.isUserDataSteward = isDataSteward;
        if (
          this.route.snapshot.queryParams.isAdmin &&
          this.route.snapshot.queryParams.isAdmin === 'true' &&
          isDataSteward
        ) {
          this.toggleRequestComp.setAdmin();
          this.isViewingAllAudits = true;
          this.taskListFacade
            .getAuditTasks()
            .pipe(takeUntil(this.destroy$))
            .subscribe(this.updateDataSource.bind(this));
        } else {
          this.taskListFacade.getTasks().pipe(takeUntil(this.destroy$)).subscribe(this.updateDataSource.bind(this));
        }
      });
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  applyFilter(filterValue: string): void {
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  getApproved(task: Tasks) {
    // we can pass an id to routerLink by using : ['/details', object.id]
    if (task.taskName === 'DUS Approval') {
      this.router.navigate(['/resourcesapproval', task.taskId]);
    } else if (task.taskName === 'Course Mapping Service Synchronization Task') {
      this.router.navigate(['/coursemappingapproval', task.taskId]);
    } else if (task.taskName.indexOf('Auditing') > -1) {
      this.router.navigate(['/auditingapproval', task.taskId]);
    } else {
      this.router.navigate(['/approve', task.taskId]);
    }
  }

  clearErrors() {
    this.errorMessageDisplayed = true;
  }

  viewAuditTasks(): void {
    this.isViewingAllAudits = !this.isViewingAllAudits;
    if (this.isViewingAllAudits) {
      this.taskListFacade.getAuditTasks().pipe(takeUntil(this.destroy$)).subscribe(this.updateDataSource.bind(this));
    } else {
      this.taskListFacade.getTasks().pipe(takeUntil(this.destroy$)).subscribe(this.updateDataSource.bind(this));
    }
    this.dataSource.filter = ''; // reset the filter

    this.router.navigate(['/tasklist'], {
      relativeTo: this.route,
      queryParams: {
        isAdmin: this.isViewingAllAudits,
      },
      queryParamsHandling: 'merge',
      skipLocationChange: false, // do not trigger navigation
    });
  }

  private updateDataSource(res: Tasks[]): void {
    this.data = res;
    this.dataSource.data = res;
    setTimeout(() => {
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
    });
  }
}
