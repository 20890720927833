import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { OkayDialogService } from '../okay-dialog/okay-dialog.service';
import { StarfishFerpaService } from '../services/directory/directory.service';

@Injectable()
export class FerpaGuard implements CanActivate {
  constructor(
    public router: Router,
    private dialogService: OkayDialogService,
    private ferpaService: StarfishFerpaService
  ) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    return this.ferpaService.hasFerpa().pipe(
      tap((hasFerpa) => {
        if (!hasFerpa) {
          this.showErrorDialog(403);
          this.router.navigate(['/']);
        }
      })
    );
  }

  showErrorDialog(err: any): void {
    if (err) {
      const message = 'FERPA training is required for all Starfish users.';
      this.dialogService.okay('FERPA Training Required', message);
    }
  }
}
