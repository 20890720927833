import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { UserInfo } from '@starfish-access/models';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';

export interface DialogData {
  users: UserInfo[];
}

@Component({
  templateUrl: './missing-users-dialog.component.html',
  styleUrls: ['./missing-users-dialog.component.scss'],
})
export class MissingUsersDialogComponent implements OnInit, OnDestroy {
  filterSubject$: BehaviorSubject<string> = new BehaviorSubject('');
  filteredChips$: Observable<UserInfo[]>;
  private destroy$ = new Subject<void>();

  constructor(
    public dialogRef: MatDialogRef<MissingUsersDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData
  ) {}

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  ngOnInit(): void {
    this.filteredChips$ = this.filterSubject$.pipe(
      takeUntil(this.destroy$),
      map((filter) => {
        if (!this.data?.users) {
          // we shouldn't actually see this
          console.error('Never received missing users list');
          return [];
        }

        if (!filter) {
          return this.data.users;
        }

        return this.data.users.filter((u) => {
          if (u?.id) {
            return u.id.toLowerCase().includes(filter.toLowerCase());
          }
          return '';
        });
      })
    );
  }
}
