import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { UserInfo } from '@starfish-access/models';

@Component({
  selector: 'sf-director-list',
  templateUrl: './director-list.component.html',
  styleUrls: ['./director-list.component.scss'],
})
export class DirectorListComponent implements OnChanges {
  @Input() directorList: UserInfo[];
  displayedColumns: string[] = ['name', 'id'];
  dataSource: MatTableDataSource<UserInfo> = new MatTableDataSource<UserInfo>();

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.directorList) {
      this.dataSource.data = this.directorList?.sort((a, b) => a.id.localeCompare(b.id));
    }
  }
}
