<div fxLayout="column">
  <div fxLayout="row">
    <h2 class="md2-emphasis-md">Task History</h2>
    <span fxFlex></span>
    <div fxLayoutAlign="end center">
      <button data-cy='sort-workflow-by-date'
        mat-button
        (click)="toggleDate()">
        Date
        <mat-icon *ngIf='!reverse'>arrow_downward</mat-icon>
        <mat-icon *ngIf='reverse'>arrow_upward</mat-icon>
      </button>
    </div>

  </div>

  <div *ngIf="isFetchingWorkflowHistory | async">
    <mat-progress-bar mode="indeterminate"></mat-progress-bar>
  </div>

  <mat-card class="mat-elevation-z0 md2-outlined"
    fxLayout="column"
    *ngFor="let approvalTask of (workFlowItems | async) | reverseList: reverse; index as i">

    <div fxLayout="row"
      fxLayoutAlign="start center"
      fxLayoutGap="8px">
      <sf-workflow-icon [taskStatus]="approvalTask.status">
      </sf-workflow-icon>

      <div fxLayout="column"
        class="role-name-div">
        <span class="md2-subtitle-1">{{approvalTask.name}}</span>
        <div fxLayout="row"
          class="md2-emphasis-md mat-body-2">
          <span [attr.data-cy]='i + "task-status-history"'>{{approvalTask | formatTaskStatus}}</span>
          <span *ngIf="approvalTask.completedBy"> | <a href="{{psuDirectoryLink}}{{approvalTask.completedBy}}"
              target="_blank">{{approvalTask.completedBy}}</a></span>
        </div>
      </div>
    </div>
    <div *ngIf="(approvalTask.comment)?.length > 0">
      <div class='divider'>
        <mat-divider></mat-divider>
      </div>
      <div fxLayout="start center">
        <mat-icon class="comment-icon md2-emphasis-md">chat</mat-icon>
        <span [attr.data-cy]="approvalTask.comment+'-task-commented-item'"
          class="comment-text">{{approvalTask.comment}} </span>
      </div>
    </div>
  </mat-card>

</div>
