<div class="save-sync-dialog">
  <div fxLayout="row">
    <span mat-dialog-title
      class="body-bold"
      fxFlex>Bulk Unsynched Courses</span>
    <button mat-flat-button
      color="accent"
      class="copy-btn"
      [cdkCopyToClipboard]="courseCopyString()">
      <mat-icon>content_copy</mat-icon><span class="bulk-btn-text">Copy Unsynched</span>
    </button>
  </div>

  <div mat-dialog-content
    fxLayout="row"
    class="content-row"
    *ngFor="let courseInfo of data?.channel?.courses | filterSynchedCourses">
    <div fxLayout="row">
      <mat-icon class="md2-emphasis-md">new_releases</mat-icon>
      <span class="mat-body-2 md2-emphasis-md course-section-id">{{courseInfo.courseDto.sectionId}}</span>
    </div>

  </div>
  <div mat-dialog-actions
    fxLayoutAlign="end end"
    class="sync-button-layout">
    <button mat-stroked-button
      color="primary"
      [mat-dialog-close]="false">Cancel</button>
    <button mat-flat-button
      color="primary"
      [mat-dialog-close]="true"
      data-cy="ok">
      <mat-icon>sync</mat-icon><span>Mark All Synched</span>
    </button>
  </div>

</div>
