import { Component, OnInit, QueryList, ViewChildren } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { MatLegacyTooltip as MatTooltip } from '@angular/material/legacy-tooltip';
import { Store } from '@ngrx/store';
import { generateRoleForm } from '@starfish-access/core';
import { ClientRoleGroup, ClientRoleGroupAndPriority, Role } from '@starfish-access/models';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { take } from 'rxjs/operators';
import { RequestGroups } from '../dynamic-role.actions';
import { DynamicRolesFacade } from '../dynamic-role.facade.service';
import { selectRolesIsFetching } from '../dynamic-role.reducer';
import { RoleDetailsFormComponent } from '../role-details-form/role-details-form.component';

@Component({
  selector: 'sf-edit-role',
  templateUrl: './edit-role.component.html',
  styleUrls: ['./edit-role.component.scss'],
})
export class EditRoleComponent implements OnInit {
  @ViewChildren('roleDetailsFormComponent')
  roleDetailsCompList: QueryList<RoleDetailsFormComponent>;
  filterSubject: Subject<string> = new BehaviorSubject('');
  roleGroupsIsFetching: Observable<boolean>;
  groupMap: Map<string, UntypedFormGroup> = new Map();
  private roleGroups: ClientRoleGroup[];

  constructor(public store: Store<any>, public facade: DynamicRolesFacade, private fb: UntypedFormBuilder) {
    this.roleGroupsIsFetching = this.store.select(selectRolesIsFetching);

    this.facade.roleGroupsWithAdminGroups$.pipe(take(1)).subscribe((res) => (this.roleGroups = res));
  }

  createFormGroup(rolename: string): UntypedFormGroup {
    const singleRoleName = this.groupMap.get(rolename);
    if (this.groupMap.has(rolename) && singleRoleName) {
      return singleRoleName;
    }
    const formGroup: UntypedFormGroup = generateRoleForm(this.fb, this.roleGroups, rolename);
    this.groupMap.set(rolename, formGroup);
    return formGroup;
  }

  ngOnInit(): void {
    this.store.dispatch(new RequestGroups());
  }

  clearEntries(componentName: string) {
    this.roleDetailsCompList.forEach((roleFormComp) => {
      if (roleFormComp.roleToEdit && roleFormComp.roleToEdit.roleName === componentName) {
        roleFormComp.ngOnInit();
        return;
      }
    });
  }

  editExistingRole(formGroup: UntypedFormGroup | null, originalRoleGroup: ClientRoleGroup, roleId: number): void {
    let roleDetailsFormComp: RoleDetailsFormComponent | undefined;
    this.roleDetailsCompList.forEach((roleFormComp) => {
      if (roleFormComp.roleToEdit && roleFormComp.roleToEdit.id === roleId) {
        roleDetailsFormComp = roleFormComp;
      }
    });

    if (!roleDetailsFormComp || !formGroup) {
      return;
    }

    formGroup.markAllAsTouched();
    formGroup.markAsTouched();

    if (formGroup.pending || formGroup.invalid) {
      console.warn('form invalid');
      return;
    }
    if (formGroup) {
      const clientSideRole: Role = formGroup.value;
      const clientGap: ClientRoleGroupAndPriority = {
        clientSideRole,
        roleGroupType: 'EXISTING',
        originalRoleGroup,
      };
      this.facade.updateRoleGroup(clientGap);
    }
  }

  setToolTipMsg(mtt: MatTooltip, roleId: string) {
    this.facade
      .getDelegationNameAndId(roleId)
      .pipe(take(1))
      .subscribe((res) => (mtt.message = res));
  }
}
