import { Component, Inject, Input, OnInit } from '@angular/core';
import { FormatTaskCommentPipe } from '@starfish-access/core';
import { ApprovalTask, ApprovalWorkFlowDisplay, InnerComment, ProcessDetails } from '@starfish-access/models';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { StarfishConfig, STARFISH_CONFIG } from '../../starfish.config';

@Component({
  templateUrl: './workflow.component.html',
  styleUrls: ['./workflow.component.scss'],
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'sf-workflow-history',
})
export class WorkflowHistoryComponent implements OnInit {
  @Input() processDetails: Observable<ProcessDetails>;
  @Input() isFetchingWorkflowHistory: Observable<boolean>;
  workFlowItems: Observable<ApprovalWorkFlowDisplay[]>;
  reverse = true;
  psuDirectoryLink = 'https://www.psu.edu/search/people/';

  constructor(@Inject(STARFISH_CONFIG) private starfishConfig: StarfishConfig) {
    this.psuDirectoryLink = this.starfishConfig.psuDirectoryEntry || 'https://www.psu.edu/search/people/';
  }

  ngOnInit(): void {
    this.workFlowItems = this.processDetails.pipe(
      map((pd) => {
        const combinedList: ApprovalWorkFlowDisplay[] = [];
        for (const comment of pd.processComments) {
          combinedList.push(this.commentToApprovalWorkFlowDisplay(comment));
        }
        for (const task of pd.approvalTasks) {
          combinedList.push(this.taskToApprovalWorkFlowDisplay(task));
        }

        const sortedList: ApprovalWorkFlowDisplay[] = combinedList.sort((n1, n2) => {
          if (n1.timeCompleted > n2.timeCompleted) {
            return 1;
          }
          if (n1.timeCompleted < n2.timeCompleted) {
            return -1;
          }
          return 0;
        });
        return sortedList;
      })
    );
  }

  toggleDate(): void {
    this.reverse = !this.reverse;
  }

  commentToApprovalWorkFlowDisplay(comment: InnerComment): ApprovalWorkFlowDisplay {
    return {
      comment: comment.processComment,
      completedBy: comment.userCommented,
      name: 'Comment Added',
      status: 'New Comment',
      timeCompleted: comment.timeCommented,
    };
  }

  taskToApprovalWorkFlowDisplay(task: ApprovalTask) {
    const pipe = new FormatTaskCommentPipe();
    return {
      comment: pipe.transform(task.taskComment),
      completedBy: task.taskApprovedBy,
      name: task.taskName,
      status: task.taskStatus,
      timeCompleted: task.taskApprovalTime,
    };
  }
}
