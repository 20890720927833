import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatLegacyAutocompleteModule as MatAutocompleteModule } from '@angular/material/legacy-autocomplete';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatLegacyCardModule as MatCardModule } from '@angular/material/legacy-card';
import { MatLegacyChipsModule as MatChipsModule } from '@angular/material/legacy-chips';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import { MatLegacyListModule as MatListModule } from '@angular/material/legacy-list';
import { MatLegacyPaginatorModule as MatPaginatorModule } from '@angular/material/legacy-paginator';
import { MatLegacyProgressBarModule as MatProgressBarModule } from '@angular/material/legacy-progress-bar';
import { MatLegacyProgressSpinnerModule as MatProgressSpinnerModule } from '@angular/material/legacy-progress-spinner';
import { MatLegacySelectModule as MatSelectModule } from '@angular/material/legacy-select';
import { MatSortModule } from '@angular/material/sort';
import { MatLegacyTableModule as MatTableModule } from '@angular/material/legacy-table';
import { MatLegacyTooltipModule as MatTooltipModule } from '@angular/material/legacy-tooltip';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import { NgLetModule } from '@psu/utils/angular';
import { CoreModule, FilterModule, OkayDialogModule } from '@starfish-access/core';
import { TypeaheadModule } from '@starfish-access/shared';
import { AddRoleComponent } from './add-role/add-role.component';
import { FilterComponent } from './filter/filter.component';
import { NewUserComponent } from './new-user/new-user.component';
import { UserManagementComponent } from './usermanagement.component';
import { UserManagementFacade } from './usermanagement.facade';
import { routes } from './usermanagement.routing';
import { UserManagementService } from './usermanagement.service';

@NgModule({
  imports: [
    FormsModule,
    ReactiveFormsModule,
    CommonModule,
    BrowserAnimationsModule,
    MatAutocompleteModule,
    MatButtonModule,
    MatCardModule,
    MatIconModule,
    MatInputModule,
    MatListModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    MatTooltipModule,
    MatTableModule,
    MatSortModule,
    RouterModule.forChild(routes),
    CoreModule,
    FlexLayoutModule,
    FilterModule,
    MatChipsModule,
    MatPaginatorModule,
    MatSelectModule,
    TypeaheadModule,
    NgLetModule,
    OkayDialogModule,
  ],
  declarations: [UserManagementComponent, AddRoleComponent, NewUserComponent, FilterComponent],
  exports: [RouterModule],
  providers: [UserManagementService, UserManagementFacade],
})
export class UserManagementModule {}
