import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatLegacyCardModule as MatCardModule } from '@angular/material/legacy-card';
import { MatLegacyProgressBarModule as MatProgressBarModule } from '@angular/material/legacy-progress-bar';
import { MatLegacyTableModule as MatTableModule } from '@angular/material/legacy-table';
import { RouterModule } from '@angular/router';
import { FilterModule } from '@starfish-access/core';
import { StarfishCommonModule } from '@starfish-access/shared';
import { ApprovalTaskComponent } from './approval-task/approval-task.component';
import { AuditFacade } from './audit.facade';
import { routes } from './audit.routing';

@NgModule({
  imports: [
    CommonModule,
    StarfishCommonModule,
    FilterModule,
    MatCardModule,
    MatTableModule,
    MatProgressBarModule,
    MatButtonModule,
    RouterModule.forChild(routes),
  ],
  declarations: [ApprovalTaskComponent],
  exports: [RouterModule],
  providers: [AuditFacade],
})
export class AuditModule {}
