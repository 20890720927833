<section *ngIf="(canViewDS | async) || (canViewDUS | async); else perms_error">

  <h1>Manage User Roles</h1>

  <mat-tab-group #tabGroup
    (selectedTabChange)="tabChanged($event)">

    <mat-tab *ngIf="canViewDS | async">
      <ng-template mat-tab-label>
        <mat-icon *ngIf="currentlySelectedTab === 0"
          class="selected-tab-icon"
          color="primary">check_circle</mat-icon>
        DATA STEWARDS
      </ng-template>
      <sf-role-display [isFetching]='isFetchingStewards'
        [hasFailed]='hasFailed'
        [canAddUser]='canAddDS'
        [canDeleteUser]='canDeleteDS'
        [userList]='stewardsUsers'
        [matTableColumns]='userRoleColumns'
        displayName="ds"
        (deleteUserEmitter)="confirmStewardDelete($event)"
        (addUserEmitter)="addNewDataSteward($event)"></sf-role-display>
    </mat-tab>

    <mat-tab *ngIf="canViewDUS | async">
      <ng-template mat-tab-label>
        <mat-icon *ngIf="currentlySelectedTab === 1"
          class="selected-tab-icon"
          color="primary">check_circle</mat-icon>
        DUS
      </ng-template>
      <sf-role-display [isFetching]='isFetchingDus'
        [hasFailed]='hasFailed'
        [canAddUser]='canAddDUS'
        [canDeleteUser]='canDeleteDUS'
        [userList]='dusUsers'
        [matTableColumns]='userRoleColumns'
        displayName="dus"
        (deleteUserEmitter)="confirmDusDelete($event)"
        (addUserEmitter)="addNewDus($event)"></sf-role-display>
    </mat-tab>
  </mat-tab-group>

</section>

<ng-template #perms_error>
  <div class="failed-styling">
    You do not have access to this content.
  </div>
</ng-template>
