import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatLegacyAutocompleteModule as MatAutocompleteModule } from '@angular/material/legacy-autocomplete';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatLegacyCardModule as MatCardModule } from '@angular/material/legacy-card';
import { MatLegacyFormFieldModule as MatFormFieldModule } from '@angular/material/legacy-form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import { MatLegacyProgressBarModule as MatProgressBarModule } from '@angular/material/legacy-progress-bar';
import { MatLegacyProgressSpinnerModule as MatProgressSpinnerModule } from '@angular/material/legacy-progress-spinner';
import { MatSortModule } from '@angular/material/sort';
import { MatLegacyTableModule as MatTableModule } from '@angular/material/legacy-table';
import { MatLegacyTabsModule as MatTabsModule } from '@angular/material/legacy-tabs';
import { MatLegacyTooltipModule as MatTooltipModule } from '@angular/material/legacy-tooltip';
import { RouterModule } from '@angular/router';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { ResponsiveModule } from '@psu/utils/responsive';
import { CoreModule } from '@starfish-access/core';
import { TypeaheadModule } from '@starfish-access/shared';
import { DUSGroupService } from './dusGroup.service';
import { RoleDisplayComponent } from './roledisplay/roledisplay.component';
import { RolesComponent } from './roles.component';
import { RolesEffects } from './roles.effects';
import { rolesListReducer } from './roles.reducer';
import { routes } from './roles.routing';
import { RoleTableComponent } from './roletable/roletable.component';
import { UserSearchComponent } from './usersearch/user-search.component';

@NgModule({
  imports: [
    CommonModule,
    MatAutocompleteModule,
    MatButtonModule,
    MatCardModule,
    MatIconModule,
    MatInputModule,
    MatFormFieldModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    MatTabsModule,
    MatTooltipModule,
    RouterModule.forChild(routes),
    FormsModule,
    ReactiveFormsModule,
    CoreModule,
    MatTableModule,
    MatSortModule,
    ResponsiveModule,
    FlexLayoutModule,
    TypeaheadModule,
    StoreModule.forFeature('rolesList', rolesListReducer),
    EffectsModule.forFeature([RolesEffects]),
  ],
  declarations: [RolesComponent, RoleTableComponent, UserSearchComponent, RoleDisplayComponent],
  exports: [RouterModule],
  providers: [DUSGroupService],
})
export class RolesModule {}
