<section *ngIf="canView | async; else perms_error">
  <mat-card class="result card-table">
    <h1>Failed Jobs Manager</h1>
    <mat-card-content>
      <div>

        <span *ngIf='isFetching && !hasFailed'>
          <mat-progress-bar mode="indeterminate"></mat-progress-bar>
        </span>

        <span *ngIf='isFetching && hasFailed'>
          We couldn't find your failed jobs. Please try again later, or contact an admin for help.
        </span>

        <span *ngIf='!isFetching && (failedJobsList | async)?.length < 1'>
          <br>
          <p fxLayoutAlign="center center">
            There are no failed jobs.
          </p>
        </span>

        <span *ngIf='!isFetching && (failedJobsList | async)?.length > 0'
          fxLayout="column">
          <mat-form-field class="starfish-filter"
            fxFlex>
            <input matInput
              (keyup)="applyFilter($event.target.value)"
              placeholder="Filter">
          </mat-form-field>
          <table mat-table
            [dataSource]="dataSource"
            class="mat-elevation-z0 starfish-data-table">

            <ng-container matColumnDef="jobid">
              <th mat-header-cell
                *matHeaderCellDef> Job ID </th>
              <td mat-cell
                *matCellDef="let element"> {{element.jobId}} </td>
            </ng-container>

            <ng-container matColumnDef="processid">
              <th mat-header-cell
                *matHeaderCellDef> Process ID </th>
              <td mat-cell
                *matCellDef="let element"> {{element.processInstanceId}} </td>
            </ng-container>

            <ng-container matColumnDef="exception">
              <th mat-header-cell
                *matHeaderCellDef> Exception Message </th>
              <td mat-cell
                *matCellDef="let element"
                matTooltip="Click for Details"
                [matTooltipShowDelay]=tooltipDelay
                class="hand-pointer"
                (click)="showExceptionDetails('Exception Message Details', element.exceptionMessage)"> {{element.exceptionMessage}} </td>
            </ng-container>

            <ng-container matColumnDef="failedtime">
              <th mat-header-cell
                *matHeaderCellDef> Failed Time </th>
              <td mat-cell
                *matCellDef="let element"> {{element.failedTime | starfishDatePipe}} </td>
            </ng-container>

            <ng-container matColumnDef="retry">
              <th mat-header-cell
                *matHeaderCellDef> Retry Job </th>
              <td mat-cell
                *matCellDef="let element">
                <span *ngIf=!isSendingRetry(element.jobId)>
                  <button *ngIf="canRetry | async"
                    (click)="retryProcess(element)"
                    color=primary
                    matTooltip="Retry Job"
                    [matTooltipShowDelay]=tooltipDelay
                    mat-button>
                    <mat-icon>replay</mat-icon>
                  </button>
                </span>
                <span *ngIf='isSendingRetry(element.jobId)'>
                  <mat-progress-bar mode="indeterminate"
                    color=accent></mat-progress-bar>
                </span>
              </td>
            </ng-container>

            <ng-container matColumnDef="delete">
              <th mat-header-cell
                *matHeaderCellDef> Delete Job </th>
              <td mat-cell
                *matCellDef="let element">
                <span *ngIf=!hasFailed>
                  <a *ngIf="canDelete | async"
                    (click)="deleteJob(element)"><button matTooltip="Delete Job"
                      color=warn
                      [matTooltipShowDelay]=tooltipDelay
                      mat-button>
                      <mat-icon>delete</mat-icon>
                    </button></a>
                </span>

                <span *ngIf=hasFailed>
                  <i matTooltip="Operation Failed"
                    [matTooltipShowDelay]=tooltipDelay
                    class="material-icons">report_problem</i>
                </span>
              </td>
            </ng-container>

            <tr mat-header-row
              *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row
              *matRowDef="let row; columns: displayedColumns;"></tr>
          </table>
          <mat-paginator class="starfish-paginator"
            [pageSizeOptions]="[10, 20]"
            showFirstLastButtons></mat-paginator>
        </span>
      </div>
    </mat-card-content>
  </mat-card>
</section>
<ng-template #perms_error>
  <div class="failed-styling">
    You do not have access to this content.
  </div>
</ng-template>
