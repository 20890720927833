<section
  utResponsive
  class="sf-dashboard-wrapper">
  <mat-card class="mat-elevation-z0 dashboard-card">
    <mat-card-content
      fxLayout="column"
      fxLayoutAlign="center center">
      <h1
        fxFlex
        fxLayoutAlign="center center">
        Welcome to Starfish Role Management
      </h1>
      <p
        mat-line
        class="welcome-text"
        data-cy="logged-in-welcome-msg"
        *ngIf="isLoggedIn">
        <b>Starfish</b> is Penn State’s main system for connecting students to academic advisers, support professionals,
        and academic resources and for facilitating collaboration among those who support student academic success.
      </p>
      <p
        mat-line
        class="welcome-text"
        data-cy="logged-in-welcome-msg"
        *ngIf="isLoggedIn">
        <b>Starfish Role Management (SRM)</b> catalogs the many available Starfish roles. Local role managers use the
        SRM to assign roles to users within their areas, thereby maintaining appropriate levels of access to Starfish
        records and functionality.
      </p>

      <span *ngIf="!isLoggedIn">
        <p data-cy="not-logged-in-msg">Log in to access Starfish role request features.</p>
        <br />
        <p fxLayoutAlign="center center">
          <button
            type="button"
            mat-flat-button
            (click)="authService.initLoginFlow()"
            color="primary">
            Log In
          </button>
        </p>
      </span>

      <span *ngIf="isLoggedIn && !hasFerpaTraining">
        <p data-cy="ferpa-training-error-message">
          FERPA Training is required for access. Please complete the FERPA training modules.
        </p>
        <br />
        <p fxLayoutAlign="center center">
          <a
            href="http://www.registrar.psu.edu/staff/ferpa_tutorial/ferpa_tutorial.cfm"
            mat-raised-button
            color="primary"
            target="_BLANK">
            <mat-icon>assignment_late</mat-icon>&nbsp;FERPA Training Modules
          </a>
        </p>
      </span>
    </mat-card-content>
  </mat-card>

  <mat-card
    *ngIf="isLoggedIn && canManageDelegations"
    class="mat-elevation-z0 dashboard-card"
    data-cy="area-directors-card">
    <mat-card-content
      fxLayout="column"
      fxLayoutAlign="left left">
      <h2 fxFlex>Manage Starfish Roles for My Area</h2>
      <span>
        <button
          mat-raised-button
          routerLink="area-directors"
          class="dashboard-main-btn dashboard-tasklist-btn"
          data-cy="area-directors">
          Manage Role Assignments
        </button>
      </span>
    </mat-card-content>
  </mat-card>

  <mat-card
    *ngIf="isLoggedIn"
    class="mat-elevation-z0 assigned-roles dashboard-card"
    data-cy="assigned-roles-card">
    <h2>My Starfish Roles (click one to read more about the role)</h2>
    <div>
      <div *ngIf="dashboardFacade.loadingMyRoles$ | async">
        <span data-cy="loading-roles-text">We're checking to see what roles you have</span>
        <mat-progress-bar mode="indeterminate"></mat-progress-bar>
      </div>
      <button
        utResponsive
        *ngFor="let role of dashboardFacade.myRoles$ | async"
        mat-flat-button
        (click)="showAssignedRoleDialog(role)"
        [attr.data-cy]="'user-role-' + role.requestedRole"
        class="role-button blue">
        {{ role.requestedRole }}
      </button>

      <span
        *ngIf="(dashboardFacade.myRoles$ | async)?.length === 0"
        data-cy="no-roles-text">
        You currently do not have any roles assigned. You can check this page to see which ones you may need and how
        apply for them.
      </span>
    </div>
  </mat-card>

  <div #scrollTarget></div>
  <mat-card
    *ngIf="isLoggedIn"
    class="mat-elevation-z0 dashboard-card"
    data-cy="role-information-card">
    <h1>Starfish Role Categories</h1>
    <p>
      Click on a chip to see the associated Starfish role group, then click on a role to see more details. Additional
      information about Starfish roles, including creating a new role, is on the
      <a
        href="https://sites.psu.edu/starfishinfo/users/other-roles/"
        target="_blank"
        >Starfish Info website.</a
      >
    </p>
    <div *ngIf="dashboardFacade.loadingDelegations$ | async">
      <h2>Loading Role Groups...</h2>
      <mat-progress-bar
        data-cy="save-role-progress-bar"
        mode="indeterminate"></mat-progress-bar>
    </div>
    <button
      utResponsive
      *ngFor="let group of allRoleGroups$ | async"
      mat-flat-button
      (click)="scrollToRoleGroup(group.name)"
      [attr.data-cy]="group.name + 'role-info-panel'"
      class="role-button blue">
      {{ group.name }}
    </button>
  </mat-card>

  <div *ngIf="isLoggedIn">
    <mat-card
      class="mat-elevation-z0 dashboard-card"
      data-cy="roles-filter">
      <h2>Search for a Starfish Role</h2>
      <sf-account-search (onSearch)="filterRoleGroupsSubject.next($event)"></sf-account-search>
    </mat-card>
    <mat-card *ngIf="dashboardFacade.loadingDelegations$ | async">
      <h2>Loading Roles...</h2>
      <mat-progress-bar
        data-cy="save-role-progress-bar"
        mode="indeterminate"></mat-progress-bar>
    </mat-card>

    <div *ngIf="{ filteredRoleGroups: filteredRoleGroups$ | async } as asyncValues">
      <mat-card
        *ngIf="asyncValues.filteredRoleGroups?.length === 0"
        class="mat-elevation-z0 dashboard-card"
        data-cy="no-filter-results">
        <h3>No results found</h3>
      </mat-card>

      <mat-card
        class="mat-elevation-z0 dashboard-card group-section"
        *ngFor="let group of asyncValues.filteredRoleGroups"
        [attr.data-cy]="group.name + 'main panel'">
        <h2
          class="scroll-top"
          #roleGroupList
          [attr.id]="group.name">
          Roles for {{ group.name }}
        </h2>
        <p>{{ group.description }}</p>

        <mat-accordion>
          <mat-expansion-panel
            hideToggle
            #matRolePanel
            [attr.id]="singleRole?.roleName"
            *ngFor="let singleRole of group.roles; index as i"
            [attr.data-cy]="singleRole?.roleName + 'expansion panel'"
            class="exp-panel-class">
            <mat-expansion-panel-header class="exp-panel-header">
              <mat-panel-title class="mat-panel-title">
                <sf-role-icon
                  [iconName]="group.iconImage"
                  [iconColor]="group.iconColor"
                  [isOpen]="matRolePanel.expanded"
                  [roleName]="singleRole.roleName"></sf-role-icon>

                <div class="role-name-div">
                  <span
                    utResponsive
                    class="mat-subtitle-1 role-name"
                    [attr.id]="singleRole.roleName"
                    [ngClass]="{ 'accordian-selected': matRolePanel.expanded }">
                    {{ singleRole.roleName }}
                  </span>
                </div>
              </mat-panel-title>
              <mat-panel-description>
                <span></span>
                <mat-icon
                  mat-list-icon
                  svgIcon="{{ matRolePanel.expanded ? 'chevron-up' : 'chevron-down' }}"></mat-icon>
              </mat-panel-description>
            </mat-expansion-panel-header>

            <ng-template matExpansionPanelContent>
              <ul data-cy="role-details-block">
                <li *ngFor="let bulletPoint of singleRole.descriptions">{{ bulletPoint }}</li>
              </ul>
            </ng-template>
          </mat-expansion-panel>
        </mat-accordion>
      </mat-card>
    </div>
    <sf-scroll-to-top
      utResponsive
      *ngIf="showScrollButton"
      class="scroll-to-top-button"
      data-cy="scroll-to-top-btn"
      [scrollTarget]="scrollTarget"></sf-scroll-to-top>
  </div>
</section>
