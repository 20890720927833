import { Injectable } from '@angular/core';
import { PermissionCheckService } from '@psu/apis/fortress';
import {
  ConfirmDialogService,
  DialogService,
  KioskPermissions,
  KioskService,
  OkayDialogService,
  SvcErrorDialogService,
} from '@starfish-access/core';
import { GenericServiceDeletionStatus, Kiosk } from '@starfish-access/models';
import { findIndex, prepend, propEq, reject, update } from 'ramda';
import { BehaviorSubject, Observable, of, ReplaySubject } from 'rxjs';
import { catchError, map, mergeMap, take } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class KiosksFacade {
  readonly canView$ = this.permissionCheckService.hasPermission(KioskPermissions.VIEW);
  readonly canAdd$ = this.permissionCheckService.hasPermission(KioskPermissions.ADD);
  readonly canEdit$ = this.permissionCheckService.hasPermission(KioskPermissions.EDIT);
  readonly canDelete$ = this.permissionCheckService.hasPermission(KioskPermissions.DELETE);

  readonly hasFailed$ = new ReplaySubject<boolean>();
  readonly isLoadingData$ = new ReplaySubject<boolean>();
  private kioskData$ = new BehaviorSubject<Kiosk[]>([]);
  private originalDataArray: Kiosk[];

  constructor(
    private permissionCheckService: PermissionCheckService,
    private dialogService: DialogService,
    private confirmDialogService: ConfirmDialogService,
    private kioskService: KioskService,
    private okayDialogService: OkayDialogService,
    private errorDialog: SvcErrorDialogService
  ) {
    this.kioskService
      .getKiosks()
      .pipe(take(1))
      .subscribe((res) => {
        this.originalDataArray = res;
        this.kioskData$.next(res);
      });
  }

  kioskList(): Observable<Kiosk[]> {
    return this.kioskData$.asObservable();
  }

  addNewServiceOrg(): void {
    this.dialogService
      .serviceDialog({
        title: 'Create a New Kiosk',
        saveBtnText: 'Create',
        requiredName: 'kiosk',
      })
      .pipe(
        take(1),
        mergeMap((res) => {
          if ((res as any) !== '') {
            return this.kioskService.createKiosk(res);
          }
          return of(undefined);
        }),
        catchError(() => of(null))
      )
      .subscribe((res: Kiosk | null) => {
        if (typeof res !== 'string' && res) {
          this.updateList(res);
        } else if (res === null) {
          // we have an error
          this.okayDialogService.okay(
            'Failed to Create Kiosk',
            'We could not create the kiosk at this time. You can try again or contact starfish@psu.edu for assistance.'
          );
        }
      });
  }

  deleteExistingKiosk(kioskId: string) {
    this.confirmDialogService
      .confirmMessage({
        title: 'Delete Kiosk',
        message: 'Are you sure? Deleting this Kiosk cannot be undone',
      })
      .subscribe((confirm) => {
        if (confirm) {
          this.kioskService
            .deleteKiosk(kioskId)
            .pipe(
              take(1),
              map((res) => ({ successfulDeletion: true })),
              catchError((e) => {
                const errorResponse: GenericServiceDeletionStatus = {
                  successfulDeletion: false,
                  errorCode: e.errorMessage,
                  serverResponseCode: e.status,
                  conflictError: e.error,
                  type: 'KIOSK',
                };
                return of(errorResponse);
              })
            )
            .subscribe((res: GenericServiceDeletionStatus) => {
              if (!res.successfulDeletion && res.serverResponseCode === 409) {
                this.errorDialog.errorDisplay(res);
              } else if (!res.successfulDeletion) {
                this.okayDialogService.okay('Failed to Delete Kiosk', 'We could not delete this kiosk at this time.');
              } else {
                this.deleteFromList(kioskId);
              }
            });
        }
      });
  }

  editExistingKiosk(service: Kiosk): void {
    this.dialogService
      .serviceDialog({
        title: 'Modify this Existing Kiosk',
        service,
        disableNameInput: true,
        saveBtnText: 'Save',
        requiredName: 'kiosk',
      })
      .pipe(
        take(1),

        mergeMap((res) => {
          if (res) {
            return this.kioskService.updateKiosk(res);
          }
          return of(undefined);
        }),
        catchError(() => of(null))
      )
      .subscribe((res) => {
        if (typeof res !== 'string' && res) {
          this.editList(res);
        } else if (res === null) {
          // we have an error
          this.okayDialogService.okay(
            'Failed to Edit Kiosk',
            'We could not edit the kiosk at this time. You can try again or contact starfish@psu.edu for assistance.'
          );
        }
      });
  }

  private updateList(kiosk: Kiosk) {
    this.originalDataArray = prepend(kiosk, this.originalDataArray);
    this.kioskData$.next(this.originalDataArray);
  }

  private deleteFromList(kioskId: string) {
    const isKioskToRemove = (k: Kiosk) => k.id === kioskId;
    this.originalDataArray = reject(isKioskToRemove, this.originalDataArray);
    this.kioskData$.next(this.originalDataArray);
  }

  private editList(kiosk: Kiosk) {
    const index: number = findIndex(propEq('id', kiosk.id))(this.originalDataArray);
    this.originalDataArray = update(index, kiosk, this.originalDataArray);
    this.kioskData$.next(this.originalDataArray);
  }
}
