<section *ngIf="facade.canView$ | async; else perms_error">
  <mat-card class="result card-table">
    <h1>Available Starfish Services</h1>
    <mat-card-content>
      <div>
        <span class="starfish-welcome"> A Starfish Service is an office visible to students and other users.</span>
        <br /><br />

        <span *ngIf="(facade.isLoadingData$ | async) && !(facade.hasFailed$ | async)">
          <br />
          <mat-progress-bar mode="indeterminate"></mat-progress-bar>
        </span>

        <span
          *ngIf="hasFailed | async"
          class="starfish-welcome">
          We couldn't load Services. Please try again later, or contact an admin for help.
        </span>

        <div *ngIf="!(isLoadingData | async) && !(hasFailed | async)">
          <div fxLayout="row">
            <mat-form-field
              class="starfish-filter"
              fxFlex>
              <input
                matInput
                data-cy="services-search-filter"
                (keyup)="applyFilter($event.target.value)"
                placeholder="Filter" />
            </mat-form-field>
            <button
              data-cy="new-service-btn"
              class="add-new-contained-btn"
              *ngIf="facade.canAdd$ | async"
              mat-raised-button
              color="accent"
              (click)="facade.addNewService()">
              <mat-icon>add</mat-icon>New Service
            </button>
          </div>
          <table
            mat-table
            [dataSource]="dataSource"
            matSort
            class="mat-elevation-z0 starfish-data-table">
            <ng-container matColumnDef="name">
              <th
                mat-header-cell
                *matHeaderCellDef
                mat-sort-header>
                Service Name
              </th>
              <td
                mat-cell
                *matCellDef="let element"
                (click)="facade.editExistingService(element)"
                class="hand-pointer">
                <span
                  *ngIf="canEdit | async"
                  matTooltip="Edit this Service"
                  [matTooltipShowDelay]="tooltipDelay"
                  >{{ element.name }}</span
                >
                <span *ngIf="!(canEdit | async)">{{ element.name }}</span>
              </td>
            </ng-container>

            <ng-container matColumnDef="id">
              <th
                mat-header-cell
                *matHeaderCellDef>
                Service ID
              </th>
              <td
                mat-cell
                *matCellDef="let element"
                (click)="facade.editExistingService(element)"
                class="hand-pointer">
                {{ element.id }}
              </td>
            </ng-container>

            <ng-container matColumnDef="delete">
              <th
                mat-header-cell
                *matHeaderCellDef></th>
              <td
                mat-cell
                *matCellDef="let element">
                <a
                  *ngIf="(facade.canDelete$ | async) && element.id && !isActivelyDeleting(element.id)"
                  (click)="facade.deleteExistingService(element.id)"
                  ><button
                    mat-button
                    color="warn">
                    <mat-icon>delete</mat-icon>
                  </button></a
                >
                <mat-spinner
                  *ngIf="element.id &amp;&amp; isActivelyDeleting(element.id)"
                  diameter="25"
                  color="accent"></mat-spinner>
              </td>
            </ng-container>

            <tr
              mat-header-row
              *matHeaderRowDef="displayedColumns"></tr>
            <tr
              mat-row
              *matRowDef="let row; columns: displayedColumns"></tr>
          </table>
          <mat-paginator
            class="starfish-paginator"
            showFirstLastButtons></mat-paginator>
        </div>
      </div>
    </mat-card-content>
  </mat-card>
</section>

<ng-template #perms_error>
  <div class="failed-styling">You do not have access to this content.</div>
</ng-template>
