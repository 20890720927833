import { Component, OnDestroy } from '@angular/core';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from '@psu/utils/security';
import { ConfirmDialogService } from '@starfish-access/core';
import { ClientAuditTask } from '@starfish-access/models';
import { BehaviorSubject, combineLatest, of, Subject } from 'rxjs';
import { catchError, finalize, map, mergeMap, takeUntil } from 'rxjs/operators';
import { AuditFacade } from '../audit.facade';

@Component({
  selector: 'sf-approval-task',
  templateUrl: './approval-task.component.html',
  styleUrls: ['./approval-task.component.scss'],
})
export class ApprovalTaskComponent implements OnDestroy {
  taskId: string;
  displayedColumns: string[] = ['userDisplayName', 'userId', 'roleName', 'approve', 'remove'];
  dataSource = new MatTableDataSource<ClientAuditTask>();
  isLoggedInUsersTask = new BehaviorSubject<boolean>(true);
  isLoadingTaskDetails = new BehaviorSubject<boolean>(false);
  private destroy$ = new Subject<void>();

  constructor(
    private facade: AuditFacade,
    private dialog: ConfirmDialogService,
    private route: ActivatedRoute,
    public router: Router,
    private authService: AuthService
  ) {
    this.route.params.pipe(takeUntil(this.destroy$)).subscribe((params) => {
      this.taskId = params.taskId;
    });

    const userFromToken = this.authService.getCurrentUser();

    combineLatest([this.route.params])
      .pipe(
        takeUntil(this.destroy$),
        mergeMap(([params]) => {
          this.isLoadingTaskDetails.next(true);
          const parameterizedTaskId = params.taskId;
          const loggedInUser: string = userFromToken !== null ? userFromToken.userName : '';
          return this.facade.getAuditTaskDetails(parameterizedTaskId).pipe(
            map((res) => {
              // iff taskname is self auditing and the user id is the currently logged in user
              const b: boolean = res.taskName === 'Self Auditing' ? res.userId === loggedInUser : true;
              this.isLoggedInUsersTask.next(b);
              return res;
            }),
            finalize(() => this.isLoadingTaskDetails.next(false))
          );
        })
      )
      .subscribe((result) => {
        this.dataSource.data = [result];
      });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  approveConfirmation(roleName: string): void {
    this.dialog
      .confirmMessage({
        title: 'Approve this Assignment',
        message: 'Are you sure? This will request re-approval for the ' + roleName + ' role in Starfish.',
      })
      .pipe(
        takeUntil(this.destroy$),
        mergeMap((res) => {
          if (res) {
            this.isLoadingTaskDetails.next(true);
            return this.facade.updateRoleAudit('KEEP', this.taskId);
          }
          return of(false);
        }),
        finalize(() => this.isLoadingTaskDetails.next(false)),
        catchError(() =>
          this.dialog.confirmMessage({
            title: 'Approval Failure',
            message: 'We could not remove the role at this time.',
            cancelLabel: 'Stay on this Page',
            confirmLabel: 'Return to Task List Page',
          })
        )
      )
      .subscribe((res) => {
        if (res) {
          this.router.navigate(['/tasklist']);
        }
      });
  }

  removeConfirmation(roleName: string): void {
    this.dialog
      .confirmMessage({
        title: 'Remove this Assignment',
        message: 'Are you sure? This will remove ' + roleName + ' from your assignments in Starfish.',
      })
      .pipe(
        takeUntil(this.destroy$),
        mergeMap((res) => {
          if (res) {
            this.isLoadingTaskDetails.next(true);
            return this.facade.updateRoleAudit('REMOVE', this.taskId);
          }
          return of(false);
        }),
        finalize(() => this.isLoadingTaskDetails.next(false)),
        catchError(() =>
          this.dialog.confirmMessage({
            title: 'Removal Failure',
            message: 'We could not remove the role at this time.',
            cancelLabel: 'Stay on this Page',
            confirmLabel: 'Return to Task List Page',
          })
        )
      )
      .subscribe((res) => {
        if (res) {
          this.router.navigate(['/tasklist']);
        }
      });
  }
}
