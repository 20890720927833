<div class="assigned-role-dialog-container">
  <div class="dialog-header">
    <span
      class="role-icon"
      [ngClass]="data.roleDetails.roleGroupIconColor">
      <mat-icon [svgIcon]="data.roleDetails.roleGroupIconImage"></mat-icon>
    </span>
    <h1 mat-dialog-title>{{ data.roleDetails.requestedRole }}</h1>
  </div>

  <mat-dialog-content>
    <ul data-cy="role-descriptions-list">
      <li *ngFor="let bulletPoint of data.role.descriptions">
        {{ bulletPoint }}
      </li>
    </ul>
  </mat-dialog-content>
  <mat-dialog-actions>
    <button
      class="close-button"
      mat-flat-button
      color="primary"
      (click)="onAssignedRoleDialogClose()">
      Close
    </button>
  </mat-dialog-actions>
</div>
