import { CommonModule } from '@angular/common';
import { Component, Inject } from '@angular/core';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogModule as MatDialogModule, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { MatIconModule } from '@angular/material/icon';
import { StarfishCommonModule } from '@starfish-access/shared';
import { Role, RoleDetailsExtended } from '../../model/roles.model';

@Component({
  selector: 'sf-assigned-role-dialog',
  standalone: true,
  templateUrl: './sf-assigned-role-dialog.component.html',
  styleUrls: ['./sf-assigned-role-dialog.component.scss'],
  imports: [CommonModule, MatButtonModule, MatDialogModule, MatIconModule, StarfishCommonModule],
})
export class AssignedRoleDialogComponent {
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { roleDetails: RoleDetailsExtended; role: Role },
    public dialogRef: MatDialogRef<AssignedRoleDialogComponent>
  ) {}

  onAssignedRoleDialogClose() {
    this.dialogRef.close();
  }
}
