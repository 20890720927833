<div class="app-component-main">
  <header>
    <mat-toolbar
      color="primary"
      class="mat-elevation-z4 header toolbar-header">
      <div
        fxLayout="row"
        fxLayoutAlign="start center"
        fxFlex>
        <a
          title="Penn State University home page"
          *ngIf="!mediaXs"
          class="psu-mark"
          target="_BLANK"
          rel="noopener"
          [href]="psuLink"
          ><img
            fxflex
            [src]="psuHorizontalLogo"
            alt="Penn State University home page"
        /></a>

        <a
          title="Penn State University home page"
          *ngIf="mediaXs"
          class="toolbar-image"
          target="_BLANK"
          rel="noopener"
          [href]="psuLink"
          ><img
            fxflex
            [src]="psuSquareLogo"
            alt="Penn State University home page"
        /></a>

        <div
          class="starfish-name-heading"
          utResponsive>
          <span
            class="starfish-name-text"
            utResponsive
            >Starfish Role Management</span
          >
        </div>

        <div
          fxFlex="auto"
          aria-hidden="true">
          &nbsp;
        </div>
        <div fxFlex="none">
          <button
            mat-icon-button
            *ngIf="this.isLoggedIn"
            (click)="sidenav.toggle()">
            <mat-icon>menu</mat-icon>
          </button>
        </div>
      </div>
    </mat-toolbar>
  </header>

  <div
    class="name-tag body-bold mat-elevation-z4"
    *ngIf="authService.getCurrentUser()?.displayName?.length > 0 && !starfishConfig?.turnOffUserDisplayNameTag">
    <span>{{ authService.getCurrentUser()?.displayName }}</span>
  </div>

  <mat-sidenav-container fullscreen>
    <mat-sidenav
      [opened]="!mediaXs"
      class="my-sidenav mat-elevation-z4"
      #sidenav
      [mode]="mediaXs ? 'over' : 'side'"
      [opened]="menuSubject | async"
      position="end">
      <psu-nav-list (sfSelect)="shouldCloseMenu(mediaXs)"></psu-nav-list>
    </mat-sidenav>

    <div
      fxFill
      fxLayout="column">
      <main
        class="content-area mat-typography"
        fxFlex
        role="main"
        fxLayout="column"
        fxLayoutAlign="space-between"
        #mainContainer>
        <div
          class="main-page-content"
          utResponsive>
          <router-outlet></router-outlet>
        </div>
        <div
          fxFlex="auto"
          aria-hidden="true">
          &nbsp;
        </div>
        <sf-mat-footer
          [logoLarge]="psuHorizontalLogo"
          [logoMobile]="psuSquareLogo"
          [year]="2022"></sf-mat-footer>
      </main>
    </div>
  </mat-sidenav-container>
  <uic-version-banner
    [environmentLabel]="serverEnv"
    [appVersion]="version"></uic-version-banner>
</div>
