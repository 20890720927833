import { Component, forwardRef, Input, OnDestroy, OnInit } from '@angular/core';
import {
  ControlValueAccessor,
  UntypedFormArray,
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  NG_VALUE_ACCESSOR,
} from '@angular/forms';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { OkayDialogService } from '@starfish-access/core';
import { UserInfo } from '@starfish-access/models';
import { Subject } from 'rxjs';
import { ConfiguredDelegation } from '../../../core/model/delegation.model';
import { FindCalendarMgrComponent } from './find-calendar-mgr/find-calendar-mgr.component';

export interface CalMgrs {
  name: string;
  userid: string;
}

@Component({
  selector: 'sf-directors-calendar-mgr',
  templateUrl: './calendar-mgr.component.html',
  styleUrls: ['./calendar-mgr.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CalendarMgrComponent),
      multi: true,
    },
  ],
})
export class CalendarMgrComponent implements OnInit, OnDestroy, ControlValueAccessor {
  @Input() configuredDelegation: ConfiguredDelegation;
  @Input() calendarTitle = 'Regular Calendar Managers';
  @Input() additionalDetail = '';
  formGroup: UntypedFormGroup;
  displayedColumns: string[] = ['name', 'userid', 'delete'];
  dataSource: MatTableDataSource<UserInfo> = new MatTableDataSource([]);
  private destroy$ = new Subject<void>();

  constructor(private fb: UntypedFormBuilder, private dialog: MatDialog, public okayDialogService: OkayDialogService) {
    this.formGroup = fb.group({
      managers: this.fb.array([]),
    });

    this.formGroup.valueChanges.subscribe((value) => {
      this.propagateChange(value);
    });
  }

  writeValue(obj: UserInfo[]): void {
    const managers: UntypedFormArray = this.formGroup.get('managers') as UntypedFormArray;
    if (obj) {
      obj.forEach((element) => {
        managers.push(new UntypedFormControl(element));
      });
      this.dataSource = new MatTableDataSource(managers.value);
    }
  }

  registerOnChange(fn: any): void {
    this.propagateChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.propagateTouch = fn;
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  ngOnInit(): void {
    const managers: UntypedFormArray = this.formGroup.get('managers') as UntypedFormArray;
    this.dataSource = new MatTableDataSource(managers.value);
  }

  removeCalendarMgr(index: number) {
    const managers: UntypedFormArray = this.formGroup.get('managers') as UntypedFormArray;
    managers.removeAt(index);
    this.dataSource = new MatTableDataSource(managers.value);
  }

  addCalMgr(): void {
    const managers: UntypedFormArray = this.formGroup.get('managers') as UntypedFormArray;

    const dialogRef = this.dialog.open(FindCalendarMgrComponent, {
      width: '850px',
      disableClose: true,
    });

    dialogRef.afterClosed().subscribe((result: UserInfo[]) => {
      if (result) {
        result.forEach((element) => {
          managers.push(new UntypedFormControl(element));
        });
        this.dataSource = new MatTableDataSource(managers.value);
      }
    });
  }

  private propagateChange: (_: any) => any = (_: any) => ({});
  private propagateTouch: (_: any) => any = (_: any) => ({});
}
