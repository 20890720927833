import { AfterViewInit, Component, forwardRef, Input, ViewChild } from '@angular/core';
import {
  AbstractControl,
  AbstractControlOptions,
  ControlValueAccessor,
  FormGroupDirective,
  NgForm,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  ValidationErrors,
  Validator,
} from '@angular/forms';
import { ErrorStateMatcher } from '@angular/material/core';
import { OrgModel, OrgSelectionType, Relationships, RelationshipType } from '@starfish-access/models';
import { OrgSelectComponent, OrgSelectItem } from '@starfish-access/shared';
import { isEmpty } from 'ramda';
import { ClientCourseSelectionType } from '../../../core/model/relationships.model';
import { OrgsFacade } from './orgs.facade';

export const orgSelectItemToOrgModel = (input: OrgSelectItem): OrgModel => ({
  code: input.id,
  name: input.orgName,
});

export const orgModelToOrgSelectItem = (input: OrgModel): OrgSelectItem => ({
  displayName: `${input.name} [${input.code}]`,
  id: input.code,
  orgName: input.name,
});

/**
 * This tells the typeahead component's input when to be invalid so it can display the
 * error state correctly.  Create a new one of these for each typeahead, services and kiosks,
 * and links the validity of the internal FormGroup's control to the typeahead input.
 */
export class StateMatcher implements ErrorStateMatcher {
  constructor(private orgList: AbstractControl | null, private relationshipType: AbstractControl | null) {}
  isErrorState(_a: UntypedFormControl | null, _b: FormGroupDirective | NgForm | null): boolean {
    const relationshipTypeValue: RelationshipType = this.relationshipType ? this.relationshipType.value : undefined;
    return !!(relationshipTypeValue === 'ORGANIZATION' && this.orgList && this.orgList.errors && this.orgList.touched);
  }
}

export class ImmediateStateMatcher implements ErrorStateMatcher {
  constructor(private orgList: AbstractControl | null, private _relationshipType: AbstractControl | null) {}
  isErrorState(_a: UntypedFormControl | null, _b: FormGroupDirective | NgForm | null): boolean {
    return !!(this.orgList && this.orgList.errors);
  }
}

interface FormGroupModel {
  relationshipType: RelationshipType;
  orgSelectionType: OrgSelectionType | undefined;
  deferOrgSelection: boolean;
  orgList: OrgModel[];
  courseType: ClientCourseSelectionType;
}

@Component({
  selector: 'sf-dynamic-role-orgs',
  templateUrl: './orgs.component.html',
  styleUrls: ['./orgs.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => OrgsComponent),
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => OrgsComponent),
      multi: true,
    },
  ],
})
export class OrgsComponent implements ControlValueAccessor, Validator, AfterViewInit {
  @Input() formControl: UntypedFormControl;
  @Input() userRequestedSelected = false;
  @Input() editMode = false; // Whether this form is creating a 'new' role or editing an existing one
  @ViewChild('orgSelect')
  private orgSelector: OrgSelectComponent;
  orgListStateMatcher: ErrorStateMatcher;

  private formGroup: UntypedFormGroup;

  private tempValues: Pick<FormGroupModel, 'orgSelectionType' | 'orgList'> = {
    orgSelectionType: undefined,
    orgList: [],
  };

  constructor(public orgFacade: OrgsFacade, private fb: UntypedFormBuilder) {
    const opts: AbstractControlOptions = {
      validators: ({ value }: any): ValidationErrors | null => {
        if (value && value.length === 0) {
          return { required: 'Select at least one thing' };
        } else {
          return null;
        }
      },
    };

    this.formGroup = fb.group({
      relationshipType: [],
      orgSelectionType: [],
      deferOrganization: [false],
      orgList: [[], opts],
      courseType: '',
    });

    this.orgListStateMatcher = new ImmediateStateMatcher(this.orgList, this.relationshipType);
  }

  get relationshipType(): AbstractControl | null {
    return this.formGroup.get('relationshipType');
  }

  get deferOrgControl(): AbstractControl | null {
    return this.formGroup.get('deferOrganization');
  }

  get orgSelectionType(): AbstractControl | null {
    return this.formGroup.get('orgSelectionType');
  }

  get orgList(): AbstractControl | null {
    return this.formGroup.get('orgList');
  }

  get courseType(): AbstractControl | null {
    return this.formGroup.get('courseType');
  }

  ngAfterViewInit(): void {
    // handle relationshipType changes
    if (this.relationshipType) {
      this.relationshipType.valueChanges.subscribe((value: RelationshipType) => {
        if (value === 'ALL_STUDENTS') {
          this.allStudentsSelected();
        } else {
          this.organizationsSelected();
        }
        const existingValue: Relationships = this.formControl.value;
        const newValue: Relationships = {
          ...existingValue,
          relationshipType: value,
        };
        this.propagateChange(newValue);
      });
    }

    // handle orgSelectionType changes
    if (this.orgSelectionType) {
      this.orgSelectionType.valueChanges.subscribe((value: OrgSelectionType) => {
        const existingValue: Relationships = this.formControl.value;
        const newValue: Relationships = {
          ...existingValue,
          orgSelectionType: value,
        };
        this.propagateChange(newValue);
      });
    }

    // handle deferment changes
    if (this.deferOrgControl) {
      this.deferOrgControl.valueChanges.subscribe((value: boolean) => {
        const existingValue: Relationships = this.formControl.value;
        const newValue: Relationships = {
          ...existingValue,
          deferOrganization: value,
        };
        this.propagateChange(newValue);
      });
    }

    // handle orgList changes
    if (this.orgList) {
      this.orgList.valueChanges.subscribe((value: OrgSelectItem[]) => {
        const newValue: Relationships = {
          ...this.formControl.value,
          organizations: value ? value.map(orgSelectItemToOrgModel) : [],
        };
        this.propagateChange(newValue);
      });
    }

    // handle courseType changes
    if (this.courseType) {
      this.courseType.valueChanges.subscribe((value: string) => {
        const newValue: ClientCourseSelectionType = {
          ...this.formControl.value,
          courseType: 'DEFER_COURSES',
        };
        this.propagateChange(newValue);
      });
    }
  }

  blurHandler(event: any): void {
    this.formControl.updateValueAndValidity();
    this.propagateTouch(event);
  }

  // Validator Implementation
  validate(_control: AbstractControl): ValidationErrors | null {
    const validationErrors: ValidationErrors = {};

    const relationshipTypeValue: RelationshipType = this.relationshipType ? this.relationshipType.value : null;
    const orgListValue: OrgModel[] = this.orgList ? this.orgList.value : [];

    if (relationshipTypeValue === 'ORGANIZATION' && orgListValue.length === 0) {
      validationErrors.orgsRequired = 'You must select at least one organization';
    }
    return !isEmpty(validationErrors) ? validationErrors : null;
  }

  // ControlValueAccessor Implementation
  writeValue(obj: Relationships): void {
    if (obj) {
      const orgList = obj.organizations ? obj.organizations.map(orgModelToOrgSelectItem) : [];

      this.formGroup.patchValue({
        relationshipType: obj.relationshipType,
        orgSelectionType: obj.orgSelectionType,
        deferOrganization: obj.deferOrganization,
        orgList,
        courseType: obj.courseType,
      });
    } else {
      const value: Relationships = {
        organizations: [],
        orgSelectionType: 'SINGLE',
        relationshipType: 'ALL_STUDENTS',
        deferOrganization: false,
        courseType: 'DEFER_COURSES',
      };
      this.formGroup.patchValue(value);
    }
  }

  registerOnChange(fn: any): void {
    this.propagateChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.propagateTouch = fn;
  }

  private allStudentsSelected(): void {
    // Stash the hidden form control's values
    this.tempValues.orgSelectionType = this.orgSelectionType ? this.orgSelectionType.value : undefined;
    this.tempValues.orgList = this.orgList ? this.orgList.value : [];

    const newValue = {
      orgList: [],
      orgSelectionType: 'SINGLE',
    };
    this.formGroup.patchValue(newValue, { onlySelf: true, emitEvent: false });
  }

  private organizationsSelected(): void {
    // pop the stashed values back into the form group
    const newValue = {
      orgSelectionType: this.tempValues.orgSelectionType ? this.tempValues.orgSelectionType : 'SINGLE',
      orgList: this.tempValues.orgList,
    };
    this.formGroup.patchValue(newValue, { onlySelf: true, emitEvent: false });
    // Clear out the tempValue for next time
    this.tempValues = { orgList: [], orgSelectionType: undefined };
    this.orgSelector.focus();
  }

  private propagateChange: (_: any) => any = (_: any) => ({});
  private propagateTouch: (_: any) => any = (_: any) => ({});
}
