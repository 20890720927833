import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  forwardRef,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
} from '@angular/core';
import {
  ControlValueAccessor,
  NG_VALUE_ACCESSOR,
  UntypedFormArray,
  UntypedFormBuilder,
  UntypedFormControl,
} from '@angular/forms';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { TypeaheadConfig, TypeaheadModel } from '@psu/components/typeahead';
import { RoleConfig, RoleSelectionType, SelectableRole } from '@starfish-access/models';
import { BehaviorSubject } from 'rxjs';
import { RoleConfigDialogData, RoleConfigFormComponent } from '../role-config-form/role-config-form.component';

const defaultRoleSearchConfig: TypeaheadConfig<SelectableRole> = {
  placeholder: 'Search for a Role',
  dataSource: [],
  isLoadingDataSource: false,
  clearOnSelect: true,
};

export const createRoleConfig = (selectableRole: SelectableRole, defaultRoleConfig: RoleConfig): RoleConfig => {
  let type: RoleSelectionType = 'ALL_STUDENTS';

  if (selectableRole.roleType === 'ORGANIZATION') {
    type = 'ORG';
  } else if (selectableRole.roleType === 'COURSE') {
    type = 'COURSES';
  } else {
    type = selectableRole.roleType;
  }

  const config: RoleConfig = {
    ...defaultRoleConfig,
    displayName: selectableRole.displayName,
    roleId: '' + selectableRole.id,
    roleSelectionType: type,
  };

  // by default in step 2, we are setting the 'kiosksFromDelegation' to everything that was in the original role config as a starting point
  if (selectableRole.possibleKiosks?.length > 0) {
    config.kiosksFromRole.selectedItems = selectableRole.possibleKiosks;
    config.kiosksFromRole.selectionType = selectableRole.kioskSelectionEnabled
      ? 'ADMIN_AREA_CHOOSE'
      : 'SPECIFIC_SERVICES';
    config.kiosksFromDelegation.selectedItems = selectableRole.possibleKiosks;
    config.kiosksFromDelegation.selectionType = selectableRole.kioskSelectionEnabled
      ? 'ADMIN_AREA_CHOOSE'
      : 'SPECIFIC_SERVICES';
  }

  // by default in step 2, we are setting the 'servicesFromDelegation' to everything that was in the original role config as a starting point
  if (selectableRole.possibleServices?.length > 0) {
    config.servicesFromRole.selectedItems = selectableRole.possibleServices;
    config.servicesFromRole.selectionType = selectableRole.serviceSelectionEnabled
      ? 'ADMIN_AREA_CHOOSE'
      : 'SPECIFIC_SERVICES';
    config.servicesFromDelegation.selectedItems = selectableRole.possibleServices;
    config.servicesFromDelegation.selectionType = selectableRole.serviceSelectionEnabled
      ? 'ADMIN_AREA_CHOOSE'
      : 'SPECIFIC_SERVICES';
  }

  // by default in step 2, we are setting the 'orgsFromDelegation' to everything that was in the original role config as a starting point

  if (selectableRole.possibleOrgs?.length > 0) {
    config.orgsFromRole.selectedItems = selectableRole.possibleOrgs;
    config.orgsFromRole.selectionType = selectableRole.orgSelectionEnabled ? 'ADMIN_AREA_CHOOSE' : 'SPECIFIC_SERVICES';

    config.orgsFromDelegation.selectedItems = selectableRole.possibleOrgs;
    config.orgsFromDelegation.selectionType = selectableRole.orgSelectionEnabled
      ? 'ADMIN_AREA_CHOOSE'
      : 'SPECIFIC_SERVICES';
  }

  return config;
};

@Component({
  selector: 'sf-role-config-list',
  templateUrl: './role-config-list.component.html',
  styleUrls: ['./role-config-list.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => RoleConfigListComponent),
      multi: true,
    },
  ],
})
export class RoleConfigListComponent implements ControlValueAccessor, OnChanges {
  @Input() roles: SelectableRole[];
  @Output() deleteRole: EventEmitter<RoleConfig> = new EventEmitter();
  roleConfigs: UntypedFormArray;

  roleTableDatasource = new MatTableDataSource([]);
  displayedColumns = ['displayName', 'deleterole', 'button'];

  roleSearchConfig$: BehaviorSubject<TypeaheadConfig<SelectableRole>> = new BehaviorSubject(defaultRoleSearchConfig);

  private addedSelectableRoles: SelectableRole[] = [];

  constructor(public fb: UntypedFormBuilder, private dialog: MatDialog, private changeDetectorRef: ChangeDetectorRef) {
    this.roleConfigs = this.fb.array([]);
    this.roleConfigs.valueChanges.subscribe((value) => {
      this.propagateChange(value);
    });
  }

  addRole(roleItem: TypeaheadModel<SelectableRole>): void {
    const newRoleConfig = createRoleConfig(roleItem.object, {
      roleSelectionType: 'ALL_STUDENTS',
      roleDelegationId: undefined, // if this is set to a value, the endpoint will break.
      roleId: '',
      displayName: '',
      servicesFromRole: { selectedItems: [], selectionType: 'NONE' },
      kiosksFromRole: { selectedItems: [], selectionType: 'NONE' },
      orgsFromRole: { selectedItems: [], selectionType: 'NONE' },
      servicesFromDelegation: {
        selectedItems: [],
        selectionType: 'NONE',
      },
      kiosksFromDelegation: {
        selectedItems: [],
        selectionType: 'NONE',
      },
      orgsFromDelegation: {
        selectedItems: [],
        selectionType: 'NONE',
      },
      studentSupporters: [],
      connectedStudents: [],
      courseConfig: undefined,
    });

    this.roleConfigs.push(new UntypedFormControl(newRoleConfig));

    this.addedSelectableRoles.push(roleItem.object);

    this.roleTableDatasource = new MatTableDataSource(this.roleConfigs.value);

    const selectedRoleIds: string[] = this.roleConfigs.value.map((r: RoleConfig) => r.roleId);

    const dataSource: TypeaheadModel<SelectableRole>[] = this.roles.map((role) => ({
      object: role,
      displayValue: role.displayName,
      disabled: selectedRoleIds.includes(role.id + ''),
    }));

    this.roleSearchConfig$.next({
      ...defaultRoleSearchConfig,
      dataSource,
    });
  }

  editRole(index: number): void {
    const data: RoleConfigDialogData = {
      config: this.roleConfigs.controls[index].value,
      roleInfo: this.addedSelectableRoles[index],
    };

    const dialogRef = this.dialog.open(RoleConfigFormComponent, {
      width: '850px',
      disableClose: true,
      data,
    });

    dialogRef.afterClosed().subscribe((result: RoleConfig) => {
      this.roleConfigs.controls[index].patchValue(result);
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['roles']) {
      const newRoles: SelectableRole[] = changes['roles'].currentValue;

      const dataSource = newRoles.map((role) => ({
        object: role,
        displayValue: role.displayName,
      }));
      this.roleSearchConfig$.next({
        ...defaultRoleSearchConfig,
        dataSource,
      });

      (this.roleConfigs.value as RoleConfig[]).forEach((roleConfig) => {
        this.roles.forEach((sr) => {
          if (sr.id === +roleConfig.roleId) {
            this.addedSelectableRoles.push(sr);
          }
        });
      });
    }
  }

  writeValue(obj: RoleConfig[]): void {
    this.roleConfigs.clear();
    let index = 0;
    if (obj) {
      obj.forEach((element) => {
        this.roleConfigs.push(new UntypedFormControl());
        this.roleConfigs.controls[index].patchValue(element);
        index++;
      });
      this.roleTableDatasource = new MatTableDataSource(this.roleConfigs.value);

      (this.roleConfigs.value as RoleConfig[]).forEach((roleConfig) => {
        this.roles.forEach((sr) => {
          if (sr.id === +roleConfig.roleId) {
            this.addedSelectableRoles.push(sr);
          }
        });
      });
    }

    this.changeDetectorRef.detectChanges();
  }

  registerOnChange(fn: any): void {
    this.propagateChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.propagateTouch = fn;
  }

  private propagateChange: (_: any) => any = (_: any) => ({});
  private propagateTouch: (_: any) => any = (_: any) => ({});
}
