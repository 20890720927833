<h3>Icon Image</h3>
<mat-radio-group (change)="iconSelection.next($event.value)"
  color="primary"
  aria-label="Select an icon for your new group.">
  <mat-radio-button *ngIf="loadedRoleGroup"
    checked
    [value]="loadedRoleGroup.iconImage">
    <div fxLayoutAlign="center center"
      class="icon-swatch"
      [attr.aria-label]="loadedRoleGroup.iconImage">
      <mat-icon [svgIcon]="loadedRoleGroup.iconImage"></mat-icon>
    </div>
  </mat-radio-button>
  <mat-radio-button [checked]="!loadedRoleGroup"
    [value]="ig?.icon1">
    <div fxLayoutAlign="center center"
      class="icon-swatch"
      [attr.aria-label]="ig?.icon1">
      <mat-icon [svgIcon]="ig?.icon1"></mat-icon>
    </div>
  </mat-radio-button>
  <mat-radio-button [value]="ig?.icon2">
    <div fxLayoutAlign="center center"
      class="icon-swatch"
      [attr.aria-label]="ig?.icon2">
      <mat-icon [svgIcon]="ig?.icon2"></mat-icon>
    </div>
  </mat-radio-button>
  <mat-radio-button [value]="ig?.icon3">
    <div fxLayoutAlign="center center"
      class="icon-swatch"
      [attr.aria-label]="ig?.icon3">
      <mat-icon [svgIcon]="ig?.icon3"></mat-icon>
    </div>
  </mat-radio-button>
</mat-radio-group>
<button mat-flat-button
  class="more-icon-btn"
  (click)="resetIcons()">More Icons</button>
