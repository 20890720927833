import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';
import { AfterViewInit, Component, ElementRef, Inject, OnDestroy, ViewChild, ViewEncapsulation } from '@angular/core';
import { MediaObserver } from '@angular/flex-layout';
import { MatSidenav } from '@angular/material/sidenav';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { AuthService } from '@psu/utils/security';
import { UserPreferencesService } from '@starfish-access/core';
import { BehaviorSubject, of, Subject } from 'rxjs';
import { catchError, filter, map, takeUntil, withLatestFrom } from 'rxjs/operators';
import packageInfo from '../../package.json';
import { StarfishConfig, STARFISH_CONFIG } from './starfish.config';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'app',
  styleUrls: ['./app.component.scss'],
  templateUrl: './app.component.html',
  encapsulation: ViewEncapsulation.None,
})
export class AppComponent implements AfterViewInit, OnDestroy {
  @ViewChild('mainContainer', { static: true })
  mainContainer: ElementRef;
  @ViewChild('sidenav') sideNav: MatSidenav;
  mediaXs = false;
  psuLogo = 'images/PS_VER2_REV_RGB_2C.png';
  psuHorizontalLogo = 'images/PSU_HOR_REV_RGB_2C.svg';
  psuHorizontalLogoDark = 'images/PSU_HOR_RGB_2C.svg';
  psuSquareLogo = 'images/PSU_RGB_1C.svg';
  footerFullLogo = 'images/PSU_HOR_RGB_2C.svg';
  starfishHorizontalLogo = 'images/Starfish-icons-52.svg';
  psuLink: string;
  serverEnv = '';
  version: string = packageInfo.version;
  currentYear: number = new Date().getFullYear();

  hasFerpa = false;
  isLoggedIn = false;

  properties: any = {};
  menuSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  /**
   * Creates an instance of AppComponent.
   *
   * @param {ObservableMedia} media
   * @param {Logger} log
   * @memberof AppComponent
   */

  private destroy$ = new Subject<void>();

  constructor(
    public media: MediaObserver,
    public route: ActivatedRoute,
    public authService: AuthService,
    public prefs: UserPreferencesService,
    private breakpointObserver: BreakpointObserver,
    private router: Router,
    @Inject(STARFISH_CONFIG) public starfishConfig: StarfishConfig
  ) {
    this.breakpointObserver.observe(['(max-width: 768px)']).subscribe((result: BreakpointState) => {
      if (result.matches) {
        this.mediaXs = true;
      } else {
        this.mediaXs = false;
      }
    });

    this.isLoggedIn = this.authService.getCurrentUser()?.userName ? true : false;

    this.psuLink = this.starfishConfig.psuHomePage || 'http://www.psu.edu';

    this.serverEnv = this.starfishConfig.environment ? this.starfishConfig.environment : '';
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  ngAfterViewInit() {
    this.router.events
      .pipe(
        filter((evt) => evt instanceof NavigationEnd),
        takeUntil(this.destroy$)
      )
      .subscribe((evt) => {
        if (this.mainContainer && this.mainContainer.nativeElement) {
          this.mainContainer.nativeElement.scrollTop = 0;
          this.mainContainer.nativeElement.scrollLeft = 0;
        }
      });

    this.prefs.getPreferences$
      .pipe(
        takeUntil(this.destroy$),
        map((res) => res.keepMenuOpen),
        catchError(() => of(false))
      )
      .subscribe((res) => this.menuSubject.next(res));

    this.router.events
      .pipe(
        withLatestFrom(this.prefs.getPreferences$),
        filter(([a, b]) => !b.keepMenuOpen && a instanceof NavigationEnd)
      )
      .subscribe((_) => this.sideNav.close());
  }

  shouldCloseMenu(mediaXs: any): void {
    if (mediaXs) {
      this.sideNav.close();
      return;
    }
  }
}
