<section *ngIf='(facade.canView$ | async); else perms_error'>

  <mat-card class="result card-table">
    <h1>Available Starfish Kiosks</h1>
    <mat-card-content>
      <div fxLayout="column">
        <span class="starfish-welcome">A Starfish kiosk waiting room is visible only to individuals who have been associated with the particular unit’s Starfish kiosk. Users who can view the waiting room can indicate that students have left, start meetings with students, and edit the meeting schedule details. If an office is using a Starfish kiosk, the user must be a member of the kiosk’s service in order to see the kiosk’s waiting room.</span>
        <br><br>
        <span *ngIf='(facade.isLoadingData$ | async) && !(facade.hasFailed$ | async)'>
          <br>
          <mat-progress-bar mode="indeterminate"></mat-progress-bar>
        </span>
        <span *ngIf='(facade.hasFailed$ | async)'
          class="starfish-welcome">
          We couldn't access Kiosks. Please try again later, or contact an admin for help.
        </span>

        <div *ngIf='!(facade.isLoadingData$ | async) && !(facade.hasFailed$ | async)'>
          <div fxLayout="row">
            <mat-form-field class="starfish-filter"
              fxFlex>
              <input matInput
                data-cy="kiosks-search-filter"
                (keyup)="applyFilter($event.target.value)"
                placeholder="Filter">
            </mat-form-field>
            <button data-cy="new-service-btn"
              class="add-new-contained-btn"
              *ngIf="facade.canAdd$ | async"
              mat-raised-button
              color="accent"
              (click)="facade.addNewServiceOrg()">
              <mat-icon>add</mat-icon><span>New Kiosk</span>
            </button>
          </div>
          <table mat-table
            [dataSource]="dataSource"
            matSort
            class="mat-elevation-z0 starfish-data-table">

            <ng-container matColumnDef="name">
              <th mat-header-cell
                *matHeaderCellDef
                mat-sort-header> Kiosk Name </th>
              <td mat-cell
                *matCellDef="let element"
                (click)="facade.editExistingKiosk(element)"
                class="hand-pointer">
                <span *ngIf="(facade.canEdit$ | async)"
                  matTooltip="Edit this Kiosk"
                  [matTooltipShowDelay]=tooltipDelay>{{element.name}}</span>
                <span *ngIf="!(facade.canEdit$ | async)">{{element.name}}</span>
              </td>
            </ng-container>

            <ng-container matColumnDef="id">
              <th mat-header-cell
                *matHeaderCellDef> Kiosk ID </th>
              <td mat-cell
                *matCellDef="let element"
                (click)="facade.editExistingKiosk(element)"
                class="hand-pointer"> {{element.id}} </td>
            </ng-container>

            <ng-container matColumnDef="delete">
              <th mat-header-cell
                *matHeaderCellDef> </th>
              <td mat-cell
                *matCellDef="let element">
                <a *ngIf="(facade.canDelete$ | async) && element.id && !isActivelyDeleting(element.id)"
                  (click)="facade.deleteExistingKiosk(element.id)"><button mat-button
                    color=warn>
                    <mat-icon>delete</mat-icon>
                  </button></a>
                <mat-spinner *ngIf="element.id &amp;&amp; isActivelyDeleting(element.id)"
                  diameter=25
                  color=accent></mat-spinner>
              </td>
            </ng-container>

            <tr mat-header-row
              *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row
              *matRowDef="let row; columns: displayedColumns;"></tr>
          </table>
          <mat-paginator showFirstLastButtons></mat-paginator>
        </div>

      </div>
    </mat-card-content>
  </mat-card>
</section>

<ng-template #perms_error>
  <div class="failed-styling">
    You do not have access to this content.
  </div>
</ng-template>
