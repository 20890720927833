import { Injectable } from '@angular/core';
import { MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { GenericServiceDeletionStatus } from '@starfish-access/models';
import { Observable } from 'rxjs';
import { SvcErrorDialogComponent } from './svc-error-dialog.component';

@Injectable()
export class SvcErrorDialogService {
  constructor(private dialog: MatDialog) {}

  errorDisplay(data: GenericServiceDeletionStatus): Observable<boolean> {
    const dialogRef: MatDialogRef<SvcErrorDialogComponent> = this.dialog.open(SvcErrorDialogComponent, {
      data,
      disableClose: true,
    });
    return dialogRef.afterClosed();
  }
}
