<h3>Icon Background Color</h3>
<mat-radio-group (change)="colorSelection.next($event.value)"
  color="primary"
  aria-label="Select a color for your new group.">
  <mat-radio-button *ngIf="loadedRoleGroup"
    checked
    [value]="loadedRoleGroup.iconColor">
    <div class="color-swatch"
      [ngClass]="loadedRoleGroup.iconColor"
      [attr.aria-label]="loadedRoleGroup.iconColor"></div>
  </mat-radio-button>
  <mat-radio-button [checked]="!loadedRoleGroup"
    [value]="cg?.color1">
    <div class="color-swatch"
      [ngClass]="cg?.color1"
      [attr.aria-label]="cg?.color1"></div>
  </mat-radio-button>
  <mat-radio-button [value]="cg?.color2">
    <div class="color-swatch"
      [ngClass]="cg?.color2"
      [attr.aria-label]="cg?.color2"></div>
  </mat-radio-button>
  <mat-radio-button [value]="cg?.color3">
    <div class="color-swatch"
      [ngClass]="cg?.color3"
      [attr.aria-label]="cg?.color3"></div>
  </mat-radio-button>
</mat-radio-group>
<button mat-flat-button
  class="more-color-btn"
  (click)="resetColors()">More Colors</button>
