import { Injectable } from '@angular/core';
import { MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { Observable } from 'rxjs';
import { OkayDialogComponent } from './okay-dialog.component';

@Injectable()
export class OkayDialogService {
  constructor(private dialog: MatDialog) {}

  okay(title: string, message: string, okLabel?: string, disableClose?: boolean): Observable<boolean> {
    if (!disableClose) {
      disableClose = false;
    }

    const dialogRef: MatDialogRef<OkayDialogComponent> = this.dialog.open(OkayDialogComponent, {
      disableClose,
      width: '540px',
    });

    dialogRef.componentInstance.title = title;
    dialogRef.componentInstance.message = message;

    if (okLabel) {
      dialogRef.componentInstance.okLabel = okLabel;
    }

    return dialogRef.afterClosed();
  }
}
